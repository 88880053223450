.automationLogsPage .form-lable {
  padding-left: 0px;
  text-align: right;
  padding-right: 0px;
  padding-top: 5px;
  font-weight: 400;
  font-size: 13px;
}

.automationLogsPage .multiSelect-option2 button.css-1r4vtzz,
.automationLogsPage .multiSelect-option2 button.css-48ayfv {
  padding: 3px 6px 3px 6px;
  font-size: 12px;
  border-radius: 30px;
  width: 100% !important;
  background-color: #ffffff;
  box-shadow: none;
}
.automationTimelinePage .multiSelect-option2 button.css-1r4vtzz > span,
.automationTimelinePage .multiSelect-option2 button.css-48ayfv > span {
  padding-right: 0px !important;
}
.automationLogsPage .css-ik6y5r {
  box-shadow: 0 2px 4px 0 #eaeaea;
  border: solid 1px #eaeaea;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  background: #ffffff;
  width: 180px;
}

.automationLogsPage .css-8ywtop {
  padding: 2px 3px;
  width: 100% !important;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 12px;
}

.automationLogsPage .multiSelect-option2 > div > div:first-of-type {
  z-index: 11 !important;
}
.automationLogsPage .multiSelect-option2 > div > div:last-of-type {
  z-index: 10 !important;
}

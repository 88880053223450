.copyIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  z-index: 99;
}

.copied {
  background-color: #176f2c;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  padding: 0 5px;
}

.accessKeyValueSection {
  display: flex;
}

.accessKeyTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 16px 16px 8px 16px;
}

.separator {
  border-bottom: 1px solid #DDDDDD;
}

.accessKeySectionTitle {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.ltTextInput {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}

.ltIconButtonInput {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.accessKeyContainer {
  width: 320px;
}

.accessKeySectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px 16px 16px;
}

.accessKeySection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.noScreenshotWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.description {
  color: #666;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 768px) {
  .navbar-nav > li > a {
    padding-bottom: 10px;
  }
}

#uncontrolled-tab-example .nav-tabs {
  border-bottom: 1px solid #eaeaea !important;
}

#uncontrolled-tab-example li.active a,
#uncontrolled-tab-example li a:hover,
#uncontrolled-tab-example li a:active,
#uncontrolled-tab-example li a:focus {
  color: #000 !important;
}

#uncontrolled-tab-example-tab li.active a {
  font-weight: 500;
}

#uncontrolled-tab-example > ul li:last-child:hover a {
  color: #fff !important;
}

#infiniteScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#infiniteScroll::-webkit-scrollbar-track {
  background: #f4f5f9;
}

#infiniteScroll::-webkit-scrollbar-thumb {
  background: #dbdeec;
  border-radius: 10px;
}

#infiniteScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 0;
}

.lt-drawer .ant-drawer-header .ant-drawer-extra {
  width: 97%;
}

.lt-drawer .ant-drawer-close:focus {
  outline: 1px solid #333;
}

.lt-drawer.ant-drawer-open,
.ant-popover {
  z-index: 99999 !important;
}

.lt-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 44px;
}

.lt-tabs .ant-tabs-tab {
  font-size: 12px;
  padding: 12px;
}

.lt-tabs.ant-tabs .ant-tabs-nav {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-left: 23px;
  color: #333333;
  margin-bottom: 0px;
}

.lt-tabs-new .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.lt-tabs-new .ant-tabs-ink-bar {
  background: #333;
}

.lt-tabs-new .ant-tabs-tab:hover {
  color: #333;
}

.lt-tabs-new .ant-tabs-tab {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.lt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
  font-weight: 500;
}

.lt-tabs .ant-tabs-ink-bar {
  background: #333;
}

.lt-tabs .ant-tabs-tab {
  color: #666;
}

.lt-tabs .ant-tabs-tab:hover {
  color: #333;
}

.lt-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 20px;
}

.lt-tabs.lt-tabs-compact .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 8px;
}

.lt-tabs.lt-tabs-compact.ant-tabs .ant-tabs-nav {
  padding-left: 0;
}

.lt-tabs.lt-tabs-hyex-btn-shown.ant-tabs .ant-tabs-nav {
  max-width: calc(100% - 155px);
}

.lt-tabs.lt-logs-tabs.ant-tabs .ant-tabs-nav {
  max-width: 100%;
}

.lt-logs-tabs .ant-tabs-ink-bar {
  display: none;
}

.lt-logs-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border: 1px solid #333;
}

.lt-logs-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 5px;
}

.lt-migrate-from-tabs .ant-tabs-ink-bar {
  display: none;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  align-items: center;
  border-radius: 2px;
  line-height: 14px;
  letter-spacing: 0.4px;
  padding: 10px 10px;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #000;
  background-color: #f4f3f3;
}

.lt-tabs.lt-migrate-from-tabs.ant-tabs .ant-tabs-nav {
  padding-left: 0px;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab {
  padding-left: 0px;
  margin-left: 1px;
}

.lt-tabs.lt-migrate-from-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  background-color: #f4f3f3;
  color: #000;
}

.lt-breadcrumb-wrapper.ant-breadcrumb a:hover {
  color: #0ebac5;
}

.tooltipDiv {
  position: relative;
  display: inline-block;
}

.tooltipDiv .tooltiptext a {
  color: #43bac5;
}

.tooltipDiv .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: white;
  color: #666666;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 32px;
  right: -19px;
  margin-left: -60px;
  font-size: 12px;
  line-height: 22px;
  color: #666666;
  border: 1px solid #d2d2d2;
  box-shadow: 1px 2px 3px rgba(197, 197, 197, 0.25);
  border-radius: 5px;
}

.tooltipDiv .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 20px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #d2d2d2;
}

.tooltipDiv:hover .tooltiptext {
  visibility: visible;
}

.accessKeyDropDown .dropdown-menu {
  min-width: 320px !important;
}

.hcm-tooltip-base {
  background: #1b1e20 !important;
  color: #fff;
}

.harsh-coach-mark {
  border-radius: 4px !important;
}

.hcm-tooltip-base-left::before {
  border-color: #1b1e20 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}

.lt-switch.ant-switch-checked {
  background-color: #4688f4;
}

.lt-drawer-accordion.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0px;
}

.lt-drawer-accordion.ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 10px;
  padding-right: 8px;
}

#lt-mask {
  background: #000;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  pointer-events: none;
  width: 100%;
  z-index: 2;
}

.lt-visible {
  visibility: visible;
}

.lt-hidden {
  visibility: hidden;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.ant-table-container .ant-table-tbody > tr > td,
.ant-table-container .ant-table-thead > tr > th {
  padding: 12px;
}

.ant-table-cell .shareBtn {
  visibility: hidden;
}

.ant-table-cell.ant-table-cell-row-hover .shareBtn {
  visibility: visible;
}

.ant-table-thead {
  background: #fafafa;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ant-table-thead > tr > th {
  background: #fff;
}

.ant-select-selection-item {
  color: #666;
  font-size: 10px;
}

.lt-scroll {
  overflow-y: scroll;
}

.testMetaDataModal .modal-dialog {
  width: 1100px;
}

.lambda__logo__img {
  max-width: unset;
}

.ant-switch.hide-error-switch-Icon {
  height: 12px;
  min-width: 6px;
  width: 25px;
}

.ant-switch.hide-error-switch-Icon.ant-switch-checked {
  background-color: #4abcc5;
}

.hide-error-switch-Icon .ant-switch-handle {
  height: 8px;
  width: 8px;
}

.hide-error-switch-Icon.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 10px);
}

.ltRunning {
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  border: 1.5px solid #409ff6;
  border-top: 1.5px solid #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton {
  background-color: #101010;
  color: #fff;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton:hover {
  border: none;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton.ant-dropdown-open {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.dot_windmill {
  animation: dotWindmill 2s infinite linear;
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  position: relative;
  top: -10px;
  transform-origin: 5px 15px;
  width: 10px;
}

.dot_windmill::before,
.dot_windmill::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot_windmill::before {
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  left: -8.66px;
  top: 15px;
  width: 10px;
}

.dot_windmill::after {
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  left: 8.66px;
  top: 15px;
  width: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes dotWindmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

.TopRightOptionsPopoverHelp.ant-popover-placement-bottom .ant-popover-arrow {
  left: 90%;
}

.TopRightOptionsPopoverAccessKey.ant-popover-placement-bottom
  .ant-popover-arrow {
  left: 70%;
}

.lt-collapse-pane .ant-collapse-content {
  background-color: transparent;
  border-top: none;
}

.lt-collapse-pane .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.lt-collapse-pane.ant-collapse {
  background-color: transparent;
  border: none;
  font-size: 12px;
  word-break: break-word;
}

.lt-collapse-pane .ant-collapse-header {
  padding: 0;
}

.lt-collapse-pane .ant-collapse-item {
  border: none !important;
}

.lt-collapse-pane.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 4px 40px 4px 16px;
}

.networkLogsColumnHeader {
  font-size: 12px;
}

.lt-sharing-modal-wrapper.ant-modal-wrap,
.lt-sharing-select-dropdown.ant-select-dropdown {
  z-index: 99999;
}

.lt-delete-modal-wrapper.ant-modal-wrap {
  z-index: 99999 !important;
}

.lt-build-tabs .ant-tabs-tab-btn:focus,
.lt-build-tabs .ant-tabs-tabpane-active:focus,
.lt-tabs-compact .ant-tabs-tab-btn:focus,
.lt-tabs-compact .ant-tabs-tabpane-active:focus,
.lt-logs-tabs .ant-tabs-tab-btn:focus,
.lt-logs-tabs .ant-tabs-tabpane-active:focus,
.lt-tabs-new .ant-tabs-tab-btn:focus,
.lt-tabs-new .ant-tabs-tabpane-active:focus,
.lt-migrate-from-tabs .ant-tabs-tab-btn:focus {
  outline: 1px solid #333;
}

.ant-modal-close:focus {
  outline: 1px solid #101010;
}

#commandLogRemark
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header:focus {
  outline: 1px solid #101010;
}

.lt-menu-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-active:focus {
  outline: 1px solid #333 !important;
}

/* Primer CSS */
#__primerPortalRoot__ > div {
  z-index: 9999999999999999 !important;
}

.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.PRC-selected {
  border-bottom-color: #ed5f00 !important;
}

.PRC-UnderlineNav {
  border-bottom: none !important;
}

.PRC-UnderlineNav-item {
  display: flex;
  align-items: center;
  margin-right: 8px !important;
}

textarea::placeholder,
input::placeholder {
  font-size: 14px;
}

.react-lazylog .ReactVirtualized__Grid__innerScrollContainer div:hover {
  background-color: rgba(221, 221, 221, 0.2);
}

.react-lazylog:focus-visible {
  outline: 2px solid #0969da;
}

.textAreaWrapper > span:focus-within {
  border-width: 2px;
  box-shadow: none;
}

.lt-healed-popover .ant-popover-content,
.lt-flaky-popover .ant-popover-content {
  border-radius: 6px;
}

.lt-healed-popover .ant-popover-inner,
.lt-flaky-popover .ant-popover-inner,
.lt-build-details-popover .ant-popover-inner {
  border: 1px solid #ddd;
  border-radius: 6px;
}

.lt-build-details-popover .ant-popover-inner-content {
  padding: 0px;
  overflow: hidden;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hiddenFromView {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ltCommandLogParamsContainer .string-value {
  word-break: break-all;
}
.filterModalCheckboxGroup {
  max-height: 200px;
  margin: 0px -16px -16px -16px;
  overflow: scroll;
  padding: 0px 16px 16px 16px;
}

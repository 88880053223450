.automationLogsPage .accordion > .card > .card-header {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.automationLogsPage .card-header:first-child {
  border-radius: 0 !important;
}
.automationLogsPage .card-header {
  padding: unset;
  margin-bottom: 0;
  background-color: unset;
  border-bottom: unset;
}
.automationLogsPage .card {
  border: unset;
  border-bottom: unset !important;
}

.automationLogsPage .collapse {
  box-sizing: unset !important;
}

.automationLogsPage .nav-link {
  padding: 10px !important;
}

.automationLogsPage .table thead th {
  border-bottom: unset !important;
}
.automationLogsPage .lh-topbar {
  z-index: 5 !important;
}
.performanceDrawer .lh-sticky-header--visible {
  position: absolute !important;
  width: 1000px;
  top: unset !important;
  left: unset !important;
  right: unset !important;
}
#controlled-tab-example-network .nav-tabs {
  border: unset !important;
}

.automationLogsPage .panel-default > .panel-heading {
  color: #3c4144;
  background-color: unset !important;
  border-color: unset !important;
  font-size: 14px !important;
  padding: 0px !important;
}
.automationLogsPage .panel-default {
  border-color: #fff !important;
}
.automationLogsPage .panel {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.automationLogsPage .panel-title a {
  text-decoration: none !important;
}
.automationLogsPage
  .panel-group
  .panel-heading
  + .panel-collapse
  > .panel-body {
  border: unset !important;
}

#code_wrapper div > pre {
  min-height: 700px !important;
  padding: 20px !important;
  padding-left: 10px !important;
  margin-top: 10px;
}

#command_wrapper div:last-child > div {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #43bac5;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.testNetworkLogs .activeNested.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0ebac5 !important;

  background-color: white;
  color: #fff;
}
.testNetworkLogs .activeNested.nav-tabs .nav-link {
  padding: 5px !important;
  margin: 15px 5px;
  border: 2px solid #0ebac5 !important;
  background-color: white;
}
.testNetworkLogs .activeNested.nav-tabs li.active {
  background-color: white;
  border: 2px solid #0ebac5 !important;
  color: #fff !important;
  background-color: white;
}
.testNetworkLogs .nav-tabs li a {
  color: #4a4a4a;
  border: unset !important;
  line-height: 1.31;
  letter-spacing: 0.48px;
  padding: 5px;
  margin-right: 20px;
  background-color: white;
}
.testNetworkLogs .nav-tabs li.active {
  border: unset !important;
  background-color: white;
}
.testNetworkLogs .automationLogsPage .nav-tabs .nav-link {
  color: #4a4a4a;
  border: unset !important;
  background-color: white;
}
.testNetworkLogs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: white;
}
/* Active Li Style For HAR Tab(Nested Filter Tab) */
.activeNested > ul > li.active a {
  background-color: white !important;
  color: #fff !important;
  border-radius: 10px;
  border: 0px;
  border: 1px solid #0ebac5 !important;
}
#uncontrolled-tab-example-tab li.active a,
#uncontrolled-tab-example-tab li a:hover,
#uncontrolled-tab-example-tab li a:active,
#uncontrolled-tab-example-tab li a:focus {
  background-color: transparent !important;
  color: #0ebac5 !important;
}

.inputConfig .string-value {
  word-break: break-all;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

[data-type="Resizer"] {
  opacity: 0 !important;
  border: none !important;
  width: 1.5px !important;
  background: #d4d4d4 !important;
  position: relative;
  right: 8px;
}

[data-type="Resizer"]:hover {
  opacity: 100 !important;
  border: none !important;
  width: 1.5px !important;
  background: #d4d4d4 !important;
}

[data-type="SplitPane"] {
  @media only screen and (max-width: 1024px) {
    flex-direction: column !important;
    gap: 8px !important;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  margin-bottom: 8px;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.actionWrapper {
  display: flex;
  gap: 16px;
}

.actionBar {
  display: flex;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.jsonSwitchContainer {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
}

.ltCommandLogParamsContainer {
  padding: 10px 16px 10px 36px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  @media only screen and (max-width: 1024px) {
    overflow: auto;
  }
}
.flakyBar {
  align-items: center;
  align-self: stretch;
  background-color: #FFEBE9;
  border: 1px solid rgba(255, 129, 130, 0.40);
  color: #333;
  display: flex;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 14px;
}

.viewTestGrpBtn {
  color: #409FF6;
  cursor: pointer;
  font-size: 10px;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-style: dashed;
}

.flakySubHeader {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
}

.flakySubHeader a:hover,
.flakySubHeader a:visited,
.flakySubHeader a:active {
  color: #0969DA;
}


.flakyAction {
  display: flex;
  gap: 8px;
}
.linkWrapper,
.linkWrapper:hover,
.linkWrapper:focus,
.linkWrapper:active {
  color: inherit;
  text-decoration: none;
}

.linkWrapper:focus .testItem {
  border: 1px solid #333;
  border-bottom: none;
  border-top: none;
  outline: 2px solid #0969da;
  outline-offset: -1px;
}

.testItem {
  border-bottom: none !important;
}

.testListContainer > li {
  border-left: 1px solid #d0d7de;
  border-right: 1px solid #d0d7de;
  border-bottom: 1px solid #d0d7de;
  border-color: #d0d7de !important;
}

.testListContainer > li:first-child {
  border-top: 1px solid #d0d7de;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.testListContainer > li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.noListStyle {
  list-style: none;
  padding: 0;
}

.tunnelInfoDiv {
  align-items: center;
  display: flex;
  gap: 8px;
}

a:hover {
  color: #1f2328;
}

.testDetailInfoContainer {
  max-height: 100%;
  padding: 16px 16px 8px 16px;
  /* @media only screen and (max-width: 1024px) {
    height: auto;
  } */
}

.screenshotContainer {
  cursor: pointer;
  /* height: 100%; */
  /* aspect-ratio: 4/2.5; */
  width: 100%;
  @media only screen and (max-width: 1024px) {
    height: auto;
    aspect-ratio: 2;
  }
}
.screenshotPrimerContainer {
  aspect-ratio: 4/2.5;
}

.borderedContainer {
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(66, 74, 83, 0.12);
  position: relative;
  overflow: hidden;
}

.downloadScreenshotWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.borderedContainer .screenshotOverlay {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
}

.borderedContainer:not(.loadingScreenshot):hover .screenshotOverlay,
.borderedContainer:not(.errorScreenshot):hover .screenshotOverlay {
  display: block;
}

.playIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.screenshotActionBtns {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px 20px;
  align-items: center;
}

.imgModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.imgModalContent {
  height: 90%;
  width: 90%;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.imgModal img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.screenshotHelpText {
  align-items: center;
  color: #666;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  gap: 2px;
  line-height: 18px;
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.videoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.linkModalContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}

.searchResults {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 300px;
}

.testResult {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 6px;
}

.testResult:hover {
  background-color: #f7f7f7;
}
.downtimeParent {
  height: 50px;
  width: 100%;
  z-index: 9999999999999999;
  left: 0px;
  position: fixed;
  background: #f89913;
  color: #fff;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.downtimeParent .message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  /* margin-top: 5px; */
  font-size: 15px;
  margin-left: 48px;
  letter-spacing: 0.3px;
  font-weight: 500;
}
.downtimeParent span {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.downtimeParent .closeBtn {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* margin-top: -30px; */
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
}
.warningIco {
  font-size: 20px;
  flex: 0;
}
.messageParent {
  flex: 4;
}

.productTitle {
  align-items: flex-end;
  color: #333;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: initial;
  margin-top: initial;
  position: relative;
}

.ltTopNavBtns {
  display: flex;
  gap: 5px;
}
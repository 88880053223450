.codeWrapper {
  background: rgba(246, 248, 252, 0.85);
  color: #333;
  display: flex;
  font-family: "Space Mono", sans-serif;
  margin: 20px 0;
  padding: 15px 30px;
}

.codeWrapperCopied .codeWrapperText {
  display: flex;
}

.codeWrapperCopied .codeWrapperCode {
  display: none;
}

.codeWrapperText {
  display: none;
  flex: 1;
  justify-content: center;
}

.codeWrapperCode {
  display: flex;
  flex: 1;
}

.capabilityCode {
  flex: 1;
}

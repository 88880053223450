@import "../../theme/colors.css";
@import "../../theme/typography.css";
@import "../../theme/fonts.css";

.Videocomponent {
  display: block;
}
.videojs2 {
  font-size: 3em;
  line-height: 1.5em;
  height: 4.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 37%;
  left: 81px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  /* border: 0.06666em solid #fff; */
  /* background-color: #2B333F; */
  /* background-color: rgba(43, 51, 63, 0.7); */
  /* border-radius: 0.3em; */
  transition: all 0.4s;
  width: 44px;
  height: 44px;
  box-shadow: 0 2px 22px 0 rgba(123, 123, 123, 0.5);
  border: solid 1px #eaeaea;
  background-color: var(--white-two);
  border-radius: 360px;
  background: #fff;
  color: #0ebac5;
}
.play_btn {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #fff !important;
  border-radius: 0.3em;
  transition: all 0.4s;
}

.refreshIcon {
  float: right;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0) !important;
}

.setupStream {
  margin-left: 36%;
  margin-top: 29%;
  z-index: 999;
  position: absolute;
  background: #ffffff91;
  padding: 20px;
  border-radius: 6px;
}

canvas {
  z-index: 97 !important;
}

/* Live Stream Button */

@keyframes up-right {
  0% {
    transform: scale(1);
    opacity: 0.25;
  }
  50% {
    transform: scale (1, 5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.25;
  }
}
.circle {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  opacity: 0.25;
}
.red {
  background-color: $colorAqua;
  position: absolute;
  top: -85%;
  left: 44%;
  -webkit-animation: up-right 1s infinite;
  -moz-animation: up-right 1s infinite;
  -o-animation: up-right 1s infinite;
  animation: up-right 1s infinite;
}
.loaderDiv {
  text-align: center;
}
.goToFullScreen {
  width: 30px;
  height: 25px;
  line-height: 23px;
  background: black;
  text-align: center;
  float: right;
  display: block;
  padding: 0;
  position: relative;
  z-index: 1;
}

.font8 {
  font-size: 8px;
}
.font9 {
  font-size: 9px;
}
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.font17 {
  font-size: 17px;
}
.font18 {
  font-size: 18px;
}
.font19 {
  font-size: 16px;
}
.font20 {
  font-size: 20px;
}
.font21 {
  font-size: 21px;
}
.font22 {
  font-size: 22px;
}
.font23 {
  font-size: 23px;
}
.font24 {
  font-size: 24px;
}
.font25 {
  font-size: 25px;
}

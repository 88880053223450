.drawerWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
  overflow: hidden;
}

.screenshotWrapper,
.liveStreamWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 50px;
  width: calc(93vw - 500px);
}

.liveStreamWrapper {
  height: 100%;
  padding: 0;
  width: 100%;
  @media only screen and (max-width: 320px) {
    min-height: 200px;
  }
}

.screenshotWrapper > img {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  outline: 1px solid #cfcfcf;
}

.infoWrapper {
  background: #ffffff;
  border-left: 1px solid #eaeaea;
  box-shadow: -3px 5px 14px rgba(209, 209, 209, 0.25);
  box-sizing: border-box;
  flex: 3;
  max-width: 500px;
  position: relative;
}

.liveInteractionBtnContainer {
  height: 38px;
  left: -20px;
  position: absolute;
  top: 45px;
  width: 38px;
}

.collapsedLiveInteractionBtn {
  background: url("../../../images/ml2.0/exit-fullscreen.svg");
}

.expandedLiveInteractionBtn {
  background: url("../../../images/ml2.0/enter-fullscreen.svg");
}

.expandedLiveInteraction {
  width: calc(93vw - 20px);
}

.shrinkLiveInteraction {
  width: 65%;
}

.liveStreamContainer {
  max-height: 100%;
  width: 100%;
}

.expandedLiveInteraction > .liveStreamContainer .liveStreamWrapper {
  width: calc(93vw - 20px);
}

.collapsedInfoWrapper {
  max-width: 20px;
}

.collapsedInfoWrapper .testDeviceSpecsWrapper,
.collapsedInfoWrapper .buttonsWrapper,
.collapsedInfoWrapper .headerWrapper,
.collapsedInfoWrapper .commandLogWrapper {
  display: none;
}

.collapsedInfoWrapper .closeDrawerIcon {
  left: -30px;
  position: absolute;
}

.infoWrapper > div {
  border-bottom: 1px solid #f1f3f8;
}

.infoWrapper > div:last-child {
  border-bottom: none;
}

.headerWrapper {
  display: flex;
  padding: 17px 23px;
}

.headerWrapper > img {
  cursor: pointer;
  margin-left: auto;
}

.headerTitle > span > h4 {
  margin-bottom: 0px;
  margin-right: 6px;
}

.headerTitle > span {
  color: #999999;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 6px;
}

.headerRunningState {
  align-items: center;
  border: 0.5px solid #219653;
  border-radius: 2.27423px;
  color: #219653;
  display: flex;
  font-size: 9px;
  height: fit-content;
  line-height: 11px;
  padding: 3px;
  width: fit-content;
}

.headerRunningState > img {
  margin-right: 3px;
}

.buttonsWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.actionButton {
  align-items: center;
  border: 0.8px solid #dfe6ef;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  gap: 8px;
  justify-content: center;
  padding: 5px 10px;
}

.actionButton:focus {
  outline: 1px solid #333;
}

.bugButton {
  background-color: #f7f7f7;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.refreshButton {
  background-color: #f7f7f7;
}

.abortTestCasesButton {
  color: #ffbb36;
  border-color: #ffbb36;
}

.actionButton:hover {
  background-color: #f7f9fa;
}

.codeWrapper {
  padding: 23px;
}

.codeWrapper > div > button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.codeWrapper > div > button.active {
  border-bottom: 1px solid #000;
}

.active {
  border-bottom: 1px solid #000;
}

.codeWrapper > div {
  position: relative;
}

.codeWrapper > div > h4 {
  margin-bottom: 15px;
}

.syntaxHighlighterClass {
  background: #24292f !important;
  border: none;
  border-radius: 8px;
  color: #ffffff !important;
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  margin-bottom: 35px;
  max-height: 220px;
  overflow: scroll;
  padding: 20px !important;
  word-wrap: initial !important;
  word-break: initial !important;
}

.syntaxHighlighterClass * {
  color: #fff !important;
  background-color: transparent !important;
}

.copyIcon {
  position: absolute;
  right: 15px;
  top: 45px;
}

.errorcode {
  background: #fae3e3 !important;
  color: #554d4d !important;
}

.cypress_error_msg {
  background-color: #fff;
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  padding: 20px 0px;
}

.cypressErrorContainer {
  font-size: 12px;
  margin-bottom: 35px;
  max-height: 200px;
  overflow: auto;
}

.abortImage {
  margin-right: 5px;
}

.logsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 320px) {
    flex-direction: column;
  }
}

.deviceInfoWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  @media only screen and (max-width: 1024) {
    flex-wrap: wrap;
  }
}

.commandLogsButton {
  @media only screen and (max-width: 512px) {
    display: none;
  }
}

.commandLogs {
  @media only screen and (max-width: 512px) {
    display: none;
  }
}

.drawerToast {
  @media only screen and (max-width: 320px) {
    display: none;
  }
}

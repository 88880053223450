.missingCapabilityLabel {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.missingCapabilityWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
}

.missingCapabilityContainer {
  background-color: #fff;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  overflow: auto;
}


.docsBtn {
  width: fit-content;
}

.docBtnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.capabilityCodeText {
  font-weight: 600;
}

.capabilityDesc {
  color: #666;
}
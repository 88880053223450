.mainDiv {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  height: 100%;
  margin: 0px;
}

.bannerPadding.mainDiv {
  padding-top: 93px;
}

.automationLogsPageContainer {
  font-size: 12px;
  height: 100%;
}

.wordBreak {
  word-break: break-all;
}

.metaInfoModalColumn {
  color: #4a4a4a;
  font-weight: 500;
  line-height: 2;
  padding: 2px 0 2px 2px;
  width: 15vw;
}

.metaInfoModalValue {
  color: #4a4a4a;
  line-height: 2;
  padding-left: 30px;
  padding-right: 15px;
  padding: 2px;
  position: relative;
  width: 35vw;
}

.metaInfoWrapper {
  margin: 5px 28px;
}

.metaInfoHeader {
  color: #000000;
  font-size: 16px;
  font-stretch: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.metaInfoHeader2 {
  color: #000000;
  font-size: 16px;
  font-stretch: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.topBarButtons {
  justify-content: flex-end;
}

.helpSupportBox {
  align-items: center;
  background-color: #ff6565;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 4px 7px 5px 5px;
}

.chatImage {
  height: 14px;
  margin-right: 6px;
  width: 14px;
}

.supportText {
  color: #ffffff;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

.modalBoxInnerContent {
  justify-content: space-between;
}

.customData {
  justify-content: space-between;
}

.copyIconWrapper {
  position: absolute;
  right: 25px;
  top: 5px;
  z-index: 999;
}

.metaDataTableInputConfig {
  font-size: 12px;
  max-height: 200px;
  min-height: 50px;
  min-width: 500px;
  overflow: auto;
  position: relative;
}

.customError {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 0.82;
  position: absolute;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  top: 40%;
}

.testLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.fullHeight {
  height: 100%;
}

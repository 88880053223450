.testListWrapper {
  flex: 3;
  border-right: 1px solid #eaeaea;
  max-width: 25%;
}
.hideTestList {
  flex: 3;
  border-right: 1px solid #eaeaea;
  max-width: 0%;
}
.testDetailInfoWrapper {
  background-color: #fafcff;
  height: 100%;
  width: 50%;
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.testDetailWrapper {
  flex: 4;
}
.testDetailLargeWrapper {
  flex: 7;
}
.testDetailPageWrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    overflow: unset;
  }
}

.testDetailPageWrapper > img {
  align-self: center;
  width: 100%;
}

.testDetailPageWrapperShared {
  padding-right: 15px;
  width: 100vw;
  @media only screen and (max-width: 1024px) {
    padding-right: 0px;
  }
}

.wider {
  display: flex;
  flex: 1;
  width: 50%;
}

.fullscreenStream {
  z-index: 1000;
  border-radius: 4px;
}
.fullscreenStream .close {
  float: right;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 3px;
  margin-right: 8px;
}
.fullscreenStream .footer {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 4px;
  margin-left: 13px;
  margin-top: -11px;
  padding: 2px;
}

.fullscreenStream .liveStreamWrapper {
  padding: 10px;
  margin-top: 15px;
  min-height: 494px;
  min-width: 1000px;
}
.stopButtonDivFullscreen {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}
.runningImage {
  margin-left: 5px;
  max-width: 54px;
}
.breakButton {
  cursor: pointer;
  background-color: #ffbb36;
  color: #fff;
  margin-right: 5px;
  font-size: 15px;
  padding: 10px 9px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 6px;
}
.backToTestView {
  cursor: pointer;
  background-color: #3e9cf2;
  color: #fff;
  margin-right: 5px;
  font-size: 15px;
  padding: 10px 9px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
}
.abortImage {
  margin-right: 5px;
}

.chatSupportBtn {
  color: #3366ff;
  cursor: pointer;
  margin-left: 5px;
}

.customError {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 0.82;
  position: absolute;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  top: 40%;
}

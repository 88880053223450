.statusChangeForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 16px;
  gap: 16px;
  @media only screen and (max-width: 1024px) {
    overflow: auto;
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.testDetailTopBar {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #747474;
  display: flex;
  font-size: 12px;
  height: 45px;
  letter-spacing: 0.4px;
  padding: 8px 15px;
}

.testDetailTopBar > span {
  color: #0ebac5;
  cursor: pointer;
}
.backIconContainer {
  align-items: center;
  border: 0.8px solid #dfe6ef;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-right: 11px;
  padding: 6px;
  width: 28px;
}

.drawerIconContainer {
  align-items: center;
  border: 0.8px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-right: 11px;
  min-width: 29px;
  padding: 0px 6px;
  width: auto;
}

.drawerIconContainer > img {
  margin-right: 5px;
}

.brandLogo {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.renameModal {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  width: 100%;
}

.deleteModal {
  padding: 16px;
}

.buildHeaderActions {
  display: flex;
  gap: 4px;
}

.remark,
.flakyBar {
  align-items: flex-start;
  align-self: stretch;
  color: #333;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 14px;
}

.flakyBar {
  background-color: #ffebe9;
  border: 1px solid rgba(255, 129, 130, 0.4);
  font-weight: 600;
}

.remark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background: #ddf4ff;
  border: 1px solid #54aeff66;
}

.actionBar {
  display: flex;
}

.reasonContainer {
  height: calc(100vh - 168px);
  overflow: auto;
}

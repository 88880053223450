.shareBuildFormField {
  flex-direction: column;
  display: flex;
  margin-bottom: 15px;
  padding: 16px;
  gap: 16px;
  @media only screen and (max-width: 1024px) {
    overflow: auto;
  }
}
.sectionHeading {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 4px;
}

.checkIcon {
  margin-right: 8px;
}

.requiredHeading:after {
  content: "*";
}

.emailError {
  align-items: center;
  background: #ffebe9;
  border: 1px rgba(255, 129, 130, 0.4) solid;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  gap: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  padding: 8px 16px;
  width: 100%;
}

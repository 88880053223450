.loaderDiv {
  /* width: 100%;
  height: 100%;
  position: absolute; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
  padding: 20px;
}

.loaderDiv .displayText {
  margin-top: 5px;
  font-size: 12px;
}
.buildViewLogo {
  height: 40px;
  width: 40px;
  margin-bottom: 7px;
  border-radius: 40px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.traceParent {
  width: 100%;
}
.baseStyle {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 72px;
  height: 40px;
  position: relative;
  border-left: 0px;
  border-right: 0px;
}
.buildViewDate {
  height: 26px;
  margin-bottom: 7px;
}

.buildViewTime {
  height: 12px;
  width: 114px;
}
.buildViewName {
  height: 20px;
  margin-top: 9px;
}
.buildViewSummary {
  height: 20px;
  margin-top: 9px;
}
.buildViewStatus {
  height: 20px;
  margin-top: 9px;
}
.buildNavBar {
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
}
.buildViewNameNavBar {
  height: 12px;
  width: 200px;
  display: inline-block;
}
.testInfiniteWrapper {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  border-right: 1px solid #eaeaea;
}
.testInfinitSellectAll {
  height: 50px;
  padding: 15px;
  border: 1px solid #eaeaea;
}
.testViewSelectall {
  height: 20px;
}
.col_xs_4_custom {
  width: 31.3333% !important;
}
.col_xs_4_custom_2 {
  width: 35.3333% !important;
}
.testCheckBox {
  width: 15px;
  height: 15px;
}
.testStatus {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: 20px;
  float: right;
}
.testName {
  height: 25px;
  margin-bottom: 10px;
}
.testSummary {
  height: 20px;
  width: 50%;
}
.testVideo {
  height: 270px;
  margin-left: 20px;
  width: 370px;
}
.testDurationSummary {
  height: 20px;
  margin-top: 14px;
  margin-left: 20px;
  margin-bottom: 15px;
}
.timeline_item {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  /* padding: 12px; */
  margin: 0 auto;
  max-width: 100%;
  min-height: 100px;
  border-right: 0px;
  border-left: 0px;
  display: flex;
  align-items: center;
}
.buildname {
  width: 250px;
  margin-left: 15px;
}
.testView_item {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  padding: 12px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 55px;
  border-right: 0px;
  border-left: 0px;
}
.commandlog {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  padding: 12px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 40px;
  border-right: 0px;
  border-left: 0px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.groupedTest {
  background: #f6f8fa;
  border-bottom: 0.5px solid #eaeaea;
  padding: 4px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 37px;
}

.sidebarGroupedTest {
  padding: 4px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 16px;
}

.testViewDate {
  height: 5px;
  margin-top: 12px;
}

.testViewName {
  height: 5px;
  margin-top: 12px;
}

.testViewSummary {
  height: 5px;
  margin-top: 12px;
}
.testViewStatus {
  height: 20px;
  width: 20px;
  border-radius: 360px;
  margin-top: 4px;
}

.analyticsCounter_item {
  background: #fff;
  border: 0px;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  /* padding: 12px; */
  margin: 0 auto;
  max-width: 100%;
  min-height: 55px;
}

.analyticsViewName {
  height: 65px;
  border-radius: 5px;
}

.testDetailsHeading {
  height: 15px;
}
.testDetailsLocalParent {
  padding: 20px;
}

.testDetailsChild {
  height: 7px;
}
.testDetailsVideoPlaceHolder {
  height: 463px;
}
.testDetailsChildParent {
  padding: 5px;
}
.testDetailsVideoParent {
  padding-top: 15px;
}
.marginClass {
  margin-top: 6px;
}

.timeline_tabs {
  height: 45px;
  background: #fff;
  width: 16%;
  display: inline-block;
  padding: 10px;
}

.textStyle {
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 0;
  color: #4a4a4a;
  letter-spacing: 2.2px;
  font-size: 18px;
}

.filterBarTrace {
  min-height: 75px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterBarElem {
  height: 17px;
  /* margin-bottom: 0px; */
  margin-top: 5px;
  width: 89px;
}
.filterBarLabel {
  height: 17px;
  /* margin-bottom: 0px; */
  margin-top: 5px;
  width: 125px;
}
.videoColumn {
  margin-left: 80px;
}
.testDetailsHeading {
  height: 14px;
}
.testDetailsSummary {
  height: 12px;
}
.debuggerTab {
  height: 40px;
}

.search {
  background: #fff;
  border-bottom: 1px solid #e1e9ec;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  min-height: 40px;
  padding: 5px 0;
}

.search:last-child {
  border-bottom: none;
}

.searchStatus {
  height: 5px;
  width: 80px;
}

.searchName {
  width: 200px;
}

.searchDate {
  width: 100px;
}

.searchName,
.searchDate {
  height: 5px;
}

.tableColumn {
  height: 10px;
  margin-top: 12px;
}

.traceCol {
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  overflow: hidden;
}

.traceCol-1 {
  width: 8%;
}

.traceCol-2 {
  width: 16.66%;
}

.traceCol-3 {
  width: 25%;
}

.traceCol-4 {
  width: 33.33%;
}

.traceCol-5 {
  width: 41.66666667%;
}

.traceCol-6 {
  width: 50%;
}

.traceCol-7 {
  width: 58.3333333%;
}

.traceCol-8 {
  width: 66.66666667%;
}

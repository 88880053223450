.errorBoundaryWrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reloadButton {
  align-items: center;
  background: #101010;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  margin-top: 20px;
  padding: 8px 16px;
}

.mainContainer {
  height: 100%;
  position: relative;
}

.videoComponent {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(66, 74, 83, 0.12);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1024px) {
    height: auto;
    aspect-ratio: 2 !important;
  }
}

.videoAspect {
  aspect-ratio: 4/2.5;
}

.videoComponent > div:nth-child(1) {
  border-radius: 6px;
  overflow: hidden;
}

.videoComponent > div.testMenu {
  padding: 2px 10px;
}
.videoComponent > div {
  width: 100%;
}
.videoCapabiltyHeading {
  padding: 7px 23px 7px 8px;
  border-radius: 3px;
  border: solid 1px rgba(224, 57, 57, 0.15);
  background-color: rgba(255, 175, 175, 0.12);
  color: #591111;
  font-size: 12px;
  /* width: 56%; */
}
.videoCapabiltyMsg {
  line-height: 1.36;
  letter-spacing: normal;
  color: #000000;
  font-size: 11px;
}
.videoCapabiltyMsg span {
  font-weight: bold;
}
.videoCapabiltyGeneraterLink {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #000;
  cursor: pointer;
}
.videoCapabiltyGeneraterLink:hover {
  color: #000;
  text-decoration: none;
}

.liveInteractionBtn {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 26px;
  line-height: 12px;
  padding: 7px 7px 7px 0px;
  position: absolute;
  right: 18px;
  top: 11px;
  width: auto;
  z-index: 98;
}

.messageContainer {
  align-items: center;
  background: #333;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  aspect-ratio: 4/2.5;
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
}
.videoCapablityFalseMessageWrapper {
  background-color: #ffffff;
  text-align: center;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messageHeading {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 20px;
  text-align: center;
  padding: 0px 8px;
}
.messageDescription {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.learnMore {
  color: #ffffff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-decoration: underline !important;
  cursor: pointer;
}
.learnMore:hover {
  color: #fff;
}
.learnMore:focus {
  outline: 2px solid #2f81f7;
}
.iconVideoNot {
  display: flex;
}
.pauseBtn {
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 12px;
}
.realTimeStart {
  padding: 6px 7px;
  cursor: pointer;
  background-color: #43bac5;
  width: 138px;
  text-transform: uppercase;
  color: #fff;
  margin-right: 5px;
  /* min-width: 200px; */
  display: inline-flex;
}
.breakButton {
  cursor: pointer;
  background-color: #43bac5;
  color: #fff;
  margin-right: 5px;
  font-size: 12px;
  padding: 14px 27px;
}
.messageContainerRealMobile {
  align-items: center;
  background-color: #333;
  border-radius: 1.2px;
  border: solid 4.9px #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 15px;
  text-align: center !important;
}
.contentContainerRealMobile {
  text-align: left;
  margin-left: 20px;
}
.messageContainerRealMobile .messageHeading:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

.errorMsgWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.lottieContainer {
  display: flex;
  padding-top: 9px;
}

.liveStreamWrapper {
  aspect-ratio: 4/2.5;
  display: flex;
  justify-content: center;
  max-height: 100%;
  padding: 0;
  width: 100%;
  @media only screen and (max-width: 320px) {
    min-height: 200px;
  }
}

.streamLoadingContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.loadingBorder {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
}

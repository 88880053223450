.automationLogsPage .accordion > .card > .card-header {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.automationLogsPage .card-header:first-child {
  border-radius: 0 !important;
}
.automationLogsPage .card-header {
  padding: unset;
  margin-bottom: 0;
  background-color: unset;
  border-bottom: unset;
}
.automationLogsPage .card {
  border: unset;
  border-bottom: unset !important;
}

.automationLogsPage .collapse {
  box-sizing: unset !important;
}
.automationLogsPage .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0ebac5 !important;
}
.automationLogsPage .nav-link {
  padding: 10px !important;
}
.automationLogsPage .automationLogsPage .nav-tabs .nav-link {
  color: #4a4a4a;
  border: unset !important;
}
.automationLogsPage .table thead th {
  border-bottom: unset !important;
}
.automationLogsPage .lh-topbar {
  z-index: 5 !important;
}

.automationLogsPage .lh-topbar__url {
  position: absolute;
  left: 25px;
  width: 93%;
}

.automationLogsPage .lh-sticky-header--visible {
  position: absolute !important;
  width: 700px;
  top: unset !important;
  left: unset !important;
  right: unset !important;
}

.automationLogsPage .nav-tabs li.active {
  border-bottom: 2px solid #0ebac5 !important;
}
.automationLogsPage .nav-tabs li a {
  color: #4a4a4a;
  border: unset !important;
  line-height: 1.31;
  padding: 15px 10px;
}
.automationLogsPage .activeNested.nav-tabs li.active {
  border-bottom: unset !important;
  background-color: #0ebac5;
  color: #fff;
}
.automationLogsPage .panel-default > .panel-heading {
  color: #3c4144;
  background-color: unset !important;
  border-color: unset !important;
  font-size: 14px !important;
  padding: 0px !important;
}
.automationLogsPage .panel-default {
  border-color: #fff !important;
}
.automationLogsPage .panel {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.automationLogsPage .panel-title a {
  text-decoration: none !important;
}
.automationLogsPage
  .panel-group
  .panel-heading
  + .panel-collapse
  > .panel-body {
  border: unset !important;
}


.sideNavCollapsed .testDetailLeftPane {
  width: 60%;
}

.sideNavCollapsed .testDetailRightPane {
  width: 40%;
}

.ltDrawerHeader {
  align-items: center;
  background-color: #fafcff;
  display: flex;
  gap: 1rem;
  height: 51px;
  max-height: 51px;
  min-width: 100%;
  padding: 12px;
  & > div:first-child {
    width: 20%;

    & > div:first-child {
      width: 100%;
    }
  }
}

.ltSearchBox {
  align-items: center;
  display: flex;
  width: 40%;
}

.ltDrawerInputBoxContainer {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  display: flex;
  height: 28px;
  padding-left: 11px;
  padding-right: 11px;
  position: relative;
  width: 100%;
}

.ltDrawerInput {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: #333333;
  font-size: 10px;
  font-weight: normal;
  line-height: 12.1px;
  outline: none;
  padding-left: 8px;
  position: relative;
  width: 90%;
}

::placeholder {
  color: #c7c7c7;
  font-size: 10px;
}

.ltButtonDefault {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  color: #666666;
  display: flex;
  font-size: 10px;
  font-weight: normal;
  gap: 0.5rem;
  height: 28px;
  justify-items: center;
  line-height: 12px;
  outline: none;
  position: relative;
}

.ltButton {
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  width: 100%;
}

.ltButtonLink {
  background-color: transparent;
  border: none;
  width: auto;
}

.ltButtonIcon {
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  position: relative;
  width: auto;
}

.ltBadgeClasses {
  align-items: center;
  background-color: #0ebac5;
  border-color: #0ebac5;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 18%;
  top: 4px;
  width: 16px;
}

.ltBadgeActive {
  align-items: center;
  background-color: #0ebac5;
  border-color: #0ebac5;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 33%;
  top: 4px;
  width: 16px;
}

.ltPrimaryText {
  color: #0ebac5;
}

.crossIcon {
  bottom: 6px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  padding: 2px;
  position: absolute;
  right: 5px;
  width: 14px;
  z-index: 9;
}

.checkboxContainer {
  align-items: center;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: #dfe6ef;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  gap: 8px;
  line-height: 20px;
  min-width: 150px;
  position: relative;
  padding: 8px;
  width: auto;
}
.ltCloseButton {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  color: #0ebac5;
  display: flex;
  font-size: 10px;
  font-weight: normal;
  gap: 0.5rem;
  height: 28px;
  justify-items: center;
  line-height: 12px;
  outline: none;
  position: relative;
}

.ltButtonDefault:focus,
.ltSearchBox:focus {
  outline: 1px solid #333;
}

.ltDateButton {
  min-width: 180px !important;
}

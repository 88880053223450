.Componenterror {
  display: block;
}
.wrapper {
  height: 77vh;
  background: #fff;
  background-repeat: no-repeat;
  /* background-size: 300vh 100vh; */
  background-size: cover;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  display: flex;
  justify-content: center;
}

.description {
  width: 597px;
  font-family: Loto;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: left;
  color: #000000;
  margin-top: 30px;
}

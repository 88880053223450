.stream_container {
  position: relative;
  height: inherit;
}

.loaderDiv {
  transform: translateY(25%);
}

.webrtc_stream_parent {
  display: flex;
  justify-content: center;
  height: inherit;
  position: relative;
}

.webrtc_remoteVideo {
  height: 95%;
  width: 95%;
}

.webrtc_canvas {
  height: 100%;
  position: absolute;
  top: 0px;
  width: 95%;
}

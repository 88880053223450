.buildName {
  margin: 2px;
}
.buildName:hover {
  & > span {
    color: #0969da;
    text-decoration: underline;
    cursor: pointer;
  }
}
.buildName:focus {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  border-radius: 4px;
}

.noListStyle {
  list-style: none;
  padding: 0;
}

.mainDiv {
  margin: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 51px;
  padding-left: 195px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
}

.testDetailTopBar {
  font-size: 10px;
  letter-spacing: 0.4px;
  color: #747474;
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
}
.testDetailTopBar span {
  color: #0ebac5;
  cursor: pointer;
}
.filter_bar_option {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buildname {
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.24px;
  color: #47c5d8;
  font-size: 12px;
  padding: 16px;
}
.searchTest {
  font-size: 12px;
  color: #9b9b9b !important;
  border: none;
  box-shadow: none !important;
  font-style: italic;
  padding-left: 31px;
}
.searchTestParent {
  position: relative;
  padding-left: 10px;
}
.searchIcon {
  background: url(../../../images/ml2.0/search.svg);
  background-repeat: no-repeat;
  background-position: 12px 4px;
  background-size: 12px 12px;
  width: 30px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 1;
}
.test_concurrency {
  display: inline-block;
  margin: 0px 20px;
  letter-spacing: 0.4px;
  color: #4a4a4a;
  font-size: 10px;
}
.analytics_btn {
  margin-right: 10px;
  padding: 6px 13px 6px 10px;
  border-radius: 100px;
  background-color: #0ebac5;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.filter_bar {
  font-size: 12px;
}
.accesskey_btn {
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 100px;
  background-color: #fff;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.metaInfo {
  margin-left: auto;
  cursor: pointer;
}
.help_btn {
  padding: 4px 4px 3px;
  border-radius: 100px;
  background-color: #e2e2e2;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.filter_rightbar_options {
  display: flex;
  float: right;
}
.filter_bar_option {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_filter_btn {
  color: #0ebac5;
  cursor: pointer;
  font-size: 12px;
}
.clear_filter_btn {
  color: #0ebac5;
  cursor: pointer;
}

.progress5 {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #f6a39f);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.progress0 {
  display: block;
  background: linear-gradient(to right, #ece7e6, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.progress1 {
  display: block;
  background: linear-gradient(to right, #a2e58d, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.progress2 {
  display: block;
  background: linear-gradient(to right, #a2e58d, #efef55, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.progress3 {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.progress4 {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #e6c5c4);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}

.dropdownSelect {
  display: flex !important;
  align-items: baseline;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  margin: 10px 0px 10px 10px;
  line-height: initial;
}
.dropdownSelect select {
  display: inline-block;
  border: 1px solid #ebebeb;
  padding: 0px 40px 0 15px;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  border-radius: 50px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
}

.dropdownSelect select:after {
  content: "";
  width: 30px;
  height: 30px;
  top: 10px;
  right: 5px;
  position: absolute;
  display: inline-block;
}
.dropdownSelect .labelText {
  margin-right: 10px;
}

.filterbar {
  border-bottom: 1px solid #eaeaea;
}

/* #popover .help_btn  */
.basic {
  color: #333;
  max-width: 600px !important;
  width: 380px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}
.help_popover {
  color: #333;
  max-width: 600px !important;
  width: 300px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}

.menuHead {
  color: #777;
  padding-left: 18px;
  font-size: 18px !important;
  padding-top: 10px;
}
.helpMenuList {
  padding-left: 18px !important;
}

.gitpod__modal_link {
  cursor: pointer;
}

.access_key_field {
  background-color: #eee;
  border-radius: 20px;
  height: 30px;
  font-size: 12px;
}
.access_key {
  padding: 0px 20px;
  font-size: 12px !important;
}
.copy_key {
  float: right;
  width: 12px;
  height: 12px;
  top: 9px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.access_key_header {
  font-size: 16px !important;
}
.access_key_row {
  margin-bottom: 10px !important;
}
.btnBase {
  border-radius: 5px;
  color: #fff;
  background-color: #43bac5;
  border: 0px;
  padding: 7px;
  margin: 10px 0px;
}
.copied {
  position: absolute;
  right: 14px;
  top: 4px;
  background-color: #86d819;
  padding: 2px;
  border-radius: 21px;
}
.access_key_genereted {
  display: flex;
  align-items: center;
  color: #86d819;
  font-size: 14px;
}
.metadataHeader {
  letter-spacing: 0.35px !important;
  color: #3c4144;
  cursor: pointer;
  font-size: 14px !important;
}

.metaDataTable {
  max-height: 200px;
  overflow: auto;
  font-size: 12px;
  /* padding: 5px 20px; */
}
.metaDataColumn {
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  padding: 5px;
}
.metaDataValue {
  letter-spacing: 0.3px;
  color: #3c4144;
}
.wordBreak {
  word-break: break-all;
}
.tunnelPill {
  width: 24px;
  height: 20px;
  background-color: #e2e2e2;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.deleteTestIcon {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff6565;
}

.shareTestIcon {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.attachmentWrapper {
  display: flex;
  font-size: 12px;
}
.attachmentName {
  display: inline-block;
  letter-spacing: 0.3px;
  color: #3c4144;
}
.attachmentSize {
  letter-spacing: 0.25px;
  color: #9b9b9b;
  font-size: 10px;
  display: inline-block;
}
.attachmentRow:hover .actiondiv {
  display: block;
}
.action_btn {
  border-radius: 5px;
  border: 1px solid #979797;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  margin-right: 8px;
}
.actiondiv {
  display: none;
  text-align: center;
}
.attachmentRow {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.attachmentRow:hover {
  background-color: #f8fcff;
}
.passedBuildStatusBar {
  background-color: #d8f5e7;
  display: flex;
  align-items: center;
  padding: 10px;
}

.buildName {
  letter-spacing: 0.29px;
  color: #16985a;
  font-size: 10px;
}
.buildStatus {
  padding: 5px;
  background-color: #37e291;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.buildStatusIcon {
  border-radius: 100%;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 2px 6px 2px 6px;
}
/* .buildStatusIcon img {
    width: 17px;
    height: 13px;
  } */
.errorBuildStatusBar {
  background-color: #fff5e5;
  display: flex;
  align-items: center;
  padding: 10px;
}
.errorBuildName {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.errorBuildStatus {
  padding: 5px;
  background-color: #ffa001;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.cancelledBuildStatusBar {
  background-color: #fbeadb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.cancelledBuildName {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.cancelledBuildStatus {
  padding: 5px;
  background-color: #e67e22;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.queuedBuildStatusBar {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.queuedBuildName {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.queuedBuildStatusBarNoStatus {
  border: solid 1px #4a4a4a;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
}
.queuedBuildNameNoStatus {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.queuedBuildStatus {
  padding: 5px;
  background-color: #9cabb5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.queuedStatusImg {
  border-radius: 10px;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  vertical-align: middle;
}
.cypress_error_msg {
  color: #4a4a4a;
  letter-spacing: 0.3px;
  font-size: 12px;
  background-color: #fff;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.errorcode {
  background: #fae3e3 !important;
  color: #554d4d !important;
}

.stoppedBuildStatusBar {
  background-color: #fbdbdb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.stoppedBuildName {
  letter-spacing: 0.29px;
  color: #9d4242;
  font-size: 10px;
}
.stoppedBuildStatus {
  padding: 4px 5px 5px 9px;
  background-color: #ff6565;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.lambdaErrorBuildStatusBar {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.lambdaErrorBuildName {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.lambdaErrorBuildStatus {
  padding: 4px 5px 5px 9px;
  background-color: #0ebac5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.timeoutBuildStatusBar {
  background-color: #fdf9e7;
  display: flex;
  align-items: center;
  padding: 10px;
}
.timeoutdBuildName {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.timeoutBuildStatus {
  padding: 5px;
  background-color: #f1c40f;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.logsTabSection {
  color: #4a4a4a;
  font-size: 12px;
}
.searchLog {
  height: 28px;
  border-radius: 4px;
  padding-left: 30px;
  /* padding-right: 20px; */
  font-size: 12px;
  color: #61605c !important;
  border: solid 1px #eaeaea !important;
  box-shadow: none !important;
}
.searchLogParent {
  position: relative;
  width: 50%;
}
.searchLogIcon {
  background: url(../../../images/ml2.0/search.svg);
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 12px 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}
.filterlogs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fcfcfc;
  border-bottom: 0.8px solid #f1f3f8;
}

.col_xs_4_custom_1 {
  width: 31.3333%;
}
.col_xs_4_custom_2 {
  width: 35.3333%;
}
.col_xs_8_custom {
  width: 67.3333%;
}

.checkboxWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 15px;
}
.checkboxContainerLabel {
  font-family: "Inter", sans-serif;
  margin: 0px 10px 0px 5px;
}
.checkboxContainer input[type="checkbox"] {
  position: relative;
  width: 14px;
  height: 14px;
  color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
.checkboxContainer input[type="checkbox"]:before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 3px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.checkboxContainer input[type="checkbox"]:checked {
  color: #fff;
  border-color: #eaeaea;
  background: #101010;
}
.checkboxContainer input[type="checkbox"]:checked:before {
  opacity: 1;
}
.checkboxContainer input[type="checkbox"]:checked ~ label:before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.commandsLogWrapper {
  font-size: 12px;
  overflow-y: auto;
  @media only screen and (max-width: 1024px) {
    min-height: 200px;
  }
}

.commandsLog:hover .bugBtn,
.commandsLog:focus-within .bugBtn {
  display: block;
  border: 1px solid #ff6565;
}
.bugBtn {
  display: flex;
}
.commandsLogUrlText {
  color: #4a4a4a;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.commandsLogUrlLink {
  color: #666;
  font-family: Space Mono;
  font-size: 10px;
  letter-spacing: normal;
  line-height: 15px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .commandsLogUrlLink,
  .commandsLogUrlText {
    margin-left: 10px;
  }
}

.playVideo {
  display: flex;
  justify-content: space-between;
}
.playVideoIcon {
  align-items: center;
  background: rgba(246, 248, 252, 0.85);
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  padding: 5px 7px;
}
.activePlayVideoIcon {
  background: #2f80ed;
  color: #fff;
}
.playIconImg {
  margin-right: 3px;
}

.markAsBugBtn {
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  background-color: #0ebac5;
  padding: 5px;
  border-radius: 2px;
}

.testListHeader {
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.testListHeader > div {
  padding: 15px 7px;
}
.testListBuildStatusBar {
  margin-top: 15px;
}
.testListBuildName {
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}
span[role="img"].lambdaErrorText {
  color: #ff6565;
}
span[role="img"].error {
  color: #f5a623;
}
span[role="img"].failed {
  color: #ff6565;
}
span[role="img"].timeout {
  color: #4768fd;
}
span[role="img"].greyedOut {
  color: #e0d8d8;
}
.greyedOut {
  color: #e0d8d8;
}
.testListBody {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4a4a4a;
  align-items: center;
}
.testDetailDiv {
  display: flex;
}
.selectTestCheckbox {
  margin: 0px !important;
}
.testDuration {
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #9b9b9b;
}
.tagName {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #ebf5fc;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  margin-right: 10px;
}
.cypress_suite_title {
  background-color: #eaeaea;
  color: #4a4a4a;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  padding: 10px;
}
.logsLeftDiv {
  border-right: 1px solid #d0c9d6;
}
.tagNameMore {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #fff;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  border: 1px solid #e2e2e2;
  margin-right: 10px;
}
.net_tab {
  border-bottom: unset;
  margin: 15px 10px;
  position: relative;
}
.net_tab2 {
  border-bottom: unset;
  margin: 15px 10px;
  background-color: green;
}
.rawLogsTab {
  margin-top: 10px;
}
.networkLogTable {
  color: #4a4a4a;
}
.networkLogTable th {
  border: unset;
}
.networkLogTable td {
  border: unset;
}
.download_report {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
  width: 100%;
}

.download_report span {
  background-color: #f1f2f4;
  color: #4a4a4a;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 8px 10px 5px 10px;
  text-align: right;
  width: 100px;
  cursor: pointer;
}
.performance {
  height: 600px;
  overflow: auto;
}
.panelBox {
  position: relative;
  padding-left: 35px;
}
.panelItem {
  border: none;
  border-top: 0.1px solid #eaeaea;
  border-radius: 0px;
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.panelItem :hover {
  background: #f9f9fa;
}

.viewCheckbox input {
  top: -2px !important;
}

.statusIcon {
  position: absolute;
  right: 30px;
  margin-top: 5px;
}

.videoComponent {
  direction: rtl;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  /* margin-top: 4px; */
  position: relative;
  /* margin-top: 25px; */
  position: relative;
  /* height: 170px; */
}

.videoComponent > div:nth-child(1) {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow: hidden;
}

.videoComponent > div.testMenu {
  padding: 2px 10px;
}
.videoComponent > div {
  width: 100%;
  height: 270px !important;
}
.modalClass div[role="document"] {
  width: 324px;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  margin: 1% auto 0;
  top: 200px;
}
.shareBuildFormField {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.shareModal div[role="document"] {
  width: 500px !important;
  top: 150px !important;
}
.headerModal img {
  /* float: right;
    cursor: pointer; */
  /* position: absolute;
    right: 25px; */
}
.headerModal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.headerModal h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.share_done_btn {
  border-radius: 5px;
  background-color: #0ebac5;
  color: #fff;
}
.share_done_btn:hover {
  background-color: #0ebac5;
}
.share_cancel_btn {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4a4a4a;
  padding: 10px;
  cursor: pointer;
}
.shareableLink {
  border-radius: 2px;
  border: solid 1px #eaeaea;
  background-color: #fafafa;
  text-align: center;
  padding: 6px 0px;
  cursor: pointer;
}

.shareableLink span {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 6px;
}
.shareTestForm {
  margin-top: 20px;
  letter-spacing: 0.41px;
  color: #4a4a4a;
  font-size: 12px;
}
.expiry_drop_down {
  font-size: 12px !important;
}
.errorText {
  color: #ff0000;
  font-size: 11px;
}
.shareLink {
  font-size: 12px;
}
.copyBtn {
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
.copied {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 16px;
  background-color: #86d819;
  border-radius: 10px;
  padding: 2px;
}

.actionContent {
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  font-size: 15px;
  margin-top: 15px;
}
.actionButtons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.actionButtons button {
  padding: 9px 38px 9px 40px;
  border-radius: 4px;
  margin-top: 20px;
}
.cancelBtn {
  border: solid 0.5px #000000;
  background-color: #fff;
  color: #111;
  text-align: center;
}
.cancelBtn:hover {
  background-color: #0ebac5;
}
.deleteBtn:hover {
  background-color: #ff6565;
  border: unset;
}
.deleteBtn {
  background-color: #ff6565;
  color: #ffffff;
  text-align: center;
  border: unset;
}
.headerModal img {
  /* float: right;
    cursor: pointer; */
}
.headerModal h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.commandsLog div[role="document"] {
  width: 1000px !important;
  top: 50px !important;
}
.modalResponseHeader {
  letter-spacing: 0.35px;
  color: #000000;
  font-size: 14px;
  margin: 15px 0px;
}
.metaInfoModal div[role="document"] {
  width: 90% !important;
  top: 50px !important;
  max-width: 1100px;
}
.actionWrapperLogImage {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-top: 1px solid #d0c9d6;
}
.actionWrapperLogImage .actiondiv {
  display: flex !important;
  align-items: center !important;
}
.markAsBugBtnCmdLog {
  color: #fff;
  background-color: #ff6565;
  font-size: 12px;
  padding: 6px 15px;
}
.markAsBugBtnCmdLog svg {
  margin-right: 5px;
}
.markAsBugBtnCmdLog:hover {
  color: #fff;
  background-color: #ff6565;
}
.testSuitsTab {
  position: relative;
  top: -40px;
  left: 10px;
}
.testSuitsTab span {
  display: inline-block;
  padding: 5px;
  background-color: #eaeaea;
  margin-right: 5px;
  cursor: pointer;
}
.saveBtn {
  color: #fff;
  background-color: #4a4a4a;
  font-size: 12px;
  padding: 6px 15px;
}
.cmdLogsIconsHover {
  display: none;
  cursor: pointer;
}
.commandsLog:hover .cmdLogsIconsHover {
  display: unset !important;
}
.commandsLog:hover .cmdLogsIcons {
  display: none;
}
.metaInfoHeader {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.metaInfoHeader2 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.metaInfoModalColumn {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px 0 2px 2px;
  font-weight: 500;
  width: 15vw;
}
.metaInfoModalValue {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px;
  position: relative;
  padding-left: 30px;
  padding-right: 15px;
  width: 35vw;
}
.copyIconWrapper {
  position: absolute;
  top: 5px;
  right: 25px;
  z-index: 999;
}
.metaDataTableInputConfig {
  max-height: 200px;
  min-height: 50px;
  min-width: 500px;
  overflow: auto;
  font-size: 12px;
  position: relative;
}
.loaderLogs {
  background: #2b2b2b;
  height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.downloadNeworkLogWrapper {
  position: relative;
  top: 4px;
  color: #43bac5;
  float: right;
  padding: 0px 10px;
  z-index: 1;
}
.downloadLogDropdown {
  background-color: #43bac5;
  color: #fff;
}
.downloadLogDropdownItem {
  color: #4a4a4a;
  font-size: 12px;
}
.downloadConsoleButton {
  z-index: 9;
  position: absolute;
  top: 4px;
  right: 0px;
  padding: 0px 10px;
}

.consoleLogWrapper {
  position: relative;
}

.commandLogRow {
  align-items: center;
  display: flex;
  gap: 5px;
  min-height: 38px;
  padding: 5px 0;
}

.commandLogCdpRow {
  padding: 5px 10px 5px 0;
}

.commandLogRow:hover {
  background: #f1f4f9;
}

.modalBoxInnerContent {
  justify-content: space-between;
  /* width: 1024px; */
}

.customData {
  justify-content: space-between;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.headerRowAlignment {
  /* justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px; */
}
.metaInfoWrapper {
  margin: 5px 28px;
}
.copyImage {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.copyImagePosition {
  position: relative;
  float: right;
  cursor: pointer;
  z-index: 100;
}
.downloadIcon {
  margin-left: -7px;
  position: fixed;
  margin-top: 2px;
}

.noStatusBuildStatusBar {
  height: 50px;
  justify-content: center;
  display: flex;
  background-color: #fafafa;
  align-items: center;
  border: 1px solid #eaeaea;
}

.downloadSvg {
  position: relative;
  top: 3px;
}
.noStatusBuildName {
  letter-spacing: 0.29px;
  color: #000000;
  font-size: 10px;
}

.helpSupportBox {
  margin-bottom: 5px;
  padding: 4px 7px 5px 5px;
  border-radius: 4px;
  background-color: #ff6565;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.chatImage {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.supportText {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.copyIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  opacity: 1.2;
  margin-top: 16px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #bbbbbb;
}

.topBarButtons {
  justify-content: flex-end;
}

.copied {
  position: absolute;
  right: 109px;
  top: 31px;
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
}

.copied {
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
  position: absolute;
  right: 35px;
  top: 35px;
}

.copyImagePosition {
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #bbb;
  text-align: center;
  padding: 2px 5px;
  z-index: 99;
}

.screenshotIconsContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;
  gap: 10px;
}

.hypertestBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #0ebac5;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 128px;
}

.loadingHtBtn {
  color: #0ebac5;
}

.loadingHtBtn > span {
  display: flex;
  justify-content: space-between;
}

.actionButton {
  align-items: center;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.commandsLog:hover .bugBtn,
.commandsLog:hover .viewParametersBtn,
.commandsLog:focus-within .bugBtn,
.commandsLog:focus-within .viewParametersBtn {
  display: flex;
}

.bugBtn {
  display: none;
}

.viewParametersBtn {
  display: none;
}

.failedChip {
  background: rgba(255, 226, 219, 0.85);
  color: #fb6637;
}

.successChip {
  background: #2ab14f;
  color: #fff;
}

.successChip,
.failedChip,
.pendingChip {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  justify-content: center;
  padding: 2px 6px;
  width: 100%;
  word-break: break-all;
}

.pendingChip {
  background: #fafa23;
  color: #997f60;
}

.responseParameterContainer {
  font-size: 14px;
  height: auto;
  max-height: 245px;
  position: relative;
  margin-bottom: 50px;
}

.copyIcon {
  position: absolute;
  right: 15px;
  top: 45px;
}

.syntaxHighlighterClass {
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  margin-bottom: 35px;
  max-height: 220px;
  overflow: scroll;
  padding: 20px !important;
  word-wrap: initial !important;
  word-break: initial !important;
}

.statusIconContainer {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.statusSuccess {
  border: 1px solid #2ab14f;
}

.statusFailed {
  border: 1px solid #f45c47;
}

.commandLogsContainer {
  padding: 8px 16px;
  width: 100%;
}

.colDiv-1 {
  min-width: 5%;
  max-width: 5%;
}

.colDiv-2 {
  min-width: 12%;
  max-width: 12%;
}

.colDiv-3 {
  min-width: 15%;
  max-width: 15%;
}
.colDiv-4 {
  min-width: 23%;
  max-width: 23%;
}

.colDiv-5 {
  min-width: 27%;
  max-width: 27%;
}

.colDiv-55 {
  min-width: 30%;
  max-width: 30%;
}

.colDiv-6 {
  min-width: 44%;
  max-width: 44%;
}

.flex {
  align-items: center;
  display: flex;
  gap: 10px;
}

.popoverSectionHeading {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin-bottom: 8px;
}

.autoHealContainer {
  padding: 1px;
  display: flex;
}

.copyHealedParam {
  margin-top: 2px;
}

.usingSelector {
  border: 1px solid #ddd;
  border-radius: 100px;
  display: flex;
  padding: 1px 7px;
  align-items: flex-start;
  gap: 10px;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.originalSelectorSection {
  margin-top: 15px;
}

.commandLogsDurationContainer {
  min-width: 40px;
}

.healedValueContainer,
.actualValueContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.healedValueContainer {
  color: #176f2c;
}

.actualValueContainer {
  color: #cf370e;
}

.healedValue,
.actualValue {
  margin-left: 8px;
}

.popoverRow {
  display: flex;
  justify-content: space-between;
}

.healedSectionHeading {
  padding-top: 0;
}

.annotationText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noListStyle {
  list-style: none;
  padding: 0;
}

.commandLogHeader {
  align-items: center;
  border: 1px solid;
  border-top: none;
  border-bottom: 1px solid;
  display: flex;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    overflow: auto;
  }
}

.commandLogHeader:hover {
  background: rgba(221, 221, 221, 0.2);
}

.commandLogHeader:hover .collapseExpandCommandLog svg path {
  stroke: #333;
}

.commandLogHeaderFirst {
  border-top: none;
}

.commandLogHeaderError {
  border-left: 1.25px solid #cf222e !important;
  background-color: #fdf4f5;
}

.commandLogHeaderError:hover {
  background-color: #fff2f0;
}

.commandLogHeaderSuccess {
  border-left: 1.25px solid #176f2c !important;
  background-color: #f0f0f0;
}

.commandLogHeaderPending {
  border-left: 1px solid #bf8700 !important;
}

.commandLogTitleSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  padding: 10px 0 10px 16px;
  position: relative;
}

.commandLogTitle {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
}

.commandLogTitle h4 {
  font-size: 14px;
  margin-bottom: 0;
}

.commandLogDuration,
.commandLogTimestamp {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  gap: 4px;
  line-height: 18px;
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #888;
}

.commandLogSecondaryInfo {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: 24px;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.commandLogResponseStatus {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.commandLogTitleAction {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
}

.errorRemark,
.commandLogRemark,
.annotationBar {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  gap: 16px;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
}

.errorRemark {
  background: #fdf4f5;
  border-left: 1.25px solid #cf222e;
}

.annotationBar-warn,
.annotationBar-debug {
  border-top: 1px solid rgba(212, 167, 44, 0.4);
  border-bottom: 1px solid rgba(212, 167, 44, 0.4);
  background: #fff8c5;
}

.annotationBar-error {
  border-top: 1px solid rgba(255, 129, 130, 0.4);
  border-bottom: 1px solid rgba(255, 129, 130, 0.4);
  background-color: #ffebe9;
}

.annotationBar-info,
.commandLogRemark {
  border-top: 1px solid rgba(84, 174, 255, 0.4);
  border-bottom: 1px solid rgba(84, 174, 255, 0.4);
  background-color: #ddf4ff;
}

.cypressCodeBlockWrapper {
  border: 1px solid #ddd;
  border-bottom: none;
}

.cypressCodeBlockWrapper:last-child {
  border-bottom: 1px solid #ddd;
}

.commandLogHeaderError ~ .commandLogRemark,
.commandLogHeaderError ~ .cypressCodeBlockWrapper,
.commandLogHeaderError .errorRemark,
.commandLogHeaderError .annotationBar-info,
.commandLogHeaderError .annotationBar-error,
.commandLogHeaderError .annotationBar-debug {
  border-left: 1.25px solid rgb(207, 34, 46);
  border-right: 1px solid #ddd;
}

.commandLogHeaderSuccess ~ .commandLogRemark,
.commandLogHeaderSuccess ~ .cypressCodeBlockWrapper,
.commandLogHeaderSuccess .errorRemark,
.commandLogHeaderSuccess .annotationBar-info,
.commandLogHeaderSuccess .annotationBar-error,
.commandLogHeaderSuccess .annotationBar-debug {
  border-left: 1.25px solid #176f2c;
  border-right: 1px solid #ddd;
}

.commandLogHeaderPending ~ .cypressCodeBlockWrapper {
  border-left: 1px solid #bf8700;
  border-right: 1px solid #ddd;
}
.loader {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.flakyTestHeader {
  display: flex;
  gap: 4px;
  font-weight: 600;
  align-items: center;
}

.suiteTitle {
  align-items: center;
  align-self: stretch;
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 8px 16px;
}

.specExecutionTime {
  color: #666;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.actionWrapper {
  display: flex;
  gap: 16px;
}

.actionBar {
  display: flex;
}

.cypressCodeBlock {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 16px;
}

.cypressCodeBlockDark {
  background: rgb(40, 44, 52);
}

.cypressCodeBlockWrapper {
  padding: 10px 16px;
}

.commandLogActive {
  background-color: rgba(221, 221, 221, 0.4);
}

.commandLogActive.commandLogHeaderError {
  background-color: #ffebe9;
}

.collapseExpandCommandLog {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex: 0 1;
  gap: 8px;
  grid-gap: 8px;
  justify-content: center;
  padding: 10px 20px;
  width: 15%;
}

.collapseExpandCommandLog:focus {
  outline-color: #0969DA;
}

.cursorPointer {
  cursor: pointer;
}

.commandLogsPlaceholderBtn {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  height: 100% !important;
  position: absolute;
  width: 100%;
  left: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.commandLogsPlaceholderBtn:hover {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.commandLogsPlaceholderBtn:focus-visible {
  outline: 2px solid #0969DA;
}

.frameworkExceptionLogContainer {
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 16px;
  @media only screen and (max-width: 1024px) {
    overflow: auto;
  }
}

.copyButton {
  display: none;
}

.paramInfo:hover .copyButton {
  display: block;
}

.testItemWrapper {
  align-items: center;
  border-bottom: 0.8px solid #F1F3F8;
  color: inherit;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 15px;
  gap: 17px;
  padding: 13px;
  text-decoration: none;
}
.content {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 15px;
  text-align: left;
  text-decoration: none;
}

.browser {
  width: 40%;
  justify-content: left;
}

.os {
  width: 30%;
  justify-content: left;
}

.duration {
  width: 5%;
}
.resolution {
  width: 25%;
}

.testItemWrapper:hover {
  background: #fafafa;
  color: inherit;
  text-decoration: none;
}

.testItemWrapper div {
  align-items: center;
}

.testItemWrapper div img {
  height: 12px;
  margin-right: 5px;
  width: 12px;
}

.inspect {
  color: #0EBAC5;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.inspect:hover {
  color: #0EBAC5;
}

.testConfiguration {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flakeRate {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  align-items: center;
  background: #fff;
  color: #333;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  height: 100%;
  justify-content: center;
  line-height: 24px;
  padding: 16px;
  text-align: center;
  width: 100%;
}

.textContainer { 
  color: #333;
}


.textContainer h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
}

.textContainer h4 {
  color: #666;
}

.link a,
.link a:hover,
.link a:visited {
  font-size: 12px;
  color: #333;
}

.secondaryMessage {
  color:  #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
  margin-top: 2px;
  text-align: center;
}
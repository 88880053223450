.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tooltipContainer svg {
  margin: 2px;
}

.tooManyLogsWrapper {
  background: #FEFAE6;
  display: flex;
  gap: 5px;
  padding: 10px;
}

.log {
  border: 1px solid #EAEAEA;
  height: 32px;
  margin-right: 2px;
}

.log:hover {
  cursor: pointer;
}

.logRed {
  background-color: #FF650E;
}

.logGreen {
  background-color: #5FC8AF;
}
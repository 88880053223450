.Artifectretentionpolicymsg {
  display: block;
}

.messageContainer {
  border-radius: 1.2px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
  border: solid 4.9px #ffffff;
  background-color: #333;
  text-align: center;
  padding: 22px;
}
.messageHeading {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 20px;
  text-align: center;
}
.messageDescription {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.contactSupport {
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
  border: solid 1px #50e3c2;
  width: 130px;
  padding: 6px 16px;
  margin-top: 34px;
}
.contactSupport:hover {
  background: #50e3c2;
}
.contactSupportLink {
  text-decoration: none !important;
  color: #ffffff !important;
}

.anchorTagStyle {
  color: white !important;
  text-decoration: none !important;
}

.errorLabel {
  color: #101010;
  font-weight: 600;
  font-size: 16px;
  margin-top: 8px;
  line-height: 24px;
}

.errorSubHeader { 
  font-weight: 400;
  line-height: 20px;
  color: #666;
}

.errorWrapper {
  text-align: center;
}

.docsBtn {
  width: fit-content;
}

.docBtnWrapper {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.errorContainer {
  background-color: #fff;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: 100%;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  overflow: auto;
}
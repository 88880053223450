.Componenterror {
  display: block;
}
.wrapper {
  height: 100vh;
  background: url("../../images/bg_404.jpg") no-repeat center center fixed;
  background-repeat: no-repeat;
  /* background-size: 300vh 100vh; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  top: 30%;
  position: absolute;
  font-size: 200px;
  font-weight: 400;
  line-height: 0.82;
  letter-spacing: 0.2px;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  color: #0ebac5;
}

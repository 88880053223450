.maintenancePageContainer {
  text-align: center;
  margin-top: 100px;
}

.heading {
  color: #333;
  font-size: 22px;
  font-weight: 700;
}

.subHeading {
  color: #666;
  font-size: 12px;
  font-weight: 400;
}

.imageWrapper {
  display: flex;
  justify-content: center;
}

.additionalInfoWrapper {
  display: flex;
  margin: 50px 10px 30px 10px;
  justify-content: center;
}

.additionalInfoBlock {
  max-width: 250px;
}

.additionalInfoLabel {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  height: 44px;
}

.additionalInfoBlock {
  flex: 1;
  margin-right: 10px;
}

.additionalInfoLink {
  color: #3366ff;
  cursor: pointer;
  margin-top: 5px;

  a,
  a:hover,
  a:visited {
    color: #3366ff;
  }
}

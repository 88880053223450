.buildStatus {
  align-items: center;
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 3px;
  height: fit-content;
  line-height: 11px;
  margin: 0px 5px;
  padding: 5px;
  width: fit-content;
}

.selectedTestTag {
  background: #fff;
  border: 1px solid #2684ff;
  color: #2684ff;
}

.cursorPointer {
  cursor: pointer;
}

.boxGreen {
  background: #176f2c;
}
.boxCyanGreen {
  background: #037f78;
}
.boxRed {
  background: #cf222e;
}
.boxDarkOrange {
  background: #bc4c00;
}
.boxCyan {
  background-color: #087493;
}
.boxPink {
  background-color: #ffadad;
  color: #101010;
}
.boxOrange {
  background: #c24e00;
}
.boxYellow {
  background-color: #fae17d;
  color: #101010;
}

.boxDarkRed {
  background: #a40e26;
}

.boxDarkPink {
  background-color: #99306f;
}

.boxGrayLight {
  background: #f7f7f7;
  color: #333;
}

.boxGrayDark {
  background: #666;
}

.boxBlueLight {
  background: #0969da;
}

.boxBlueDark {
  background: #033d8b;
}

.boxPurple {
  background: #8250df;
}

.boxDarkPurple {
  background-color: #512a97;
}

.boxDarkYellow {
  background-color: #ffb302;
}

.boxFlaky {
  background: #a04100;
}

.tagIcon {
  margin-right: 4px;
}

.statuButton {
  align-items: center;
  display: flex;
  justify-content: center;
}

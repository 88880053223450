body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 768px) {
  .navbar-nav > li > a {
    padding-bottom: 10px;
  }
}

#uncontrolled-tab-example .nav-tabs {
  border-bottom: 1px solid #eaeaea !important;
}

#uncontrolled-tab-example li.active a,
#uncontrolled-tab-example li a:hover,
#uncontrolled-tab-example li a:active,
#uncontrolled-tab-example li a:focus {
  color: #000 !important;
}

#uncontrolled-tab-example-tab li.active a {
  font-weight: 500;
}

#uncontrolled-tab-example > ul li:last-child:hover a {
  color: #fff !important;
}

#infiniteScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#infiniteScroll::-webkit-scrollbar-track {
  background: #f4f5f9;
}

#infiniteScroll::-webkit-scrollbar-thumb {
  background: #dbdeec;
  border-radius: 10px;
}

#infiniteScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  padding: 0;
}

.lt-drawer .ant-drawer-header .ant-drawer-extra {
  width: 97%;
}

.lt-drawer .ant-drawer-close:focus {
  outline: 1px solid #333;
}

.lt-drawer.ant-drawer-open,
.ant-popover {
  z-index: 99999 !important;
}

.lt-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 44px;
}

.lt-tabs .ant-tabs-tab {
  font-size: 12px;
  padding: 12px;
}

.lt-tabs.ant-tabs .ant-tabs-nav {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-left: 23px;
  color: #333333;
  margin-bottom: 0px;
}

.lt-tabs-new .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.lt-tabs-new .ant-tabs-ink-bar {
  background: #333;
}

.lt-tabs-new .ant-tabs-tab:hover {
  color: #333;
}

.lt-tabs-new .ant-tabs-tab {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.lt-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
  font-weight: 500;
}

.lt-tabs .ant-tabs-ink-bar {
  background: #333;
}

.lt-tabs .ant-tabs-tab {
  color: #666;
}

.lt-tabs .ant-tabs-tab:hover {
  color: #333;
}

.lt-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 20px;
}

.lt-tabs.lt-tabs-compact .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 8px;
}

.lt-tabs.lt-tabs-compact.ant-tabs .ant-tabs-nav {
  padding-left: 0;
}

.lt-tabs.lt-tabs-hyex-btn-shown.ant-tabs .ant-tabs-nav {
  max-width: calc(100% - 155px);
}

.lt-tabs.lt-logs-tabs.ant-tabs .ant-tabs-nav {
  max-width: 100%;
}

.lt-logs-tabs .ant-tabs-ink-bar {
  display: none;
}

.lt-logs-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border: 1px solid #333;
}

.lt-logs-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 5px;
}

.lt-migrate-from-tabs .ant-tabs-ink-bar {
  display: none;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  align-items: center;
  border-radius: 2px;
  line-height: 14px;
  letter-spacing: 0.4px;
  padding: 10px 10px;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #000;
  background-color: #f4f3f3;
}

.lt-tabs.lt-migrate-from-tabs.ant-tabs .ant-tabs-nav {
  padding-left: 0px;
}

.lt-tabs.lt-migrate-from-tabs .ant-tabs-tab {
  padding-left: 0px;
  margin-left: 1px;
}

.lt-tabs.lt-migrate-from-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  background-color: #f4f3f3;
  color: #000;
}

.lt-breadcrumb-wrapper.ant-breadcrumb a:hover {
  color: #0ebac5;
}

.tooltipDiv {
  position: relative;
  display: inline-block;
}

.tooltipDiv .tooltiptext a {
  color: #43bac5;
}

.tooltipDiv .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: white;
  color: #666666;
  text-align: center;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 32px;
  right: -19px;
  margin-left: -60px;
  font-size: 12px;
  line-height: 22px;
  color: #666666;
  border: 1px solid #d2d2d2;
  box-shadow: 1px 2px 3px rgba(197, 197, 197, 0.25);
  border-radius: 5px;
}

.tooltipDiv .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 20px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #d2d2d2;
}

.tooltipDiv:hover .tooltiptext {
  visibility: visible;
}

.accessKeyDropDown .dropdown-menu {
  min-width: 320px !important;
}

.hcm-tooltip-base {
  background: #1b1e20 !important;
  color: #fff;
}

.harsh-coach-mark {
  border-radius: 4px !important;
}

.hcm-tooltip-base-left::before {
  border-color: #1b1e20 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
}

.lt-switch.ant-switch-checked {
  background-color: #4688f4;
}

.lt-drawer-accordion.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0px;
}

.lt-drawer-accordion.ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 10px;
  padding-right: 8px;
}

#lt-mask {
  background: #000;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  pointer-events: none;
  width: 100%;
  z-index: 2;
}

.lt-visible {
  visibility: visible;
}

.lt-hidden {
  visibility: hidden;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.ant-table-container .ant-table-tbody > tr > td,
.ant-table-container .ant-table-thead > tr > th {
  padding: 12px;
}

.ant-table-cell .shareBtn {
  visibility: hidden;
}

.ant-table-cell.ant-table-cell-row-hover .shareBtn {
  visibility: visible;
}

.ant-table-thead {
  background: #fafafa;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ant-table-thead > tr > th {
  background: #fff;
}

.ant-select-selection-item {
  color: #666;
  font-size: 10px;
}

.lt-scroll {
  overflow-y: scroll;
}

.testMetaDataModal .modal-dialog {
  width: 1100px;
}

.lambda__logo__img {
  max-width: unset;
}

.ant-switch.hide-error-switch-Icon {
  height: 12px;
  min-width: 6px;
  width: 25px;
}

.ant-switch.hide-error-switch-Icon.ant-switch-checked {
  background-color: #4abcc5;
}

.hide-error-switch-Icon .ant-switch-handle {
  height: 8px;
  width: 8px;
}

.hide-error-switch-Icon.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 10px);
}

.ltRunning {
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  border: 1.5px solid #409ff6;
  border-top: 1.5px solid #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton {
  background-color: #101010;
  color: #fff;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton:hover {
  border: none;
}

.ant-btn.ant-btn-default.ant-dropdown-trigger.downloadNetworkLogsButton.ant-dropdown-open {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.dot_windmill {
  -webkit-animation: dotWindmill 2s infinite linear;
          animation: dotWindmill 2s infinite linear;
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  position: relative;
  top: -10px;
  -webkit-transform-origin: 5px 15px;
          transform-origin: 5px 15px;
  width: 10px;
}

.dot_windmill::before,
.dot_windmill::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.dot_windmill::before {
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  left: -8.66px;
  top: 15px;
  width: 10px;
}

.dot_windmill::after {
  background-color: #43bac5;
  border-radius: 5px;
  color: #43bac5;
  height: 10px;
  left: 8.66px;
  top: 15px;
  width: 10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes dotWindmill {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
            transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(720deg) translate3d(0, 0, 0);
            transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

@keyframes dotWindmill {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
            transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(720deg) translate3d(0, 0, 0);
            transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

.TopRightOptionsPopoverHelp.ant-popover-placement-bottom .ant-popover-arrow {
  left: 90%;
}

.TopRightOptionsPopoverAccessKey.ant-popover-placement-bottom
  .ant-popover-arrow {
  left: 70%;
}

.lt-collapse-pane .ant-collapse-content {
  background-color: transparent;
  border-top: none;
}

.lt-collapse-pane .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.lt-collapse-pane.ant-collapse {
  background-color: transparent;
  border: none;
  font-size: 12px;
  word-break: break-word;
}

.lt-collapse-pane .ant-collapse-header {
  padding: 0;
}

.lt-collapse-pane .ant-collapse-item {
  border: none !important;
}

.lt-collapse-pane.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 4px 40px 4px 16px;
}

.networkLogsColumnHeader {
  font-size: 12px;
}

.lt-sharing-modal-wrapper.ant-modal-wrap,
.lt-sharing-select-dropdown.ant-select-dropdown {
  z-index: 99999;
}

.lt-delete-modal-wrapper.ant-modal-wrap {
  z-index: 99999 !important;
}

.lt-build-tabs .ant-tabs-tab-btn:focus,
.lt-build-tabs .ant-tabs-tabpane-active:focus,
.lt-tabs-compact .ant-tabs-tab-btn:focus,
.lt-tabs-compact .ant-tabs-tabpane-active:focus,
.lt-logs-tabs .ant-tabs-tab-btn:focus,
.lt-logs-tabs .ant-tabs-tabpane-active:focus,
.lt-tabs-new .ant-tabs-tab-btn:focus,
.lt-tabs-new .ant-tabs-tabpane-active:focus,
.lt-migrate-from-tabs .ant-tabs-tab-btn:focus {
  outline: 1px solid #333;
}

.ant-modal-close:focus {
  outline: 1px solid #101010;
}

#commandLogRemark
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header:focus {
  outline: 1px solid #101010;
}

.lt-menu-dropdown .ant-dropdown-menu-item.ant-dropdown-menu-item-active:focus {
  outline: 1px solid #333 !important;
}

/* Primer CSS */
#__primerPortalRoot__ > div {
  z-index: 9999999999999999 !important;
}

.flex {
  display: flex;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.PRC-selected {
  border-bottom-color: #ed5f00 !important;
}

.PRC-UnderlineNav {
  border-bottom: none !important;
}

.PRC-UnderlineNav-item {
  display: flex;
  align-items: center;
  margin-right: 8px !important;
}

textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  font-size: 14px;
}

textarea::placeholder,
input::placeholder {
  font-size: 14px;
}

.react-lazylog .ReactVirtualized__Grid__innerScrollContainer div:hover {
  background-color: rgba(221, 221, 221, 0.2);
}

.react-lazylog.focus-visible {
  outline: 2px solid #0969da;
}

.textAreaWrapper > span:focus-within {
  border-width: 2px;
  box-shadow: none;
}

.lt-healed-popover .ant-popover-content,
.lt-flaky-popover .ant-popover-content {
  border-radius: 6px;
}

.lt-healed-popover .ant-popover-inner,
.lt-flaky-popover .ant-popover-inner,
.lt-build-details-popover .ant-popover-inner {
  border: 1px solid #ddd;
  border-radius: 6px;
}

.lt-build-details-popover .ant-popover-inner-content {
  padding: 0px;
  overflow: hidden;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hiddenFromView {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ltCommandLogParamsContainer .string-value {
  word-break: break-all;
}
.filterModalCheckboxGroup {
  max-height: 200px;
  margin: 0px -16px -16px -16px;
  overflow: scroll;
  padding: 0px 16px 16px 16px;
}

.Component404_Componenterror__1Yb04 {
  display: block;
}
.Component404_wrapper__2m4fL {
  height: 100vh;
  background: url(/static/media/bg_404.7fe2d282.jpg) no-repeat center center fixed;
  background-repeat: no-repeat;
  /* background-size: 300vh 100vh; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Component404_error__3Y-a8 {
  top: 30%;
  position: absolute;
  font-size: 200px;
  font-weight: 400;
  line-height: 0.82;
  letter-spacing: 0.2px;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  color: #0ebac5;
}

.Component500_Componenterror__1Inw8 {
  display: block;
}
.Component500_wrapper__27lrN {
  height: 77vh;
  background: #fff;
  background-repeat: no-repeat;
  /* background-size: 300vh 100vh; */
  background-size: cover;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Component500_error__yCPg2 {
  display: flex;
  justify-content: center;
}

.Component500_description__12XBy {
  width: 597px;
  font-family: Loto;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: left;
  color: #000000;
  margin-top: 30px;
}

.ErrorBoundary_errorBoundaryWrapper__3DX74 {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ErrorBoundary_reloadButton__3F00u {
  align-items: center;
  background: #101010;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  margin-top: 20px;
  padding: 8px 16px;
}

.ComponentTraceLoader_loaderDiv__3mIZo {
  /* width: 100%;
  height: 100%;
  position: absolute; */
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* align horizontal */
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9;
  padding: 20px;
}

.ComponentTraceLoader_loaderDiv__3mIZo .ComponentTraceLoader_displayText__MfjaF {
  margin-top: 5px;
  font-size: 12px;
}
.ComponentTraceLoader_buildViewLogo__v3IKA {
  height: 40px;
  width: 40px;
  margin-bottom: 7px;
  border-radius: 40px;
}
@-webkit-keyframes ComponentTraceLoader_placeHolderShimmer__2ehZ- {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes ComponentTraceLoader_placeHolderShimmer__2ehZ- {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.ComponentTraceLoader_traceParent__3Y38L {
  width: 100%;
}
.ComponentTraceLoader_baseStyle__2CkMy {
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: ComponentTraceLoader_placeHolderShimmer__2ehZ-;
          animation-name: ComponentTraceLoader_placeHolderShimmer__2ehZ-;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 72px;
  height: 40px;
  position: relative;
  border-left: 0px;
  border-right: 0px;
}
.ComponentTraceLoader_buildViewDate__29izc {
  height: 26px;
  margin-bottom: 7px;
}

.ComponentTraceLoader_buildViewTime__htxdc {
  height: 12px;
  width: 114px;
}
.ComponentTraceLoader_buildViewName__2rpZL {
  height: 20px;
  margin-top: 9px;
}
.ComponentTraceLoader_buildViewSummary__2ti0P {
  height: 20px;
  margin-top: 9px;
}
.ComponentTraceLoader_buildViewStatus__1L1Rj {
  height: 20px;
  margin-top: 9px;
}
.ComponentTraceLoader_buildNavBar__1nEeK {
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
}
.ComponentTraceLoader_buildViewNameNavBar__m0Te9 {
  height: 12px;
  width: 200px;
  display: inline-block;
}
.ComponentTraceLoader_testInfiniteWrapper__3uGS0 {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  border-right: 1px solid #eaeaea;
}
.ComponentTraceLoader_testInfinitSellectAll__1l3xp {
  height: 50px;
  padding: 15px;
  border: 1px solid #eaeaea;
}
.ComponentTraceLoader_testViewSelectall__vX0t6 {
  height: 20px;
}
.ComponentTraceLoader_col_xs_4_custom___A2-n {
  width: 31.3333% !important;
}
.ComponentTraceLoader_col_xs_4_custom_2__15JOU {
  width: 35.3333% !important;
}
.ComponentTraceLoader_testCheckBox__tY3UU {
  width: 15px;
  height: 15px;
}
.ComponentTraceLoader_testStatus__2afuV {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: 20px;
  float: right;
}
.ComponentTraceLoader_testName___fSgW {
  height: 25px;
  margin-bottom: 10px;
}
.ComponentTraceLoader_testSummary__1r_mF {
  height: 20px;
  width: 50%;
}
.ComponentTraceLoader_testVideo__36l9F {
  height: 270px;
  margin-left: 20px;
  width: 370px;
}
.ComponentTraceLoader_testDurationSummary__1vMNn {
  height: 20px;
  margin-top: 14px;
  margin-left: 20px;
  margin-bottom: 15px;
}
.ComponentTraceLoader_timeline_item__bi_R3 {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  /* padding: 12px; */
  margin: 0 auto;
  max-width: 100%;
  min-height: 100px;
  border-right: 0px;
  border-left: 0px;
  display: flex;
  align-items: center;
}
.ComponentTraceLoader_buildname__2DsHv {
  width: 250px;
  margin-left: 15px;
}
.ComponentTraceLoader_testView_item__1ep-I {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  padding: 12px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 55px;
  border-right: 0px;
  border-left: 0px;
}
.ComponentTraceLoader_commandlog__1Md-h {
  background: #fff;
  border: 0.5px solid;
  border-color: #eaeaea;
  padding: 12px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 40px;
  border-right: 0px;
  border-left: 0px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.ComponentTraceLoader_groupedTest__1rpr3 {
  background: #f6f8fa;
  border-bottom: 0.5px solid #eaeaea;
  padding: 4px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 37px;
}

.ComponentTraceLoader_sidebarGroupedTest__3_MXP {
  padding: 4px;
  margin: 0 auto;
  max-width: 100%;
  min-height: 16px;
}

.ComponentTraceLoader_testViewDate__2x3YL {
  height: 5px;
  margin-top: 12px;
}

.ComponentTraceLoader_testViewName__1SFdb {
  height: 5px;
  margin-top: 12px;
}

.ComponentTraceLoader_testViewSummary__1uVzg {
  height: 5px;
  margin-top: 12px;
}
.ComponentTraceLoader_testViewStatus__2nP0r {
  height: 20px;
  width: 20px;
  border-radius: 360px;
  margin-top: 4px;
}

.ComponentTraceLoader_analyticsCounter_item__1MMim {
  background: #fff;
  border: 0px;
  border-color: #eaeaea;
  /* border-radius: 3px; */
  /* padding: 12px; */
  margin: 0 auto;
  max-width: 100%;
  min-height: 55px;
}

.ComponentTraceLoader_analyticsViewName__2wHpS {
  height: 65px;
  border-radius: 5px;
}

.ComponentTraceLoader_testDetailsHeading__1qMB7 {
  height: 15px;
}
.ComponentTraceLoader_testDetailsLocalParent__2hv0G {
  padding: 20px;
}

.ComponentTraceLoader_testDetailsChild__110bf {
  height: 7px;
}
.ComponentTraceLoader_testDetailsVideoPlaceHolder__124_l {
  height: 463px;
}
.ComponentTraceLoader_testDetailsChildParent__1KNjI {
  padding: 5px;
}
.ComponentTraceLoader_testDetailsVideoParent__2aJjD {
  padding-top: 15px;
}
.ComponentTraceLoader_marginClass__3nC8p {
  margin-top: 6px;
}

.ComponentTraceLoader_timeline_tabs__1-tsM {
  height: 45px;
  background: #fff;
  width: 16%;
  display: inline-block;
  padding: 10px;
}

.ComponentTraceLoader_textStyle__5pc5W {
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 0;
  color: #4a4a4a;
  letter-spacing: 2.2px;
  font-size: 18px;
}

.ComponentTraceLoader_filterBarTrace__2hMl9 {
  min-height: 75px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ComponentTraceLoader_filterBarElem__10nOK {
  height: 17px;
  /* margin-bottom: 0px; */
  margin-top: 5px;
  width: 89px;
}
.ComponentTraceLoader_filterBarLabel__3BQhM {
  height: 17px;
  /* margin-bottom: 0px; */
  margin-top: 5px;
  width: 125px;
}
.ComponentTraceLoader_videoColumn__1n2Ge {
  margin-left: 80px;
}
.ComponentTraceLoader_testDetailsHeading__1qMB7 {
  height: 14px;
}
.ComponentTraceLoader_testDetailsSummary__3sBdz {
  height: 12px;
}
.ComponentTraceLoader_debuggerTab__1ghyw {
  height: 40px;
}

.ComponentTraceLoader_search__Px8bo {
  background: #fff;
  border-bottom: 1px solid #e1e9ec;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  min-height: 40px;
  padding: 5px 0;
}

.ComponentTraceLoader_search__Px8bo:last-child {
  border-bottom: none;
}

.ComponentTraceLoader_searchStatus__17rwX {
  height: 5px;
  width: 80px;
}

.ComponentTraceLoader_searchName__12Z4z {
  width: 200px;
}

.ComponentTraceLoader_searchDate__2ygaJ {
  width: 100px;
}

.ComponentTraceLoader_searchName__12Z4z,
.ComponentTraceLoader_searchDate__2ygaJ {
  height: 5px;
}

.ComponentTraceLoader_tableColumn__1C9ia {
  height: 10px;
  margin-top: 12px;
}

.ComponentTraceLoader_traceCol__2EPw- {
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  overflow: hidden;
}

.ComponentTraceLoader_traceCol-1__2pL1_ {
  width: 8%;
}

.ComponentTraceLoader_traceCol-2__XMvvW {
  width: 16.66%;
}

.ComponentTraceLoader_traceCol-3__AKNTM {
  width: 25%;
}

.ComponentTraceLoader_traceCol-4__1Zy3k {
  width: 33.33%;
}

.ComponentTraceLoader_traceCol-5__SE8dj {
  width: 41.66666667%;
}

.ComponentTraceLoader_traceCol-6__spLmx {
  width: 50%;
}

.ComponentTraceLoader_traceCol-7__1S8Au {
  width: 58.3333333%;
}

.ComponentTraceLoader_traceCol-8__2bqhE {
  width: 66.66666667%;
}

.AutomationLogsPage_mainDiv__3c1uv {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  height: 100%;
  margin: 0px;
}

.AutomationLogsPage_bannerPadding__17qBT.AutomationLogsPage_mainDiv__3c1uv {
  padding-top: 93px;
}

.AutomationLogsPage_automationLogsPageContainer__1gkF0 {
  font-size: 12px;
  height: 100%;
}

.AutomationLogsPage_wordBreak__IbLkh {
  word-break: break-all;
}

.AutomationLogsPage_metaInfoModalColumn__1AU0O {
  color: #4a4a4a;
  font-weight: 500;
  line-height: 2;
  padding: 2px 0 2px 2px;
  width: 15vw;
}

.AutomationLogsPage_metaInfoModalValue__31Zot {
  color: #4a4a4a;
  line-height: 2;
  padding-left: 30px;
  padding-right: 15px;
  padding: 2px;
  position: relative;
  width: 35vw;
}

.AutomationLogsPage_metaInfoWrapper__3DZhV {
  margin: 5px 28px;
}

.AutomationLogsPage_metaInfoHeader__2PL0W {
  color: #000000;
  font-size: 16px;
  font-stretch: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.AutomationLogsPage_metaInfoHeader2__1ZZxZ {
  color: #000000;
  font-size: 16px;
  font-stretch: normal;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.AutomationLogsPage_flexRow__2sk0G {
  display: flex;
  flex-direction: row;
}

.AutomationLogsPage_topBarButtons__3V3EP {
  justify-content: flex-end;
}

.AutomationLogsPage_helpSupportBox__FJVPE {
  align-items: center;
  background-color: #ff6565;
  border-radius: 4px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 4px 7px 5px 5px;
}

.AutomationLogsPage_chatImage__1yFmi {
  height: 14px;
  margin-right: 6px;
  width: 14px;
}

.AutomationLogsPage_supportText__TpLki {
  color: #ffffff;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
}

.AutomationLogsPage_modalBoxInnerContent__1nQ6t {
  justify-content: space-between;
}

.AutomationLogsPage_customData__PQpmY {
  justify-content: space-between;
}

.AutomationLogsPage_copyIconWrapper__o9ScU {
  position: absolute;
  right: 25px;
  top: 5px;
  z-index: 999;
}

.AutomationLogsPage_metaDataTableInputConfig__1eB3X {
  font-size: 12px;
  max-height: 200px;
  min-height: 50px;
  min-width: 500px;
  overflow: auto;
  position: relative;
}

.AutomationLogsPage_customError__1bjPQ {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 0.82;
  position: absolute;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  top: 40%;
}

.AutomationLogsPage_testLoader__OmCIS {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.AutomationLogsPage_fullHeight__uyRA- {
  height: 100%;
}

.automationLogsPage .accordion > .card > .card-header {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.automationLogsPage .card-header:first-child {
  border-radius: 0 !important;
}
.automationLogsPage .card-header {
  padding: unset;
  margin-bottom: 0;
  background-color: unset;
  border-bottom: unset;
}
.automationLogsPage .card {
  border: unset;
  border-bottom: unset !important;
}

.automationLogsPage .collapse {
  box-sizing: unset !important;
}
.automationLogsPage .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0ebac5 !important;
}
.automationLogsPage .nav-link {
  padding: 10px !important;
}
.automationLogsPage .automationLogsPage .nav-tabs .nav-link {
  color: #4a4a4a;
  border: unset !important;
}
.automationLogsPage .table thead th {
  border-bottom: unset !important;
}
.automationLogsPage .lh-topbar {
  z-index: 5 !important;
}

.automationLogsPage .lh-topbar__url {
  position: absolute;
  left: 25px;
  width: 93%;
}

.automationLogsPage .lh-sticky-header--visible {
  position: absolute !important;
  width: 700px;
  top: unset !important;
  left: unset !important;
  right: unset !important;
}

.automationLogsPage .nav-tabs li.active {
  border-bottom: 2px solid #0ebac5 !important;
}
.automationLogsPage .nav-tabs li a {
  color: #4a4a4a;
  border: unset !important;
  line-height: 1.31;
  padding: 15px 10px;
}
.automationLogsPage .activeNested.nav-tabs li.active {
  border-bottom: unset !important;
  background-color: #0ebac5;
  color: #fff;
}
.automationLogsPage .panel-default > .panel-heading {
  color: #3c4144;
  background-color: unset !important;
  border-color: unset !important;
  font-size: 14px !important;
  padding: 0px !important;
}
.automationLogsPage .panel-default {
  border-color: #fff !important;
}
.automationLogsPage .panel {
  box-shadow: unset !important;
}
.automationLogsPage .panel-title a {
  text-decoration: none !important;
}
.automationLogsPage
  .panel-group
  .panel-heading
  + .panel-collapse
  > .panel-body {
  border: unset !important;
}


.sideNavCollapsed .testDetailLeftPane {
  width: 60%;
}

.sideNavCollapsed .testDetailRightPane {
  width: 40%;
}

.ErrorScreen_errorLabel__2oKBB {
  color: #101010;
  font-weight: 600;
  font-size: 16px;
  margin-top: 8px;
  line-height: 24px;
}

.ErrorScreen_errorSubHeader__3R7U8 { 
  font-weight: 400;
  line-height: 20px;
  color: #666;
}

.ErrorScreen_errorWrapper__1u_W6 {
  text-align: center;
}

.ErrorScreen_docsBtn__2GSnE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ErrorScreen_docBtnWrapper__152vJ {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.ErrorScreen_errorContainer__-tXHW {
  background-color: #fff;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  height: 100%;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  overflow: auto;
}
.CopyCode_codeWrapper__13OUd {
  background: rgba(246, 248, 252, 0.85);
  color: #333;
  display: flex;
  font-family: "Space Mono", sans-serif;
  margin: 20px 0;
  padding: 15px 30px;
}

.CopyCode_codeWrapperCopied__1Uk6h .CopyCode_codeWrapperText__3xP6- {
  display: flex;
}

.CopyCode_codeWrapperCopied__1Uk6h .CopyCode_codeWrapperCode__2I6WI {
  display: none;
}

.CopyCode_codeWrapperText__3xP6- {
  display: none;
  flex: 1 1;
  justify-content: center;
}

.CopyCode_codeWrapperCode__2I6WI {
  display: flex;
  flex: 1 1;
}

.CopyCode_capabilityCode__1fJ0d {
  flex: 1 1;
}

.ComponentNoDataFound_message__2XRXO {
  align-items: center;
  background: #fff;
  color: #333;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  height: 100%;
  justify-content: center;
  line-height: 24px;
  padding: 16px;
  text-align: center;
  width: 100%;
}

.ComponentNoDataFound_textContainer__1l01U { 
  color: #333;
}


.ComponentNoDataFound_textContainer__1l01U h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2px;
}

.ComponentNoDataFound_textContainer__1l01U h4 {
  color: #666;
}

.ComponentNoDataFound_link__10MNT a,
.ComponentNoDataFound_link__10MNT a:hover,
.ComponentNoDataFound_link__10MNT a:visited {
  font-size: 12px;
  color: #333;
}

.ComponentNoDataFound_secondaryMessage__2uu3X {
  color:  #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
  margin-top: 2px;
  text-align: center;
}
.CapabilityScreen_missingCapabilityLabel__MKk7R {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.CapabilityScreen_missingCapabilityWrapper__1_Pjj {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.CapabilityScreen_flex__XeUno {
  display: flex;
  justify-content: center;
}

.CapabilityScreen_missingCapabilityContainer__rgaS1 {
  background-color: #fff;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  overflow: auto;
}


.CapabilityScreen_docsBtn__1c5C2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.CapabilityScreen_docBtnWrapper__3v91C {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.CapabilityScreen_capabilityCodeText__5yrW_ {
  font-weight: 600;
}

.CapabilityScreen_capabilityDesc__1AGmj {
  color: #666;
}
.automationLogsPage .accordion > .card > .card-header {
  border-radius: 0 !important;
  margin-bottom: 0px !important;
}
.automationLogsPage .card-header:first-child {
  border-radius: 0 !important;
}
.automationLogsPage .card-header {
  padding: unset;
  margin-bottom: 0;
  background-color: unset;
  border-bottom: unset;
}
.automationLogsPage .card {
  border: unset;
  border-bottom: unset !important;
}

.automationLogsPage .collapse {
  box-sizing: unset !important;
}

.automationLogsPage .nav-link {
  padding: 10px !important;
}

.automationLogsPage .table thead th {
  border-bottom: unset !important;
}
.automationLogsPage .lh-topbar {
  z-index: 5 !important;
}
.performanceDrawer .lh-sticky-header--visible {
  position: absolute !important;
  width: 1000px;
  top: unset !important;
  left: unset !important;
  right: unset !important;
}
#controlled-tab-example-network .nav-tabs {
  border: unset !important;
}

.automationLogsPage .panel-default > .panel-heading {
  color: #3c4144;
  background-color: unset !important;
  border-color: unset !important;
  font-size: 14px !important;
  padding: 0px !important;
}
.automationLogsPage .panel-default {
  border-color: #fff !important;
}
.automationLogsPage .panel {
  box-shadow: unset !important;
}
.automationLogsPage .panel-title a {
  text-decoration: none !important;
}
.automationLogsPage
  .panel-group
  .panel-heading
  + .panel-collapse
  > .panel-body {
  border: unset !important;
}

#code_wrapper div > pre {
  min-height: 700px !important;
  padding: 20px !important;
  padding-left: 10px !important;
  margin-top: 10px;
}

#command_wrapper div:last-child > div {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  -webkit-animation: dotFlashing 1s infinite linear alternate;
          animation: dotFlashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  -webkit-animation: dotFlashing 1s infinite alternate;
          animation: dotFlashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #43bac5;
  color: #43bac5;
  -webkit-animation: dotFlashing 1s infinite alternate;
          animation: dotFlashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes dotFlashing {
  0% {
    background-color: #43bac5;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #43bac5;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.testNetworkLogs .activeNested.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0ebac5 !important;

  background-color: white;
  color: #fff;
}
.testNetworkLogs .activeNested.nav-tabs .nav-link {
  padding: 5px !important;
  margin: 15px 5px;
  border: 2px solid #0ebac5 !important;
  background-color: white;
}
.testNetworkLogs .activeNested.nav-tabs li.active {
  background-color: white;
  border: 2px solid #0ebac5 !important;
  color: #fff !important;
  background-color: white;
}
.testNetworkLogs .nav-tabs li a {
  color: #4a4a4a;
  border: unset !important;
  line-height: 1.31;
  letter-spacing: 0.48px;
  padding: 5px;
  margin-right: 20px;
  background-color: white;
}
.testNetworkLogs .nav-tabs li.active {
  border: unset !important;
  background-color: white;
}
.testNetworkLogs .automationLogsPage .nav-tabs .nav-link {
  color: #4a4a4a;
  border: unset !important;
  background-color: white;
}
.testNetworkLogs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: white;
}
/* Active Li Style For HAR Tab(Nested Filter Tab) */
.activeNested > ul > li.active a {
  background-color: white !important;
  color: #fff !important;
  border-radius: 10px;
  border: 0px;
  border: 1px solid #0ebac5 !important;
}
#uncontrolled-tab-example-tab li.active a,
#uncontrolled-tab-example-tab li a:hover,
#uncontrolled-tab-example-tab li a:active,
#uncontrolled-tab-example-tab li a:focus {
  background-color: transparent !important;
  color: #0ebac5 !important;
}

.inputConfig .string-value {
  word-break: break-all;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

[data-type="Resizer"] {
  opacity: 0 !important;
  border: none !important;
  width: 1.5px !important;
  background: #d4d4d4 !important;
  position: relative;
  right: 8px;
}

[data-type="Resizer"]:hover {
  opacity: 100 !important;
  border: none !important;
  width: 1.5px !important;
  background: #d4d4d4 !important;
}

@media only screen and (max-width: 1024px) {
  [data-type="SplitPane"] {
    flex-direction: column !important;
    grid-gap: 8px !important;
    gap: 8px !important;
  }
}

.TestDetailLogs_buildname__13i9p {
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.24px;
  color: #47c5d8;
  font-size: 12px;
  padding: 16px;
}

.TestDetailLogs_metaDataTable__ZqOH4 {
  overflow: auto;
  font-size: 12px;
}
.TestDetailLogs_metaDataColumn__3CTM3 {
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  padding: 5px;
}
.TestDetailLogs_metaDataValue__3e9cJ {
  letter-spacing: 0.3px;
  color: #3c4144;
}

.TestDetailLogs_attachmentWrapper__2Xaji {
  display: flex;
  font-size: 12px;
  grid-gap: 10px;
  gap: 10px;
}
.TestDetailLogs_attachmentName__2B2Fz {
  color: #3c4144;
  display: block;
  letter-spacing: 0.3px;
}

.TestDetailLogs_action_btn__kiBTs {
  border: 1px solid #979797;
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}

.TestDetailLogs_action_btn__kiBTs:hover {
  border: 1px solid #0ebac5;
}

.TestDetailLogs_actiondiv__3ek6Q {
  text-align: center;
}

.TestDetailLogs_attachmentRow__11Mtt {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  gap: 5px;
  justify-content: space-between;
  max-width: 100%;
  padding: 10px;
}

.TestDetailLogs_attachmentRow__11Mtt:hover {
  background-color: #f8fcff;
}

.TestDetailLogs_logsTabSection__16le8 {
  margin-top: 16px;
}

.TestDetailLogs_searchLog__15y_p {
  height: 28px;
  border-radius: 4px;
  padding-left: 30px;
  /* padding-right: 20px; */
  font-size: 12px;
  color: #61605c !important;
  border: solid 1px #eaeaea !important;
  box-shadow: none !important;
}

.TestDetailLogs_searchLogParent__NHnW6 {
  position: relative;
  width: 35%;
}

.TestDetailLogs_searchLogIcon__1bb3m {
  background: url(/static/media/search.e4c66dbc.svg);
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 12px 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}

.TestDetailLogs_filterlogs__eis7X {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px;
}

.TestDetailLogs_checkboxWrapper__3iEat {
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-right: 15px;
}

.TestDetailLogs_checkboxDisabled__1tyTN {
  pointer-events: none;
  opacity: 0.5;
}

.TestDetailLogs_checkboxContainer__3O5rv {
  display: flex;
  align-items: center;
}

.TestDetailLogs_checkboxContainerLabel__Pu7Rq {
  font-size: 12px;
  line-height: 1.6;
  margin: 0px 10px 0px 5px;
}

.TestDetailLogs_checkboxContainer__3O5rv input[type="checkbox"] {
  position: relative;
  width: 14px;
  height: 14px;
  color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.TestDetailLogs_checkboxContainer__3O5rv input[type="checkbox"]:before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 3px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}

.TestDetailLogs_checkboxContainer__3O5rv input[type="checkbox"]:checked {
  color: #fff;
  border-color: #eaeaea;
  background: #101010;
}

.TestDetailLogs_checkboxContainer__3O5rv input[type="checkbox"]:checked:before {
  opacity: 1;
}

.TestDetailLogs_checkboxContainer__3O5rv input[type="checkbox"]:checked ~ label:before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.TestDetailLogs_commandsLogWrapper__U5t7g {
  font-size: 12px;
  margin-left: 5px;
}

.TestDetailLogs_rawLogsTab__2gG-2 {
  margin: 10px 5px 0px 0px;
}

.TestDetailLogs_download_report__32AKe {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
  width: 100%;
}

.TestDetailLogs_download_report__32AKe span {
  background-color: #f1f2f4;
  border-radius: 4px;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 8px 10px;
}

.TestDetailLogs_performance__3JsAY {
  overflow: auto;
}

.TestDetailLogs_copyIconWrapper__3T3r_ {
  position: absolute;
  right: 25px;
  top: 20px;
  z-index: 999;
}

.TestDetailLogs_hypertestBtn__1Ydap {
  border-radius: 4px;
  border: 0.8px solid #0ebac5;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 4px;
}

.TestDetailLogs_hyperExecuteText__2TCmX {
  color: #999;
  font-size: 10px;
  margin-left: 6px;
}

.TestDetailLogs_loadingHtBtn__2EqG0 {
  color: #0ebac5;
}

.TestDetailLogs_loadingHtBtn__2EqG0 > span {
  display: flex;
  justify-content: space-between;
}

.TestDetailLogs_remarkDrawer__WC7Jt {
  overflow: hidden;
  position: relative;
}

.TestDetailLogs_headerWrapper__2nKNL {
  display: flex;
  padding: 17px 23px;
}

.TestDetailLogs_headerWrapper__2nKNL > img {
  cursor: pointer;
  margin-left: auto;
}

.TestDetailLogs_headerTitle__1t_z9 > h4 {
  margin-bottom: 0px;
  margin-right: 6px;
}

.TestDetailLogs_headerTitle__1t_z9 {
  color: #999999;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 6px;
}

.TestDetailLogs_copyIcon__2mLxz {
  position: absolute;
  right: 10px;
  top: 70px;
}

.TestDetailLogs_testDetailTabContent__2KjCP {
  display: block;
  overflow: auto;
  padding: 10px;
}

.TestDetailLogs_testDetailTabContent__2KjCP > tbody {
  display: flex;
  flex-direction: column;
}

.TestDetailLogs_testDetailTabContent__2KjCP > tbody > tr {
  display: flex;
  margin-bottom: 15px;
}

.TestDetailLogs_testDetailTabContent__2KjCP.TestDetailLogs_bannerPadding__1W2Yd {
  max-height: calc(23vh - 42px);
}

.TestDetailLogs_metaDataColumn__3CTM3 {
  color: #666;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  width: 30%;
}

.TestDetailLogs_metaDataValue__3e9cJ {
  color: #3c4144;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 70%;
}

.TestDetailLogs_startHeading__3N_Qe {
  color: #666;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 15px;
  width: 50px;
}

.TestDetailLogs_metaDataDate__Y6XM4 {
  display: flex;
}

.TestDetailLogs_performanceReportName__1wvlx {
  border-bottom: 0.8px solid #f1f3f8;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 10px;
}

.TestDetailLogs_performanceReportView__3yLpI {
  font-size: 12px;
}

.TestDetailLogs_performanceReportName__1wvlx:hover {
  background-color: #f1f4f9;
}

.TestDetailLogs_performanceReportDrawer__1KU25 {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.TestDetailLogs_checkboxContainer__3O5rv:focus-within {
  outline: 1px solid #0969da;
}

.TestDetailLogs_customTabPane__3RVpK:focus {
  outline: 1px solid #0969da;
}

.TestDetailLogs_testContainer__37mZ7 {
  width: 100%;
  height: 100%;
}

.TestDetailLogs_allCommandsWrapper___xNnX {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.TestDetailLogs_commandLogWrapper__1VJIU {
  width: 50%;
}
.TestDetailLogs_commandLogWrapperPrimer__2JnKP {
  width: 100%;
  height: 100%;
}

.TestDetailLogs_testDetailInfoWrapper__1aX44 {
  height: 100%;
  /* width: 50%; */
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.TestDetailLogs_rightPane__3s7cA {
  width: 50%;
}

.TestDetailLogs_annotationWrapper__3NeQJ {
  margin-left: 8px;
}

@media only screen and (max-width: 320px) {

  .TestDetailLogs_annotationWrapper__3NeQJ {
    display: none;
  }
}

.TestDetailLogs_cypressSpec__2hDiY td {
  padding: 8px;
}

.TestDetailLogs_cypressSpec__2hDiY th {
  position: relative;
  padding: 8px;
}

.TestDetailLogs_cypressSpec__2hDiY th,
.TestDetailLogs_cypressSpec__2hDiY td {
  text-align: left;
}

.TestDetailLogs_cypressSpecTable__2zquf {
  padding: 16px 16px 8px 16px;
}

.TestDetailLogs_navContainer__1xqH0 {
  overflow: auto !important;
}

@media only screen and (max-width: 1024px) {
  .TestDetailLogs_allCommandsWrapper___xNnX {
    flex-direction: column;
    height: unset;
  }
  .TestDetailLogs_commandLogWrapper__1VJIU {
    width: 100%;
  }
  .TestDetailLogs_testDetailInfoWrapper__1aX44 {
    aspect-ratio: 2;
    width: 100%;
    height: unset;
    width: 100% !important;
  }
}

/* Theme Colours */

/* Color Indicators */

/* Font Color */

/* Panel Color */

/* Color */

/* Status Color */
.LiveStream_font8__2AkuN {
  font-size: 8px;
}
.LiveStream_font9__2i3Pe {
  font-size: 9px;
}
.LiveStream_font10__3j6HE {
  font-size: 10px;
}
.LiveStream_font11__26QWk {
  font-size: 11px;
}
.LiveStream_font12__359Mb {
  font-size: 12px;
}
.LiveStream_font13__2AV8u {
  font-size: 13px;
}
.LiveStream_font14__3l5gt {
  font-size: 14px;
}
.LiveStream_font15__1Qmfo {
  font-size: 15px;
}
.LiveStream_font16__1rcHm {
  font-size: 16px;
}
.LiveStream_font17__CHRdz {
  font-size: 17px;
}
.LiveStream_font18__GktYw {
  font-size: 18px;
}
.LiveStream_font19__1FmPd {
  font-size: 16px;
}
.LiveStream_font20__2steV {
  font-size: 20px;
}
.LiveStream_font21__3fTFV {
  font-size: 21px;
}
.LiveStream_font22__2GgEP {
  font-size: 22px;
}
.LiveStream_font23__1f5o- {
  font-size: 23px;
}
.LiveStream_font24__1ok2l {
  font-size: 24px;
}
.LiveStream_font25__29_eb {
  font-size: 25px;
}
.LiveStream_fontHelvetica__1c5ot {
  font-family: "Helvetica Neue", sans-serif;
}

.LiveStream_Videocomponent__wF0qD {
  display: block;
}
.LiveStream_videojs2__1BC3- {
  font-size: 3em;
  line-height: 1.5em;
  height: 4.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 37%;
  left: 81px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  /* border: 0.06666em solid #fff; */
  /* background-color: #2B333F; */
  /* background-color: rgba(43, 51, 63, 0.7); */
  /* border-radius: 0.3em; */
  transition: all 0.4s;
  width: 44px;
  height: 44px;
  box-shadow: 0 2px 22px 0 rgba(123, 123, 123, 0.5);
  border: solid 1px #eaeaea;
  background-color: var(--white-two);
  background-color: var(--white-two);
  border-radius: 360px;
  background: #fff;
  color: #0ebac5;
}
.LiveStream_play_btn__16nyO {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #fff !important;
  border-radius: 0.3em;
  transition: all 0.4s;
}

.LiveStream_refreshIcon__1PJWT {
  float: right;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0) !important;
}

.LiveStream_setupStream__1tbQh {
  margin-left: 36%;
  margin-top: 29%;
  z-index: 999;
  position: absolute;
  background: rgba(255, 255, 255, 0.56863);
  padding: 20px;
  border-radius: 6px;
}

canvas {
  z-index: 97 !important;
}

/* Live Stream Button */

@-webkit-keyframes LiveStream_up-right__dOdXX {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale (1, 5);
            transform: scale (1, 5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.25;
  }
}

@keyframes LiveStream_up-right__dOdXX {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.25;
  }
  50% {
    -webkit-transform: scale (1, 5);
            transform: scale (1, 5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.25;
  }
}
.LiveStream_circle__1fEb8 {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  opacity: 0.25;
}
.LiveStream_red__1U-Zg {
  background-color: #0ebac5;
  position: absolute;
  top: -85%;
  left: 44%;
  -webkit-animation: LiveStream_up-right__dOdXX 1s infinite;
  animation: LiveStream_up-right__dOdXX 1s infinite;
}
.LiveStream_loaderDiv__2CNc3 {
  text-align: center;
}
.LiveStream_goToFullScreen__qRJJl {
  width: 30px;
  height: 25px;
  line-height: 23px;
  background: black;
  text-align: center;
  float: right;
  display: block;
  padding: 0;
  position: relative;
  z-index: 1;
}

.CommandLogs_mainDiv__O-ac_ {
  margin: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 51px;
  padding-left: 195px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
}

.CommandLogs_testDetailTopBar__2DIvb {
  font-size: 10px;
  letter-spacing: 0.4px;
  color: #747474;
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
}
.CommandLogs_testDetailTopBar__2DIvb span {
  color: #0ebac5;
  cursor: pointer;
}
.CommandLogs_filter_bar_option__1pTte {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CommandLogs_buildname__3NaJ_ {
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.24px;
  color: #47c5d8;
  font-size: 12px;
  padding: 16px;
}
.CommandLogs_searchTest__1u8FV {
  font-size: 12px;
  color: #9b9b9b !important;
  border: none;
  box-shadow: none !important;
  font-style: italic;
  padding-left: 31px;
}
.CommandLogs_searchTestParent__3xzN3 {
  position: relative;
  padding-left: 10px;
}
.CommandLogs_searchIcon__2VQXQ {
  background: url(/static/media/search.e4c66dbc.svg);
  background-repeat: no-repeat;
  background-position: 12px 4px;
  background-size: 12px 12px;
  width: 30px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 1;
}
.CommandLogs_test_concurrency__21pIn {
  display: inline-block;
  margin: 0px 20px;
  letter-spacing: 0.4px;
  color: #4a4a4a;
  font-size: 10px;
}
.CommandLogs_analytics_btn__3QlrU {
  margin-right: 10px;
  padding: 6px 13px 6px 10px;
  border-radius: 100px;
  background-color: #0ebac5;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.CommandLogs_filter_bar__1VWzM {
  font-size: 12px;
}
.CommandLogs_accesskey_btn__WuxhM {
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 100px;
  background-color: #fff;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.CommandLogs_metaInfo__3Nbps {
  margin-left: auto;
  cursor: pointer;
}
.CommandLogs_help_btn__A3tIs {
  padding: 4px 4px 3px;
  border-radius: 100px;
  background-color: #e2e2e2;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.CommandLogs_filter_rightbar_options__1oUuF {
  display: flex;
  float: right;
}
.CommandLogs_filter_bar_option__1pTte {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CommandLogs_add_filter_btn__3MZjJ {
  color: #0ebac5;
  cursor: pointer;
  font-size: 12px;
}
.CommandLogs_clear_filter_btn__7xDZ_ {
  color: #0ebac5;
  cursor: pointer;
}

.CommandLogs_progress5__2X1d_ {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #f6a39f);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.CommandLogs_progress0__33tbr {
  display: block;
  background: linear-gradient(to right, #ece7e6, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.CommandLogs_progress1__8kpUQ {
  display: block;
  background: linear-gradient(to right, #a2e58d, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.CommandLogs_progress2__hI4h5 {
  display: block;
  background: linear-gradient(to right, #a2e58d, #efef55, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.CommandLogs_progress3__JPhX7 {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.CommandLogs_progress4__1dSyM {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #e6c5c4);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}

.CommandLogs_dropdownSelect__2QHXg {
  display: flex !important;
  align-items: baseline;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  margin: 10px 0px 10px 10px;
  line-height: initial;
}
.CommandLogs_dropdownSelect__2QHXg select {
  display: inline-block;
  border: 1px solid #ebebeb;
  padding: 0px 40px 0 15px;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  border-radius: 50px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
}

.CommandLogs_dropdownSelect__2QHXg select:after {
  content: "";
  width: 30px;
  height: 30px;
  top: 10px;
  right: 5px;
  position: absolute;
  display: inline-block;
}
.CommandLogs_dropdownSelect__2QHXg .CommandLogs_labelText__2xGiv {
  margin-right: 10px;
}

.CommandLogs_filterbar__3Af1u {
  border-bottom: 1px solid #eaeaea;
}

/* #popover .help_btn  */
.CommandLogs_basic__1beXW {
  color: #333;
  max-width: 600px !important;
  width: 380px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}
.CommandLogs_help_popover__BWhIH {
  color: #333;
  max-width: 600px !important;
  width: 300px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}

.CommandLogs_menuHead__1Hjhm {
  color: #777;
  padding-left: 18px;
  font-size: 18px !important;
  padding-top: 10px;
}
.CommandLogs_helpMenuList__1rKRt {
  padding-left: 18px !important;
}

.CommandLogs_gitpod__modal_link__1pF29 {
  cursor: pointer;
}

.CommandLogs_access_key_field__2gsim {
  background-color: #eee;
  border-radius: 20px;
  height: 30px;
  font-size: 12px;
}
.CommandLogs_access_key__2_qwu {
  padding: 0px 20px;
  font-size: 12px !important;
}
.CommandLogs_copy_key__26JMX {
  float: right;
  width: 12px;
  height: 12px;
  top: 9px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.CommandLogs_access_key_header__24lqg {
  font-size: 16px !important;
}
.CommandLogs_access_key_row__COAid {
  margin-bottom: 10px !important;
}
.CommandLogs_btnBase__Pd9L7 {
  border-radius: 5px;
  color: #fff;
  background-color: #43bac5;
  border: 0px;
  padding: 7px;
  margin: 10px 0px;
}
.CommandLogs_copied__1SIph {
  position: absolute;
  right: 14px;
  top: 4px;
  background-color: #86d819;
  padding: 2px;
  border-radius: 21px;
}
.CommandLogs_access_key_genereted__3e3_k {
  display: flex;
  align-items: center;
  color: #86d819;
  font-size: 14px;
}
.CommandLogs_metadataHeader__21DBq {
  letter-spacing: 0.35px !important;
  color: #3c4144;
  cursor: pointer;
  font-size: 14px !important;
}

.CommandLogs_metaDataTable__2lQmP {
  max-height: 200px;
  overflow: auto;
  font-size: 12px;
  /* padding: 5px 20px; */
}
.CommandLogs_metaDataColumn__Sf1L9 {
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  padding: 5px;
}
.CommandLogs_metaDataValue__3o_Pg {
  letter-spacing: 0.3px;
  color: #3c4144;
}
.CommandLogs_wordBreak__2w-bi {
  word-break: break-all;
}
.CommandLogs_tunnelPill__2XYZS {
  width: 24px;
  height: 20px;
  background-color: #e2e2e2;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.CommandLogs_deleteTestIcon__2x-AQ {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff6565;
}

.CommandLogs_shareTestIcon__sqQAd {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.CommandLogs_attachmentWrapper__3M0Hi {
  display: flex;
  font-size: 12px;
}
.CommandLogs_attachmentName__2rqF2 {
  display: inline-block;
  letter-spacing: 0.3px;
  color: #3c4144;
}
.CommandLogs_attachmentSize__3NVkp {
  letter-spacing: 0.25px;
  color: #9b9b9b;
  font-size: 10px;
  display: inline-block;
}
.CommandLogs_attachmentRow__2PQ0d:hover .CommandLogs_actiondiv__3eZ2M {
  display: block;
}
.CommandLogs_action_btn__1NkJm {
  border-radius: 5px;
  border: 1px solid #979797;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  margin-right: 8px;
}
.CommandLogs_actiondiv__3eZ2M {
  display: none;
  text-align: center;
}
.CommandLogs_attachmentRow__2PQ0d {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.CommandLogs_attachmentRow__2PQ0d:hover {
  background-color: #f8fcff;
}
.CommandLogs_passedBuildStatusBar__3HlZG {
  background-color: #d8f5e7;
  display: flex;
  align-items: center;
  padding: 10px;
}

.CommandLogs_buildName__3KX4q {
  letter-spacing: 0.29px;
  color: #16985a;
  font-size: 10px;
}
.CommandLogs_buildStatus__2Aa-Y {
  padding: 5px;
  background-color: #37e291;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.CommandLogs_buildStatusIcon__f34hq {
  border-radius: 100%;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 2px 6px 2px 6px;
}
/* .buildStatusIcon img {
    width: 17px;
    height: 13px;
  } */
.CommandLogs_errorBuildStatusBar__2LHN6 {
  background-color: #fff5e5;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_errorBuildName__2kUlA {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.CommandLogs_errorBuildStatus__3Vd6a {
  padding: 5px;
  background-color: #ffa001;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.CommandLogs_cancelledBuildStatusBar__CoAu1 {
  background-color: #fbeadb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_cancelledBuildName__3JU4V {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.CommandLogs_cancelledBuildStatus__jGcNe {
  padding: 5px;
  background-color: #e67e22;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.CommandLogs_queuedBuildStatusBar__3bJyd {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_queuedBuildName__qC3tQ {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.CommandLogs_queuedBuildStatusBarNoStatus__23b7v {
  border: solid 1px #4a4a4a;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_queuedBuildNameNoStatus__1ydYC {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.CommandLogs_queuedBuildStatus__2RJbT {
  padding: 5px;
  background-color: #9cabb5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.CommandLogs_queuedStatusImg__xbI7d {
  border-radius: 10px;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  vertical-align: middle;
}
.CommandLogs_cypress_error_msg__3jcfy {
  color: #4a4a4a;
  letter-spacing: 0.3px;
  font-size: 12px;
  background-color: #fff;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.CommandLogs_errorcode__YCCto {
  background: #fae3e3 !important;
  color: #554d4d !important;
}

.CommandLogs_stoppedBuildStatusBar__3Npma {
  background-color: #fbdbdb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_stoppedBuildName__wFW4m {
  letter-spacing: 0.29px;
  color: #9d4242;
  font-size: 10px;
}
.CommandLogs_stoppedBuildStatus__2PE7F {
  padding: 4px 5px 5px 9px;
  background-color: #ff6565;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.CommandLogs_lambdaErrorBuildStatusBar__2D8fk {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_lambdaErrorBuildName__16aG4 {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.CommandLogs_lambdaErrorBuildStatus__15UwP {
  padding: 4px 5px 5px 9px;
  background-color: #0ebac5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.CommandLogs_timeoutBuildStatusBar__2bfui {
  background-color: #fdf9e7;
  display: flex;
  align-items: center;
  padding: 10px;
}
.CommandLogs_timeoutdBuildName__VS9lQ {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.CommandLogs_timeoutBuildStatus__VwFee {
  padding: 5px;
  background-color: #f1c40f;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.CommandLogs_logsTabSection__AeZTS {
  color: #4a4a4a;
  font-size: 12px;
}
.CommandLogs_searchLog__9Fp-1 {
  height: 28px;
  border-radius: 4px;
  padding-left: 30px;
  /* padding-right: 20px; */
  font-size: 12px;
  color: #61605c !important;
  border: solid 1px #eaeaea !important;
  box-shadow: none !important;
}
.CommandLogs_searchLogParent__3sFSc {
  position: relative;
  width: 50%;
}
.CommandLogs_searchLogIcon__VeSLq {
  background: url(/static/media/search.e4c66dbc.svg);
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 12px 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}
.CommandLogs_filterlogs__2780f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fcfcfc;
  border-bottom: 0.8px solid #f1f3f8;
}

.CommandLogs_col_xs_4_custom_1__1Ge8- {
  width: 31.3333%;
}
.CommandLogs_col_xs_4_custom_2__3NtnA {
  width: 35.3333%;
}
.CommandLogs_col_xs_8_custom__1az_6 {
  width: 67.3333%;
}

.CommandLogs_checkboxWrapper__19cEn {
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-right: 15px;
}
.CommandLogs_checkboxContainerLabel__1nrvh {
  font-family: "Inter", sans-serif;
  margin: 0px 10px 0px 5px;
}
.CommandLogs_checkboxContainer__8vx7S input[type="checkbox"] {
  position: relative;
  width: 14px;
  height: 14px;
  color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
.CommandLogs_checkboxContainer__8vx7S input[type="checkbox"]:before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 3px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}
.CommandLogs_checkboxContainer__8vx7S input[type="checkbox"]:checked {
  color: #fff;
  border-color: #eaeaea;
  background: #101010;
}
.CommandLogs_checkboxContainer__8vx7S input[type="checkbox"]:checked:before {
  opacity: 1;
}
.CommandLogs_checkboxContainer__8vx7S input[type="checkbox"]:checked ~ label:before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.CommandLogs_commandsLogWrapper__3k0dq {
  font-size: 12px;
  overflow-y: auto;
}

@media only screen and (max-width: 1024px) {

  .CommandLogs_commandsLogWrapper__3k0dq {
    min-height: 200px;
  }
}

.CommandLogs_commandsLog__B3gEp:hover .CommandLogs_bugBtn__CRk4G,
.CommandLogs_commandsLog__B3gEp:focus-within .CommandLogs_bugBtn__CRk4G {
  display: block;
  border: 1px solid #ff6565;
}
.CommandLogs_bugBtn__CRk4G {
  display: flex;
}
.CommandLogs_commandsLogUrlText__3Lf6n {
  color: #4a4a4a;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.CommandLogs_commandsLogUrlLink__1VrBM {
  color: #666;
  font-family: Space Mono;
  font-size: 10px;
  letter-spacing: normal;
  line-height: 15px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .CommandLogs_commandsLogUrlLink__1VrBM,
  .CommandLogs_commandsLogUrlText__3Lf6n {
    margin-left: 10px;
  }
}

.CommandLogs_playVideo__2-1AG {
  display: flex;
  justify-content: space-between;
}
.CommandLogs_playVideoIcon__3VXi6 {
  align-items: center;
  background: rgba(246, 248, 252, 0.85);
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  padding: 5px 7px;
}
.CommandLogs_activePlayVideoIcon__1X_Jg {
  background: #2f80ed;
  color: #fff;
}
.CommandLogs_playIconImg__1vmsZ {
  margin-right: 3px;
}

.CommandLogs_markAsBugBtn__1CFZl {
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  background-color: #0ebac5;
  padding: 5px;
  border-radius: 2px;
}

.CommandLogs_testListHeader__T3yr2 {
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.CommandLogs_testListHeader__T3yr2 > div {
  padding: 15px 7px;
}
.CommandLogs_testListBuildStatusBar__Q7Ja8 {
  margin-top: 15px;
}
.CommandLogs_testListBuildName___jV5X {
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}
span[role="img"].CommandLogs_lambdaErrorText__2clh4 {
  color: #ff6565;
}
span[role="img"].CommandLogs_error__37XYy {
  color: #f5a623;
}
span[role="img"].CommandLogs_failed__2g6fs {
  color: #ff6565;
}
span[role="img"].CommandLogs_timeout__3WjAQ {
  color: #4768fd;
}
span[role="img"].CommandLogs_greyedOut__1TJFf {
  color: #e0d8d8;
}
.CommandLogs_greyedOut__1TJFf {
  color: #e0d8d8;
}
.CommandLogs_testListBody__2wuZ9 {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4a4a4a;
  align-items: center;
}
.CommandLogs_testDetailDiv__3RGiG {
  display: flex;
}
.CommandLogs_selectTestCheckbox__1OhgZ {
  margin: 0px !important;
}
.CommandLogs_testDuration__2pRao {
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #9b9b9b;
}
.CommandLogs_tagName__10eeK {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #ebf5fc;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  margin-right: 10px;
}
.CommandLogs_cypress_suite_title__32_tb {
  background-color: #eaeaea;
  color: #4a4a4a;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  padding: 10px;
}
.CommandLogs_logsLeftDiv__vQN0k {
  border-right: 1px solid #d0c9d6;
}
.CommandLogs_tagNameMore__1AnlA {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #fff;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  border: 1px solid #e2e2e2;
  margin-right: 10px;
}
.CommandLogs_net_tab__2anPA {
  border-bottom: unset;
  margin: 15px 10px;
  position: relative;
}
.CommandLogs_net_tab2__2OdO3 {
  border-bottom: unset;
  margin: 15px 10px;
  background-color: green;
}
.CommandLogs_rawLogsTab__1oIvR {
  margin-top: 10px;
}
.CommandLogs_networkLogTable__2XVWQ {
  color: #4a4a4a;
}
.CommandLogs_networkLogTable__2XVWQ th {
  border: unset;
}
.CommandLogs_networkLogTable__2XVWQ td {
  border: unset;
}
.CommandLogs_download_report__3MMo6 {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
  width: 100%;
}

.CommandLogs_download_report__3MMo6 span {
  background-color: #f1f2f4;
  color: #4a4a4a;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 8px 10px 5px 10px;
  text-align: right;
  width: 100px;
  cursor: pointer;
}
.CommandLogs_performance__2b9hH {
  height: 600px;
  overflow: auto;
}
.CommandLogs_panelBox__3ksg0 {
  position: relative;
  padding-left: 35px;
}
.CommandLogs_panelItem__3OQS5 {
  border: none;
  border-top: 0.1px solid #eaeaea;
  border-radius: 0px;
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.CommandLogs_panelItem__3OQS5 :hover {
  background: #f9f9fa;
}

.CommandLogs_viewCheckbox__3M-Za input {
  top: -2px !important;
}

.CommandLogs_statusIcon__3c8ai {
  position: absolute;
  right: 30px;
  margin-top: 5px;
}

.CommandLogs_videoComponent__S-RMS {
  direction: rtl;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  /* margin-top: 4px; */
  position: relative;
  /* margin-top: 25px; */
  position: relative;
  /* height: 170px; */
}

.CommandLogs_videoComponent__S-RMS > div:nth-child(1) {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow: hidden;
}

.CommandLogs_videoComponent__S-RMS > div.CommandLogs_testMenu__3hoee {
  padding: 2px 10px;
}
.CommandLogs_videoComponent__S-RMS > div {
  width: 100%;
  height: 270px !important;
}
.CommandLogs_modalClass__2iw02 div[role="document"] {
  width: 324px;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  margin: 1% auto 0;
  top: 200px;
}
.CommandLogs_shareBuildFormField__IlisY {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.CommandLogs_shareModal__27P-o div[role="document"] {
  width: 500px !important;
  top: 150px !important;
}
.CommandLogs_headerModal__sKNUx img {
  /* float: right;
    cursor: pointer; */
  /* position: absolute;
    right: 25px; */
}
.CommandLogs_headerModal__sKNUx {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.CommandLogs_headerModal__sKNUx h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.CommandLogs_share_done_btn__1P4iT {
  border-radius: 5px;
  background-color: #0ebac5;
  color: #fff;
}
.CommandLogs_share_done_btn__1P4iT:hover {
  background-color: #0ebac5;
}
.CommandLogs_share_cancel_btn__32BNN {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4a4a4a;
  padding: 10px;
  cursor: pointer;
}
.CommandLogs_shareableLink__1j0sh {
  border-radius: 2px;
  border: solid 1px #eaeaea;
  background-color: #fafafa;
  text-align: center;
  padding: 6px 0px;
  cursor: pointer;
}

.CommandLogs_shareableLink__1j0sh span {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 6px;
}
.CommandLogs_shareTestForm__2vOOw {
  margin-top: 20px;
  letter-spacing: 0.41px;
  color: #4a4a4a;
  font-size: 12px;
}
.CommandLogs_expiry_drop_down__1BRem {
  font-size: 12px !important;
}
.CommandLogs_errorText__1PJgS {
  color: #ff0000;
  font-size: 11px;
}
.CommandLogs_shareLink__1cAgC {
  font-size: 12px;
}
.CommandLogs_copyBtn__1rYQl {
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
.CommandLogs_copied__1SIph {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 16px;
  background-color: #86d819;
  border-radius: 10px;
  padding: 2px;
}

.CommandLogs_actionContent__57KoJ {
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  font-size: 15px;
  margin-top: 15px;
}
.CommandLogs_actionButtons__NktaK {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.CommandLogs_actionButtons__NktaK button {
  padding: 9px 38px 9px 40px;
  border-radius: 4px;
  margin-top: 20px;
}
.CommandLogs_cancelBtn__omaum {
  border: solid 0.5px #000000;
  background-color: #fff;
  color: #111;
  text-align: center;
}
.CommandLogs_cancelBtn__omaum:hover {
  background-color: #0ebac5;
}
.CommandLogs_deleteBtn__39iTX:hover {
  background-color: #ff6565;
  border: unset;
}
.CommandLogs_deleteBtn__39iTX {
  background-color: #ff6565;
  color: #ffffff;
  text-align: center;
  border: unset;
}
.CommandLogs_headerModal__sKNUx img {
  /* float: right;
    cursor: pointer; */
}
.CommandLogs_headerModal__sKNUx h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.CommandLogs_commandsLog__B3gEp div[role="document"] {
  width: 1000px !important;
  top: 50px !important;
}
.CommandLogs_modalResponseHeader__WbI1y {
  letter-spacing: 0.35px;
  color: #000000;
  font-size: 14px;
  margin: 15px 0px;
}
.CommandLogs_metaInfoModal__197qj div[role="document"] {
  width: 90% !important;
  top: 50px !important;
  max-width: 1100px;
}
.CommandLogs_actionWrapperLogImage__25yyf {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-top: 1px solid #d0c9d6;
}
.CommandLogs_actionWrapperLogImage__25yyf .CommandLogs_actiondiv__3eZ2M {
  display: flex !important;
  align-items: center !important;
}
.CommandLogs_markAsBugBtnCmdLog__2m0Gx {
  color: #fff;
  background-color: #ff6565;
  font-size: 12px;
  padding: 6px 15px;
}
.CommandLogs_markAsBugBtnCmdLog__2m0Gx svg {
  margin-right: 5px;
}
.CommandLogs_markAsBugBtnCmdLog__2m0Gx:hover {
  color: #fff;
  background-color: #ff6565;
}
.CommandLogs_testSuitsTab__qodLE {
  position: relative;
  top: -40px;
  left: 10px;
}
.CommandLogs_testSuitsTab__qodLE span {
  display: inline-block;
  padding: 5px;
  background-color: #eaeaea;
  margin-right: 5px;
  cursor: pointer;
}
.CommandLogs_saveBtn__P2WqF {
  color: #fff;
  background-color: #4a4a4a;
  font-size: 12px;
  padding: 6px 15px;
}
.CommandLogs_cmdLogsIconsHover__sB50S {
  display: none;
  cursor: pointer;
}
.CommandLogs_commandsLog__B3gEp:hover .CommandLogs_cmdLogsIconsHover__sB50S {
  display: unset !important;
}
.CommandLogs_commandsLog__B3gEp:hover .CommandLogs_cmdLogsIcons__1lptQ {
  display: none;
}
.CommandLogs_metaInfoHeader__3o5ow {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.CommandLogs_metaInfoHeader2__1gvbu {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.CommandLogs_metaInfoModalColumn__1V6Xn {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px 0 2px 2px;
  font-weight: 500;
  width: 15vw;
}
.CommandLogs_metaInfoModalValue__19axk {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px;
  position: relative;
  padding-left: 30px;
  padding-right: 15px;
  width: 35vw;
}
.CommandLogs_copyIconWrapper__16hcy {
  position: absolute;
  top: 5px;
  right: 25px;
  z-index: 999;
}
.CommandLogs_metaDataTableInputConfig__1lquZ {
  max-height: 200px;
  min-height: 50px;
  min-width: 500px;
  overflow: auto;
  font-size: 12px;
  position: relative;
}
.CommandLogs_loaderLogs__3JdaC {
  background: #2b2b2b;
  height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.CommandLogs_downloadNeworkLogWrapper__3WMdl {
  position: relative;
  top: 4px;
  color: #43bac5;
  float: right;
  padding: 0px 10px;
  z-index: 1;
}
.CommandLogs_downloadLogDropdown__1hwl4 {
  background-color: #43bac5;
  color: #fff;
}
.CommandLogs_downloadLogDropdownItem__iEN44 {
  color: #4a4a4a;
  font-size: 12px;
}
.CommandLogs_downloadConsoleButton__mXH4U {
  z-index: 9;
  position: absolute;
  top: 4px;
  right: 0px;
  padding: 0px 10px;
}

.CommandLogs_consoleLogWrapper__2j_uj {
  position: relative;
}

.CommandLogs_commandLogRow__1TF8I {
  align-items: center;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  min-height: 38px;
  padding: 5px 0;
}

.CommandLogs_commandLogCdpRow__1uP2S {
  padding: 5px 10px 5px 0;
}

.CommandLogs_commandLogRow__1TF8I:hover {
  background: #f1f4f9;
}

.CommandLogs_modalBoxInnerContent__2yAS7 {
  justify-content: space-between;
  /* width: 1024px; */
}

.CommandLogs_customData__3C7yQ {
  justify-content: space-between;
}

.CommandLogs_flexRow__2rwW5 {
  display: flex;
  flex-direction: row;
}

.CommandLogs_headerRowAlignment__1DB7v {
  /* justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px; */
}
.CommandLogs_metaInfoWrapper__3-q4e {
  margin: 5px 28px;
}
.CommandLogs_copyImage__2fU9r {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.CommandLogs_copyImagePosition__3vPzJ {
  position: relative;
  float: right;
  cursor: pointer;
  z-index: 100;
}
.CommandLogs_downloadIcon__1R6TJ {
  margin-left: -7px;
  position: fixed;
  margin-top: 2px;
}

.CommandLogs_noStatusBuildStatusBar__1tOcv {
  height: 50px;
  justify-content: center;
  display: flex;
  background-color: #fafafa;
  align-items: center;
  border: 1px solid #eaeaea;
}

.CommandLogs_downloadSvg__KqO9K {
  position: relative;
  top: 3px;
}
.CommandLogs_noStatusBuildName__3vcrk {
  letter-spacing: 0.29px;
  color: #000000;
  font-size: 10px;
}

.CommandLogs_helpSupportBox__GurBj {
  margin-bottom: 5px;
  padding: 4px 7px 5px 5px;
  border-radius: 4px;
  background-color: #ff6565;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.CommandLogs_flexRow__2rwW5 {
  display: flex;
  flex-direction: row;
}

.CommandLogs_flexColumn__3jaSx {
  display: flex;
  flex-direction: column;
}

.CommandLogs_chatImage__3Iat5 {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.CommandLogs_supportText__2M6J0 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.CommandLogs_copyIconBox__1P-u8 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  opacity: 1.2;
  margin-top: 16px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #bbbbbb;
}

.CommandLogs_topBarButtons__qlwOS {
  justify-content: flex-end;
}

.CommandLogs_copied__1SIph {
  position: absolute;
  right: 109px;
  top: 31px;
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
}

.CommandLogs_copied__1SIph {
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
  position: absolute;
  right: 35px;
  top: 35px;
}

.CommandLogs_copyImagePosition__3vPzJ {
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #bbb;
  text-align: center;
  padding: 2px 5px;
  z-index: 99;
}

.CommandLogs_screenshotIconsContainer__3COaA {
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;
  grid-gap: 10px;
  gap: 10px;
}

.CommandLogs_hypertestBtn__2xHtK {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #0ebac5;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 128px;
}

.CommandLogs_loadingHtBtn__DRKOv {
  color: #0ebac5;
}

.CommandLogs_loadingHtBtn__DRKOv > span {
  display: flex;
  justify-content: space-between;
}

.CommandLogs_actionButton__3mfq4 {
  align-items: center;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.CommandLogs_commandsLog__B3gEp:hover .CommandLogs_bugBtn__CRk4G,
.CommandLogs_commandsLog__B3gEp:hover .CommandLogs_viewParametersBtn__mpw81,
.CommandLogs_commandsLog__B3gEp:focus-within .CommandLogs_bugBtn__CRk4G,
.CommandLogs_commandsLog__B3gEp:focus-within .CommandLogs_viewParametersBtn__mpw81 {
  display: flex;
}

.CommandLogs_bugBtn__CRk4G {
  display: none;
}

.CommandLogs_viewParametersBtn__mpw81 {
  display: none;
}

.CommandLogs_failedChip__2qXV6 {
  background: rgba(255, 226, 219, 0.85);
  color: #fb6637;
}

.CommandLogs_successChip__druYM {
  background: #2ab14f;
  color: #fff;
}

.CommandLogs_successChip__druYM,
.CommandLogs_failedChip__2qXV6,
.CommandLogs_pendingChip__eirY8 {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  justify-content: center;
  padding: 2px 6px;
  width: 100%;
  word-break: break-all;
}

.CommandLogs_pendingChip__eirY8 {
  background: #fafa23;
  color: #997f60;
}

.CommandLogs_responseParameterContainer__1OnPh {
  font-size: 14px;
  height: auto;
  max-height: 245px;
  position: relative;
  margin-bottom: 50px;
}

.CommandLogs_copyIcon__1Wwgc {
  position: absolute;
  right: 15px;
  top: 45px;
}

.CommandLogs_syntaxHighlighterClass__1cGN1 {
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  margin-bottom: 35px;
  max-height: 220px;
  overflow: scroll;
  padding: 20px !important;
  word-wrap: initial !important;
  word-break: initial !important;
}

.CommandLogs_statusIconContainer__1uHnQ {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.CommandLogs_statusSuccess__3Oawe {
  border: 1px solid #2ab14f;
}

.CommandLogs_statusFailed__2sIgR {
  border: 1px solid #f45c47;
}

.CommandLogs_commandLogsContainer__xCsJK {
  padding: 8px 16px;
  width: 100%;
}

.CommandLogs_colDiv-1__2RP-n {
  min-width: 5%;
  max-width: 5%;
}

.CommandLogs_colDiv-2__1iU7t {
  min-width: 12%;
  max-width: 12%;
}

.CommandLogs_colDiv-3__3l11L {
  min-width: 15%;
  max-width: 15%;
}
.CommandLogs_colDiv-4__3Dulq {
  min-width: 23%;
  max-width: 23%;
}

.CommandLogs_colDiv-5__3IQ3V {
  min-width: 27%;
  max-width: 27%;
}

.CommandLogs_colDiv-55__2R1Hu {
  min-width: 30%;
  max-width: 30%;
}

.CommandLogs_colDiv-6__2EphO {
  min-width: 44%;
  max-width: 44%;
}

.CommandLogs_flex__3RPLX {
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.CommandLogs_popoverSectionHeading__2DO8M {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin-bottom: 8px;
}

.CommandLogs_autoHealContainer__2KY62 {
  padding: 1px;
  display: flex;
}

.CommandLogs_copyHealedParam__2Lang {
  margin-top: 2px;
}

.CommandLogs_usingSelector__1FQxt {
  border: 1px solid #ddd;
  border-radius: 100px;
  display: flex;
  padding: 1px 7px;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.CommandLogs_originalSelectorSection__2B-8e {
  margin-top: 15px;
}

.CommandLogs_commandLogsDurationContainer__3Yol4 {
  min-width: 40px;
}

.CommandLogs_healedValueContainer__s89QQ,
.CommandLogs_actualValueContainer__2WMGm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.CommandLogs_healedValueContainer__s89QQ {
  color: #176f2c;
}

.CommandLogs_actualValueContainer__2WMGm {
  color: #cf370e;
}

.CommandLogs_healedValue__1i1-J,
.CommandLogs_actualValue__1Z9tY {
  margin-left: 8px;
}

.CommandLogs_popoverRow__1Bbc2 {
  display: flex;
  justify-content: space-between;
}

.CommandLogs_healedSectionHeading__16Rez {
  padding-top: 0;
}

.CommandLogs_annotationText__1Mk3n {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.CommandLogs_noListStyle__1-cQo {
  list-style: none;
  padding: 0;
}

.CommandLogs_commandLogHeader__T5oVS {
  align-items: center;
  border: 1px solid;
  border-top: none;
  border-bottom: 1px solid;
  display: flex;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1024px) {

  .CommandLogs_commandLogHeader__T5oVS {
    overflow: auto;
  }
}

.CommandLogs_commandLogHeader__T5oVS:hover {
  background: rgba(221, 221, 221, 0.2);
}

.CommandLogs_commandLogHeader__T5oVS:hover .CommandLogs_collapseExpandCommandLog__g2msh svg path {
  stroke: #333;
}

.CommandLogs_commandLogHeaderFirst__36QqO {
  border-top: none;
}

.CommandLogs_commandLogHeaderError__w7oPC {
  border-left: 1.25px solid #cf222e !important;
  background-color: #fdf4f5;
}

.CommandLogs_commandLogHeaderError__w7oPC:hover {
  background-color: #fff2f0;
}

.CommandLogs_commandLogHeaderSuccess__3knvu {
  border-left: 1.25px solid #176f2c !important;
  background-color: #f0f0f0;
}

.CommandLogs_commandLogHeaderPending__2Nrg9 {
  border-left: 1px solid #bf8700 !important;
}

.CommandLogs_commandLogTitleSection__TbetC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
  flex: 1 0;
  padding: 10px 0 10px 16px;
  position: relative;
}

.CommandLogs_commandLogTitle__3-oI8 {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
}

.CommandLogs_commandLogTitle__3-oI8 h4 {
  font-size: 14px;
  margin-bottom: 0;
}

.CommandLogs_commandLogDuration__3wwev,
.CommandLogs_commandLogTimestamp__2q3Cw {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  grid-gap: 4px;
  gap: 4px;
  line-height: 18px;
}

.CommandLogs_dot__1WXoH {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #888;
}

.CommandLogs_commandLogSecondaryInfo__2a8zF {
  align-items: center;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-left: 24px;
}

@media only screen and (max-width: 1024px) {

  .CommandLogs_commandLogSecondaryInfo__2a8zF {
    flex-wrap: wrap;
  }
}

.CommandLogs_commandLogResponseStatus__2OkmA {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.CommandLogs_commandLogTitleAction__3bdJs {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
}

.CommandLogs_errorRemark__1tkWi,
.CommandLogs_commandLogRemark__3subV,
.CommandLogs_annotationBar__3y-nO {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  grid-gap: 16px;
  gap: 16px;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
}

.CommandLogs_errorRemark__1tkWi {
  background: #fdf4f5;
  border-left: 1.25px solid #cf222e;
}

.CommandLogs_annotationBar-warn__1aZSP,
.CommandLogs_annotationBar-debug__4HCyE {
  border-top: 1px solid rgba(212, 167, 44, 0.4);
  border-bottom: 1px solid rgba(212, 167, 44, 0.4);
  background: #fff8c5;
}

.CommandLogs_annotationBar-error__2jMRa {
  border-top: 1px solid rgba(255, 129, 130, 0.4);
  border-bottom: 1px solid rgba(255, 129, 130, 0.4);
  background-color: #ffebe9;
}

.CommandLogs_annotationBar-info__3umTQ,
.CommandLogs_commandLogRemark__3subV {
  border-top: 1px solid rgba(84, 174, 255, 0.4);
  border-bottom: 1px solid rgba(84, 174, 255, 0.4);
  background-color: #ddf4ff;
}

.CommandLogs_cypressCodeBlockWrapper__35VRP {
  border: 1px solid #ddd;
  border-bottom: none;
}

.CommandLogs_cypressCodeBlockWrapper__35VRP:last-child {
  border-bottom: 1px solid #ddd;
}

.CommandLogs_commandLogHeaderError__w7oPC ~ .CommandLogs_commandLogRemark__3subV,
.CommandLogs_commandLogHeaderError__w7oPC ~ .CommandLogs_cypressCodeBlockWrapper__35VRP,
.CommandLogs_commandLogHeaderError__w7oPC .CommandLogs_errorRemark__1tkWi,
.CommandLogs_commandLogHeaderError__w7oPC .CommandLogs_annotationBar-info__3umTQ,
.CommandLogs_commandLogHeaderError__w7oPC .CommandLogs_annotationBar-error__2jMRa,
.CommandLogs_commandLogHeaderError__w7oPC .CommandLogs_annotationBar-debug__4HCyE {
  border-left: 1.25px solid rgb(207, 34, 46);
  border-right: 1px solid #ddd;
}

.CommandLogs_commandLogHeaderSuccess__3knvu ~ .CommandLogs_commandLogRemark__3subV,
.CommandLogs_commandLogHeaderSuccess__3knvu ~ .CommandLogs_cypressCodeBlockWrapper__35VRP,
.CommandLogs_commandLogHeaderSuccess__3knvu .CommandLogs_errorRemark__1tkWi,
.CommandLogs_commandLogHeaderSuccess__3knvu .CommandLogs_annotationBar-info__3umTQ,
.CommandLogs_commandLogHeaderSuccess__3knvu .CommandLogs_annotationBar-error__2jMRa,
.CommandLogs_commandLogHeaderSuccess__3knvu .CommandLogs_annotationBar-debug__4HCyE {
  border-left: 1.25px solid #176f2c;
  border-right: 1px solid #ddd;
}

.CommandLogs_commandLogHeaderPending__2Nrg9 ~ .CommandLogs_cypressCodeBlockWrapper__35VRP {
  border-left: 1px solid #bf8700;
  border-right: 1px solid #ddd;
}
.CommandLogs_loader__3gbgr {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.CommandLogs_flakyTestHeader__hzVwo {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  font-weight: 600;
  align-items: center;
}

.CommandLogs_suiteTitle__MfuGi {
  align-items: center;
  align-self: stretch;
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 8px 16px;
}

.CommandLogs_specExecutionTime__27rwu {
  color: #666;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.CommandLogs_actionWrapper__1IbJy {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.CommandLogs_actionBar__3uDx3 {
  display: flex;
}

.CommandLogs_cypressCodeBlock__1Nd_- {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 16px;
}

.CommandLogs_cypressCodeBlockDark__2w-K6 {
  background: rgb(40, 44, 52);
}

.CommandLogs_cypressCodeBlockWrapper__35VRP {
  padding: 10px 16px;
}

.CommandLogs_commandLogActive__1qheR {
  background-color: rgba(221, 221, 221, 0.4);
}

.CommandLogs_commandLogActive__1qheR.CommandLogs_commandLogHeaderError__w7oPC {
  background-color: #ffebe9;
}

.CommandLogs_collapseExpandCommandLog__g2msh {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex: 0 1;
  grid-gap: 8px;
  gap: 8px;
  grid-gap: 8px;
  justify-content: center;
  padding: 10px 20px;
  width: 15%;
}

.CommandLogs_collapseExpandCommandLog__g2msh:focus {
  outline-color: #0969DA;
}

.CommandLogs_cursorPointer__3V8lc {
  cursor: pointer;
}

.CommandLogs_commandLogsPlaceholderBtn__y7rRc {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  height: 100% !important;
  position: absolute;
  width: 100%;
  left: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.CommandLogs_commandLogsPlaceholderBtn__y7rRc:hover {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.CommandLogs_commandLogsPlaceholderBtn__y7rRc.CommandLogs_focus-visible__3nIbn {
  outline: 2px solid #0969DA;
}

.CommandLogs_frameworkExceptionLogContainer__3pQig {
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding: 10px 16px;
}

@media only screen and (max-width: 1024px) {

  .CommandLogs_frameworkExceptionLogContainer__3pQig {
    overflow: auto;
  }
}

.CommandLogs_copyButton__w8hSM {
  display: none;
}

.CommandLogs_paramInfo__1xUnn:hover .CommandLogs_copyButton__w8hSM {
  display: block;
}

.CommandLogParameters_title__2MhLe {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1024px) {

  .CommandLogParameters_title__2MhLe {
    flex-wrap: wrap;
  }
}

.CommandLogParameters_actionWrapper__1QOjw {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.CommandLogParameters_actionBar__2tPJh {
  display: flex;
}

.CommandLogParameters_tableWrapper__1tWHW {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.CommandLogParameters_jsonSwitchContainer__2uriX {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  grid-gap: 8px;
  gap: 8px;
}

.CommandLogParameters_ltCommandLogParamsContainer__1ra1M {
  padding: 10px 16px 10px 36px;
  border-bottom: 1px solid;
  border-right: 1px solid;
}

@media only screen and (max-width: 1024px) {

  .CommandLogParameters_ltCommandLogParamsContainer__1ra1M {
    overflow: auto;
  }
}
.TestCommandLogsDrawer_drawerWrapper__2EF5U {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  grid-gap: 12px;
  gap: 12px;
  overflow: hidden;
}

.TestCommandLogsDrawer_screenshotWrapper__1DFEx,
.TestCommandLogsDrawer_liveStreamWrapper__28gKu {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 50px;
  width: calc(93vw - 500px);
}

.TestCommandLogsDrawer_liveStreamWrapper__28gKu {
  height: 100%;
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 320px) {

  .TestCommandLogsDrawer_liveStreamWrapper__28gKu {
    min-height: 200px;
  }
}

.TestCommandLogsDrawer_screenshotWrapper__1DFEx > img {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  outline: 1px solid #cfcfcf;
}

.TestCommandLogsDrawer_infoWrapper__16lce {
  background: #ffffff;
  border-left: 1px solid #eaeaea;
  box-shadow: -3px 5px 14px rgba(209, 209, 209, 0.25);
  box-sizing: border-box;
  flex: 3 1;
  max-width: 500px;
  position: relative;
}

.TestCommandLogsDrawer_liveInteractionBtnContainer__2keTn {
  height: 38px;
  left: -20px;
  position: absolute;
  top: 45px;
  width: 38px;
}

.TestCommandLogsDrawer_collapsedLiveInteractionBtn__1nQuc {
  background: url(/static/media/exit-fullscreen.c8bedccb.svg);
}

.TestCommandLogsDrawer_expandedLiveInteractionBtn__1W3n_ {
  background: url(/static/media/enter-fullscreen.c2565dac.svg);
}

.TestCommandLogsDrawer_expandedLiveInteraction__1ht8D {
  width: calc(93vw - 20px);
}

.TestCommandLogsDrawer_shrinkLiveInteraction__2cKkH {
  width: 65%;
}

.TestCommandLogsDrawer_liveStreamContainer__17U-a {
  max-height: 100%;
  width: 100%;
}

.TestCommandLogsDrawer_expandedLiveInteraction__1ht8D > .TestCommandLogsDrawer_liveStreamContainer__17U-a .TestCommandLogsDrawer_liveStreamWrapper__28gKu {
  width: calc(93vw - 20px);
}

.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW {
  max-width: 20px;
}

.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW .TestCommandLogsDrawer_testDeviceSpecsWrapper__3HGuz,
.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW .TestCommandLogsDrawer_buttonsWrapper__gMcu1,
.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW .TestCommandLogsDrawer_headerWrapper__21jk3,
.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW .TestCommandLogsDrawer_commandLogWrapper__E55Sp {
  display: none;
}

.TestCommandLogsDrawer_collapsedInfoWrapper__3YZKW .TestCommandLogsDrawer_closeDrawerIcon__1yax0 {
  left: -30px;
  position: absolute;
}

.TestCommandLogsDrawer_infoWrapper__16lce > div {
  border-bottom: 1px solid #f1f3f8;
}

.TestCommandLogsDrawer_infoWrapper__16lce > div:last-child {
  border-bottom: none;
}

.TestCommandLogsDrawer_headerWrapper__21jk3 {
  display: flex;
  padding: 17px 23px;
}

.TestCommandLogsDrawer_headerWrapper__21jk3 > img {
  cursor: pointer;
  margin-left: auto;
}

.TestCommandLogsDrawer_headerTitle__20ywH > span > h4 {
  margin-bottom: 0px;
  margin-right: 6px;
}

.TestCommandLogsDrawer_headerTitle__20ywH > span {
  color: #999999;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 6px;
}

.TestCommandLogsDrawer_headerRunningState__bRjMo {
  align-items: center;
  border: 0.5px solid #219653;
  border-radius: 2.27423px;
  color: #219653;
  display: flex;
  font-size: 9px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 11px;
  padding: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.TestCommandLogsDrawer_headerRunningState__bRjMo > img {
  margin-right: 3px;
}

.TestCommandLogsDrawer_buttonsWrapper__gMcu1 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.TestCommandLogsDrawer_actionButton__pX49q {
  align-items: center;
  border: 0.8px solid #dfe6ef;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  padding: 5px 10px;
}

.TestCommandLogsDrawer_actionButton__pX49q:focus {
  outline: 1px solid #333;
}

.TestCommandLogsDrawer_bugButton__1LpUR {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 768px) {

  .TestCommandLogsDrawer_bugButton__1LpUR {
    display: none;
  }
}

.TestCommandLogsDrawer_refreshButton__1NITc {
  background-color: #f7f7f7;
}

.TestCommandLogsDrawer_abortTestCasesButton__32Gjx {
  color: #ffbb36;
  border-color: #ffbb36;
}

.TestCommandLogsDrawer_actionButton__pX49q:hover {
  background-color: #f7f9fa;
}

.TestCommandLogsDrawer_codeWrapper__3MnUp {
  padding: 23px;
}

.TestCommandLogsDrawer_codeWrapper__3MnUp > div > button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.TestCommandLogsDrawer_codeWrapper__3MnUp > div > button.TestCommandLogsDrawer_active__3lbx1 {
  border-bottom: 1px solid #000;
}

.TestCommandLogsDrawer_active__3lbx1 {
  border-bottom: 1px solid #000;
}

.TestCommandLogsDrawer_codeWrapper__3MnUp > div {
  position: relative;
}

.TestCommandLogsDrawer_codeWrapper__3MnUp > div > h4 {
  margin-bottom: 15px;
}

.TestCommandLogsDrawer_syntaxHighlighterClass__s24BJ {
  background: #24292f !important;
  border: none;
  border-radius: 8px;
  color: #ffffff !important;
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  margin-bottom: 35px;
  max-height: 220px;
  overflow: scroll;
  padding: 20px !important;
  word-wrap: initial !important;
  word-break: initial !important;
}

.TestCommandLogsDrawer_syntaxHighlighterClass__s24BJ * {
  color: #fff !important;
  background-color: transparent !important;
}

.TestCommandLogsDrawer_copyIcon__3Ovhz {
  position: absolute;
  right: 15px;
  top: 45px;
}

.TestCommandLogsDrawer_errorcode__3w60x {
  background: #fae3e3 !important;
  color: #554d4d !important;
}

.TestCommandLogsDrawer_cypress_error_msg__N6zzg {
  background-color: #fff;
  color: #4a4a4a;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
  padding: 20px 0px;
}

.TestCommandLogsDrawer_cypressErrorContainer__1_ura {
  font-size: 12px;
  margin-bottom: 35px;
  max-height: 200px;
  overflow: auto;
}

.TestCommandLogsDrawer_abortImage__1E_sN {
  margin-right: 5px;
}

.TestCommandLogsDrawer_logsHeader__3bOMh {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {

  .TestCommandLogsDrawer_logsHeader__3bOMh {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 320px) {

  .TestCommandLogsDrawer_logsHeader__3bOMh {
    flex-direction: column;
  }
}

.TestCommandLogsDrawer_deviceInfoWrapper__3mbHD {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

@media only screen and (max-width: 1024) {

  .TestCommandLogsDrawer_deviceInfoWrapper__3mbHD {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 512px) {

  .TestCommandLogsDrawer_commandLogsButton__lmsXA {
    display: none;
  }
}

@media only screen and (max-width: 512px) {

  .TestCommandLogsDrawer_commandLogs__1fzr4 {
    display: none;
  }
}

@media only screen and (max-width: 320px) {

  .TestCommandLogsDrawer_drawerToast__fd8p_ {
    display: none;
  }
}

.WebRTCViewer_stream_container__2ktoa {
  position: relative;
  height: inherit;
}

.WebRTCViewer_loaderDiv__OVEQo {
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
}

.WebRTCViewer_webrtc_stream_parent__sNSeD {
  display: flex;
  justify-content: center;
  height: inherit;
  position: relative;
}

.WebRTCViewer_webrtc_remoteVideo__u3GJS {
  height: 95%;
  width: 95%;
}

.WebRTCViewer_webrtc_canvas__37V6F {
  height: 100%;
  position: absolute;
  top: 0px;
  width: 95%;
}

.indexV2_mainDiv__2vSxo {
  margin: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 51px;
  padding-left: 195px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
}

.indexV2_screenshotImage__HOp4L.indexV2_focus-visible__Zb6f3 {
  outline: 2px solid #0969da;
}

.indexV2_annotationHeader__3kzjq:focus {
  margin: 0px 2px;
  outline: 2px solid #0969da;
}

.indexV2_testDetailTopBar__kuc0L {
  font-size: 10px;
  letter-spacing: 0.4px;
  color: #747474;
  padding: 8px 15px;
  border-bottom: 1px solid #eaeaea;
}
.indexV2_testDetailTopBar__kuc0L span {
  color: #0ebac5;
  cursor: pointer;
}
.indexV2_filter_bar_option__UnuC6 {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.indexV2_buildname__17HHZ {
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.24px;
  color: #47c5d8;
  font-size: 12px;
  padding: 16px;
}
.indexV2_searchTest__3jGdJ {
  font-size: 12px;
  color: #9b9b9b !important;
  border: none;
  box-shadow: none !important;
  font-style: italic;
  padding-left: 31px;
}
.indexV2_searchTestParent__1Yfof {
  position: relative;
  padding-left: 10px;
}
.indexV2_searchIcon__2hnCO {
  background: url(/static/media/search.e4c66dbc.svg);
  background-repeat: no-repeat;
  background-position: 12px 4px;
  background-size: 12px 12px;
  width: 30px;
  height: 20px;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  z-index: 1;
}
.indexV2_test_concurrency__16N9S {
  display: inline-block;
  margin: 0px 20px;
  letter-spacing: 0.4px;
  color: #4a4a4a;
  font-size: 10px;
}
.indexV2_analytics_btn__3jWuc {
  margin-right: 10px;
  padding: 6px 13px 6px 10px;
  border-radius: 100px;
  background-color: #0ebac5;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.indexV2_filter_bar__yNlcz {
  font-size: 12px;
}
.indexV2_accesskey_btn__2tQSa {
  margin-right: 10px;
  padding: 6px 12px;
  border-radius: 100px;
  background-color: #fff;
  border: 1px solid #0ebac5;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.indexV2_metaInfo__2oMlZ {
  margin-left: auto;
  cursor: pointer;
}
.indexV2_help_btn__2ApK4 {
  padding: 4px 4px 3px;
  border-radius: 100px;
  background-color: #e2e2e2;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  color: #fff;
}
.indexV2_filter_rightbar_options__29zk3 {
  display: flex;
  float: right;
}
.indexV2_filter_bar_option__UnuC6 {
  padding: 8px 15px;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.indexV2_add_filter_btn__vy69w {
  color: #0ebac5;
  cursor: pointer;
  font-size: 12px;
}
.indexV2_clear_filter_btn__ssYs_ {
  color: #0ebac5;
  cursor: pointer;
}

.indexV2_progress5__sqicY {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #f6a39f);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.indexV2_progress0__KdboZ {
  display: block;
  background: linear-gradient(to right, #ece7e6, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.indexV2_progress1__2zQzc {
  display: block;
  background: linear-gradient(to right, #a2e58d, #ece7e6, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.indexV2_progress2__9l0Ip {
  display: block;
  background: linear-gradient(to right, #a2e58d, #efef55, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.indexV2_progress3__358sf {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #ece7e6);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}
.indexV2_progress4__2PS1S {
  display: block;
  background: linear-gradient(to right, #a2e58d, yellow, #e6c5c4);
  border-radius: 10px;
  width: 100%;
  height: 5px;
}

.indexV2_dropdownSelect__3UxDv {
  display: flex !important;
  align-items: baseline;
  border: 1px solid #eaeaea;
  border-radius: 30px;
  margin: 10px 0px 10px 10px;
  line-height: initial;
}
.indexV2_dropdownSelect__3UxDv select {
  display: inline-block;
  border: 1px solid #ebebeb;
  padding: 0px 40px 0 15px;
  margin: 0 5px;
  outline: none;
  -webkit-appearance: none;
  border-radius: 50px;
  box-shadow: none;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
}

.indexV2_dropdownSelect__3UxDv select:after {
  content: "";
  width: 30px;
  height: 30px;
  top: 10px;
  right: 5px;
  position: absolute;
  display: inline-block;
}
.indexV2_dropdownSelect__3UxDv .indexV2_labelText__2E82o {
  margin-right: 10px;
}

.indexV2_filterbar__1Uff5 {
  border-bottom: 1px solid #eaeaea;
}

/* #popover .help_btn  */
.indexV2_basic__3qSH_ {
  color: #333;
  max-width: 600px !important;
  width: 380px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}
.indexV2_help_popover__3wfK_ {
  color: #333;
  max-width: 600px !important;
  width: 300px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11) !important;
}

.indexV2_menuHead__2wD5g {
  color: #777;
  padding-left: 18px;
  font-size: 18px !important;
  padding-top: 10px;
}
.indexV2_helpMenuList__w3cIJ {
  padding-left: 18px !important;
}

.indexV2_gitpod__modal_link__1o2d4 {
  cursor: pointer;
}

.indexV2_access_key_field__3HPFp {
  background-color: #eee;
  border-radius: 20px;
  height: 30px;
  font-size: 12px;
}
.indexV2_access_key__3BN4q {
  padding: 0px 20px;
  font-size: 12px !important;
}
.indexV2_copy_key__Xfosz {
  float: right;
  width: 12px;
  height: 12px;
  top: 9px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.indexV2_access_key_header__aAJJE {
  font-size: 16px !important;
}
.indexV2_access_key_row__1ukIP {
  margin-bottom: 10px !important;
}
.indexV2_btnBase__3Igkz {
  border-radius: 5px;
  color: #fff;
  background-color: #43bac5;
  border: 0px;
  padding: 7px;
  margin: 10px 0px;
}
.indexV2_copied__ivg4u {
  position: absolute;
  right: 14px;
  top: 4px;
  background-color: #86d819;
  padding: 2px;
  border-radius: 21px;
}
.indexV2_access_key_genereted__1OYKH {
  display: flex;
  align-items: center;
  color: #86d819;
  font-size: 14px;
}
.indexV2_metadataHeader__3i88m {
  letter-spacing: 0.35px !important;
  color: #3c4144;
  cursor: pointer;
  font-size: 14px !important;
}

.indexV2_metaDataTable__EXRQu {
  max-height: 200px;
  overflow: auto;
  font-size: 12px;
  /* padding: 5px 20px; */
}
.indexV2_metaDataColumn__1TYVf {
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  padding: 5px;
}
.indexV2_metaDataValue__tdvzk {
  letter-spacing: 0.3px;
  color: #3c4144;
}
.indexV2_wordBreak__sSr4s {
  word-break: break-all;
}
.indexV2_tunnelPill__FIAkE {
  width: 24px;
  height: 20px;
  background-color: #e2e2e2;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.indexV2_deleteTestIcon__2KJ5V {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff6565;
}

.indexV2_shareTestIcon__1hYfb {
  width: 24px;
  height: 20px;
  margin: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
}
.indexV2_attachmentWrapper__27Thh {
  display: flex;
  font-size: 12px;
}
.indexV2_attachmentName__2EKv- {
  display: inline-block;
  letter-spacing: 0.3px;
  color: #3c4144;
}
.indexV2_attachmentSize__1DxYa {
  letter-spacing: 0.25px;
  color: #9b9b9b;
  font-size: 10px;
  display: inline-block;
}
.indexV2_attachmentRow__2P4_D:hover .indexV2_actiondiv__1jssU {
  display: block;
}
.indexV2_action_btn__9ZuRE {
  border-radius: 5px;
  border: 1px solid #979797;
  display: inline-block;
  width: 28px;
  height: 28px;
  text-align: center;
  margin-right: 8px;
}
.indexV2_actiondiv__1jssU {
  display: none;
  text-align: center;
}
.indexV2_attachmentRow__2P4_D {
  margin-bottom: 15px;
  cursor: pointer;
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.indexV2_attachmentRow__2P4_D:hover {
  background-color: #f8fcff;
}
.indexV2_passedBuildStatusBar__2O_wP {
  background-color: #d8f5e7;
  display: flex;
  align-items: center;
  padding: 10px;
}

.indexV2_buildName__1HEUM {
  letter-spacing: 0.29px;
  color: #16985a;
  font-size: 10px;
}
.indexV2_buildStatus__1CE_1 {
  padding: 5px;
  background-color: #37e291;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.indexV2_buildStatusIcon__uSP5X {
  border-radius: 100%;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 2px 6px 2px 6px;
}
/* .buildStatusIcon img {
      width: 17px;
      height: 13px;
    } */
.indexV2_errorBuildStatusBar__ewbE7 {
  background-color: #fff5e5;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_errorBuildName__NpnAl {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.indexV2_errorBuildStatus__OUbCZ {
  padding: 5px;
  background-color: #ffa001;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.indexV2_cancelledBuildStatusBar__QaTpj {
  background-color: #fbeadb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_cancelledBuildName__3lPJ0 {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.indexV2_cancelledBuildStatus__3ErSv {
  padding: 5px;
  background-color: #e67e22;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.indexV2_queuedBuildStatusBar__amEwE {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_queuedBuildName__36tMj {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.indexV2_queuedBuildStatusBarNoStatus__2eiqA {
  border: solid 1px #4a4a4a;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_queuedBuildNameNoStatus__16pxh {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.indexV2_queuedBuildStatus__3aA7V {
  padding: 5px;
  background-color: #9cabb5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.indexV2_queuedStatusImg__1vpgP {
  border-radius: 10px;
  margin-left: 5px;
  background: #fff;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  vertical-align: middle;
}
.indexV2_cypress_error_msg__3ohWB {
  color: #4a4a4a;
  letter-spacing: 0.3px;
  font-size: 12px;
  background-color: #fff;
  padding: 20px 0px;
  margin-bottom: 20px;
}
.indexV2_errorcode__2nu_K {
  background: #fae3e3 !important;
  color: #554d4d !important;
}

.indexV2_stoppedBuildStatusBar__3bwrO {
  background-color: #fbdbdb;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_stoppedBuildName__aIad8 {
  letter-spacing: 0.29px;
  color: #9d4242;
  font-size: 10px;
}
.indexV2_stoppedBuildStatus__1s7AS {
  padding: 4px 5px 5px 9px;
  background-color: #ff6565;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.indexV2_lambdaErrorBuildStatusBar__Z3UU0 {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_lambdaErrorBuildName__2uKkW {
  letter-spacing: 0.29px;
  color: #4a4a4a;
  font-size: 10px;
}
.indexV2_lambdaErrorBuildStatus__3TbZG {
  padding: 4px 5px 5px 9px;
  background-color: #0ebac5;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}
.indexV2_timeoutBuildStatusBar__AskCt {
  background-color: #fdf9e7;
  display: flex;
  align-items: center;
  padding: 10px;
}
.indexV2_timeoutdBuildName__1VIGS {
  letter-spacing: 0.29px;
  color: #e67e22;
  font-size: 10px;
}
.indexV2_timeoutBuildStatus__2TjtU {
  padding: 5px;
  background-color: #f1c40f;
  border-radius: 6.5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin-right: 12px;
}

.indexV2_logsTabSection__38Vn2 {
  color: #4a4a4a;
  font-size: 12px;
}
.indexV2_searchLog__efz2W {
  height: 28px;
  border-radius: 4px;
  padding-left: 30px;
  /* padding-right: 20px; */
  font-size: 12px;
  color: #61605c !important;
  border: solid 1px #eaeaea !important;
  box-shadow: none !important;
}
.indexV2_searchLogParent__3LHCM {
  position: relative;
  width: 50%;
}
.indexV2_searchLogIcon__7rywW {
  background: url(/static/media/search.e4c66dbc.svg);
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 12px 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}
.indexV2_filterlogs__1QBwW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fcfcfc;
  border-bottom: 0.8px solid #f1f3f8;
}

.indexV2_col_xs_4_custom_1__2aApB {
  width: 31.3333%;
}
.indexV2_col_xs_4_custom_2__c0ldS {
  width: 35.3333%;
}
.indexV2_col_xs_8_custom__b3EBE {
  width: 67.3333%;
}

.indexV2_checkboxWrapper__POhlm {
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-right: 15px;
}
.indexV2_checkboxContainerLabel__2d_nA {
  font-family: "Inter", sans-serif;
  margin: 0px 10px 0px 5px;
}
.indexV2_checkboxContainer__2El7M input[type="checkbox"] {
  position: relative;
  width: 14px;
  height: 14px;
  color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}
.indexV2_checkboxContainer__2El7M input[type="checkbox"]:before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 3px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  opacity: 0;
}
.indexV2_checkboxContainer__2El7M input[type="checkbox"]:checked {
  color: #fff;
  border-color: #eaeaea;
  background: #101010;
}
.indexV2_checkboxContainer__2El7M input[type="checkbox"]:checked:before {
  opacity: 1;
}
.indexV2_checkboxContainer__2El7M input[type="checkbox"]:checked ~ label:before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.indexV2_commandsLogWrapper__3wCb4 {
  font-size: 12px;
  overflow-y: auto;
}

@media only screen and (max-width: 1024px) {

  .indexV2_commandsLogWrapper__3wCb4 {
    min-height: 200px;
  }
}

/* .commandsLog:hover .bugBtn,
  .commandsLog:focus-within .bugBtn {
    display: block;
    border: 1px solid #ff6565;
  } */
.indexV2_bugBtn__MTDtG {
  display: flex;
}
.indexV2_commandsLogUrlText__3spwz {
  color: #4a4a4a;
  font-family: Space Mono;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.indexV2_commandsLogUrlLink__3cRdT {
  color: #666;
  font-family: Space Mono;
  font-size: 10px;
  letter-spacing: normal;
  line-height: 15px;
  word-wrap: break-word;
}

@media only screen and (max-width: 1024px) {
  .indexV2_commandsLogUrlLink__3cRdT,
  .indexV2_commandsLogUrlText__3spwz {
    margin-left: 10px;
  }
}

.indexV2_playVideo__3dcx4 {
  display: flex;
  justify-content: space-between;
}
.indexV2_playVideoIcon__RsTFV {
  align-items: center;
  background: rgba(246, 248, 252, 0.85);
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  padding: 5px 7px;
}
.indexV2_activePlayVideoIcon__1bSF- {
  background: #2f80ed;
  color: #fff;
}
.indexV2_playIconImg__1Kk1e {
  margin-right: 3px;
}

.indexV2_markAsBugBtn__2q-0R {
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
  background-color: #0ebac5;
  padding: 5px;
  border-radius: 2px;
}

.indexV2_testListHeader__3pUMQ {
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.indexV2_testListHeader__3pUMQ > div {
  padding: 15px 7px;
}
.indexV2_testListBuildStatusBar__1L3pQ {
  margin-top: 15px;
}
.indexV2_testListBuildName__31FZg {
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: #4a4a4a;
}
span[role="img"].indexV2_lambdaErrorText__lwC_0 {
  color: #ff6565;
}
span[role="img"].indexV2_error__3x7Ee {
  color: #f5a623;
}
span[role="img"].indexV2_failed__1n8Dp {
  color: #ff6565;
}
span[role="img"].indexV2_timeout__5KNnd {
  color: #4768fd;
}
span[role="img"].indexV2_greyedOut__2kGzc {
  color: #e0d8d8;
}
.indexV2_greyedOut__2kGzc {
  color: #e0d8d8;
}
.indexV2_testListBody__1_kap {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4a4a4a;
  align-items: center;
}
.indexV2_testDetailDiv__ZFF2s {
  display: flex;
}
.indexV2_selectTestCheckbox__8j4a1 {
  margin: 0px !important;
}
.indexV2_testDuration__2Y-f2 {
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #9b9b9b;
}
.indexV2_tagName__34k_l {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #ebf5fc;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  margin-right: 10px;
}
.indexV2_cypress_suite_title__24KJ8 {
  background-color: #eaeaea;
  color: #4a4a4a;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-between;
  padding: 10px;
}
.indexV2_logsLeftDiv__2GkrH {
  border-right: 1px solid #d0c9d6;
}
.indexV2_tagNameMore__1-HW4 {
  padding: 5px 10px;
  border-radius: 100px;
  background-color: #fff;
  display: inline-block;
  color: #57606d;
  line-height: 1;
  font-size: 10px;
  border: 1px solid #e2e2e2;
  margin-right: 10px;
}
.indexV2_net_tab__1E064 {
  border-bottom: unset;
  margin: 15px 10px;
  position: relative;
}
.indexV2_net_tab2__2R2yH {
  border-bottom: unset;
  margin: 15px 10px;
  background-color: green;
}
.indexV2_rawLogsTab__1F5mB {
  margin-top: 10px;
}
.indexV2_networkLogTable__24hGl {
  color: #4a4a4a;
}
.indexV2_networkLogTable__24hGl th {
  border: unset;
}
.indexV2_networkLogTable__24hGl td {
  border: unset;
}
.indexV2_download_report__2NPVY {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
  width: 100%;
}

.indexV2_download_report__2NPVY span {
  background-color: #f1f2f4;
  color: #4a4a4a;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 8px 10px 5px 10px;
  text-align: right;
  width: 100px;
  cursor: pointer;
}
.indexV2_performance__3I6WV {
  height: 600px;
  overflow: auto;
}
.indexV2_panelBox__38Jar {
  position: relative;
  padding-left: 35px;
}
.indexV2_panelItem__XjprO {
  border: none;
  border-top: 0.1px solid #eaeaea;
  border-radius: 0px;
  box-shadow: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.indexV2_panelItem__XjprO :hover {
  background: #f9f9fa;
}

.indexV2_viewCheckbox__1kkft input {
  top: -2px !important;
}

.indexV2_statusIcon__2hf1X {
  position: absolute;
  right: 30px;
  margin-top: 5px;
}

.indexV2_videoComponent__3vK4Q {
  direction: rtl;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  /* margin-top: 4px; */
  position: relative;
  /* margin-top: 25px; */
  position: relative;
  /* height: 170px; */
}

.indexV2_videoComponent__3vK4Q > div:nth-child(1) {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow: hidden;
}

.indexV2_videoComponent__3vK4Q > div.indexV2_testMenu__2CM6Q {
  padding: 2px 10px;
}
.indexV2_videoComponent__3vK4Q > div {
  width: 100%;
  height: 270px !important;
}
.indexV2_modalClass__3AjYa div[role="document"] {
  width: 324px;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  margin: 1% auto 0;
  top: 200px;
}
.indexV2_shareBuildFormField__379Og {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.indexV2_shareModal__18akg div[role="document"] {
  width: 500px !important;
  top: 150px !important;
}
.indexV2_headerModal__3zNQI img {
  /* float: right;
      cursor: pointer; */
  /* position: absolute;
      right: 25px; */
}
.indexV2_headerModal__3zNQI {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.indexV2_headerModal__3zNQI h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.indexV2_share_done_btn__3d--l {
  border-radius: 5px;
  background-color: #0ebac5;
  color: #fff;
}
.indexV2_share_done_btn__3d--l:hover {
  background-color: #0ebac5;
}
.indexV2_share_cancel_btn__2Jf8L {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4a4a4a;
  padding: 10px;
  cursor: pointer;
}
.indexV2_shareableLink__38aST {
  border-radius: 2px;
  border: solid 1px #eaeaea;
  background-color: #fafafa;
  text-align: center;
  padding: 6px 0px;
  cursor: pointer;
}

.indexV2_shareableLink__38aST span {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 6px;
}
.indexV2_shareTestForm__3CF30 {
  margin-top: 20px;
  letter-spacing: 0.41px;
  color: #4a4a4a;
  font-size: 12px;
}
.indexV2_expiry_drop_down__1511X {
  font-size: 12px !important;
}
.indexV2_errorText__395x8 {
  color: #ff0000;
  font-size: 11px;
}
.indexV2_shareLink__r7owe {
  font-size: 12px;
}
.indexV2_copyBtn__24qUb {
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
}
.indexV2_copied__ivg4u {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 16px;
  background-color: #86d819;
  border-radius: 10px;
  padding: 2px;
}

.indexV2_actionContent__1_5yN {
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  font-size: 15px;
  margin-top: 15px;
}
.indexV2_actionButtons__28QmC {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.indexV2_actionButtons__28QmC button {
  padding: 9px 38px 9px 40px;
  border-radius: 4px;
  margin-top: 20px;
}
.indexV2_cancelBtn__1Cawy {
  border: solid 0.5px #000000;
  background-color: #fff;
  color: #111;
  text-align: center;
}
.indexV2_cancelBtn__1Cawy:hover {
  background-color: #0ebac5;
}
.indexV2_deleteBtn__3Hw67:hover {
  background-color: #ff6565;
  border: unset;
}
.indexV2_deleteBtn__3Hw67 {
  background-color: #ff6565;
  color: #ffffff;
  text-align: center;
  border: unset;
}
.indexV2_headerModal__3zNQI img {
  /* float: right;
      cursor: pointer; */
}
.indexV2_headerModal__3zNQI h4 {
  font-size: 18px;
  letter-spacing: 0.65px;
  color: #4a4a4a;
}
.indexV2_commandsLog__2gK0j div[role="document"] {
  width: 1000px !important;
  top: 50px !important;
}
.indexV2_modalResponseHeader__16qiy {
  letter-spacing: 0.35px;
  color: #000000;
  font-size: 14px;
  margin: 15px 0px;
}
.indexV2_metaInfoModal__2oFzI div[role="document"] {
  width: 90% !important;
  top: 50px !important;
  max-width: 1100px;
}
.indexV2_actionWrapperLogImage__2cTDK {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-top: 1px solid #d0c9d6;
}
.indexV2_actionWrapperLogImage__2cTDK .indexV2_actiondiv__1jssU {
  display: flex !important;
  align-items: center !important;
}
.indexV2_markAsBugBtnCmdLog__264Vl {
  color: #fff;
  background-color: #ff6565;
  font-size: 12px;
  padding: 6px 15px;
}
.indexV2_markAsBugBtnCmdLog__264Vl svg {
  margin-right: 5px;
}
.indexV2_markAsBugBtnCmdLog__264Vl:hover {
  color: #fff;
  background-color: #ff6565;
}
.indexV2_testSuitsTab__2ouN_ {
  position: relative;
  top: -40px;
  left: 10px;
}
.indexV2_testSuitsTab__2ouN_ span {
  display: inline-block;
  padding: 5px;
  background-color: #eaeaea;
  margin-right: 5px;
  cursor: pointer;
}
.indexV2_saveBtn__3epZJ {
  color: #fff;
  background-color: #4a4a4a;
  font-size: 12px;
  padding: 6px 15px;
}
.indexV2_cmdLogsIconsHover__2V9zy {
  display: none;
  cursor: pointer;
}
.indexV2_commandsLog__2gK0j:hover .indexV2_cmdLogsIconsHover__2V9zy {
  display: unset !important;
}
.indexV2_commandsLog__2gK0j:hover .indexV2_cmdLogsIcons__3bY2B {
  display: none;
}

.indexV2_metaInfoHeader__1tFUE {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.indexV2_metaInfoHeader2__34sxB {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
.indexV2_metaInfoModalColumn__24J7m {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px 0 2px 2px;
  font-weight: 500;
  width: 15vw;
}
.indexV2_metaInfoModalValue__sM0_c {
  color: #4a4a4a;
  line-height: 2;
  padding: 2px;
  position: relative;
  padding-left: 30px;
  padding-right: 15px;
  width: 35vw;
}
.indexV2_copyIconWrapper__1vwKn {
  position: absolute;
  top: 5px;
  right: 25px;
  z-index: 999;
}
.indexV2_metaDataTableInputConfig__3qo32 {
  max-height: 200px;
  min-height: 50px;
  min-width: 500px;
  overflow: auto;
  font-size: 12px;
  position: relative;
}
.indexV2_loaderLogs__ik-4i {
  background: #2b2b2b;
  height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.indexV2_downloadNeworkLogWrapper__15ytp {
  position: relative;
  top: 4px;
  color: #43bac5;
  float: right;
  padding: 0px 10px;
  z-index: 1;
}
.indexV2_downloadLogDropdown__2CnFz {
  background-color: #43bac5;
  color: #fff;
}
.indexV2_downloadLogDropdownItem__15An3 {
  color: #4a4a4a;
  font-size: 12px;
}
.indexV2_downloadConsoleButton__3BhwL {
  z-index: 9;
  position: absolute;
  top: 4px;
  right: 0px;
  padding: 0px 10px;
}

.indexV2_consoleLogWrapper__3obmX {
  position: relative;
}

.indexV2_commandLogRow__2qgx2 {
  align-items: center;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  min-height: 38px;
  padding: 5px 0;
}

.indexV2_commandLogCdpRow__Weft9 {
  padding: 5px 10px 5px 0;
}

.indexV2_commandLogRow__2qgx2:hover {
  background: #f1f4f9;
}

.indexV2_modalBoxInnerContent__2FXEU {
  justify-content: space-between;
  /* width: 1024px; */
}

.indexV2_customData__2ZvRP {
  justify-content: space-between;
}

.indexV2_flexRow__eXNlX {
  display: flex;
  flex-direction: row;
}

.indexV2_headerRowAlignment__2-2B5 {
  /* justify-content: space-between;
      padding-left: 50px;
      padding-right: 50px; */
}
.indexV2_metaInfoWrapper__3zRue {
  margin: 5px 28px;
}
.indexV2_copyImage__4IyOs {
  height: 13px;
  width: 13px;
  cursor: pointer;
}

.indexV2_copyImagePosition__2yBoz {
  position: relative;
  float: right;
  cursor: pointer;
  z-index: 100;
}
.indexV2_downloadIcon__3s1Qp {
  margin-left: -7px;
  position: fixed;
  margin-top: 2px;
}

.indexV2_noStatusBuildStatusBar__1IBM1 {
  height: 50px;
  justify-content: center;
  display: flex;
  background-color: #fafafa;
  align-items: center;
  border: 1px solid #eaeaea;
}

.indexV2_downloadSvg__Mj2zG {
  position: relative;
  top: 3px;
}
.indexV2_noStatusBuildName__bpLzN {
  letter-spacing: 0.29px;
  color: #000000;
  font-size: 10px;
}

.indexV2_helpSupportBox__2I7SM {
  margin-bottom: 5px;
  padding: 4px 7px 5px 5px;
  border-radius: 4px;
  background-color: #ff6565;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.indexV2_flexRow__eXNlX {
  display: flex;
  flex-direction: row;
}

.indexV2_flexColumn__EDKjG {
  display: flex;
  flex-direction: column;
}

.indexV2_chatImage__RxEmv {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.indexV2_supportText__33bSn {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.indexV2_copyIconBox__3EnTy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  opacity: 1.2;
  margin-top: 16px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #bbbbbb;
}

.indexV2_topBarButtons__1z3Q9 {
  justify-content: flex-end;
}

.indexV2_copied__ivg4u {
  position: absolute;
  right: 109px;
  top: 31px;
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
}

.indexV2_copied__ivg4u {
  height: 21px;
  width: 58px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  display: flex;
  background-color: #86d819;
  padding: 6px;
  border-radius: 21px;
  color: #fff;
  position: absolute;
  right: 35px;
  top: 35px;
}

.indexV2_copyImagePosition__2yBoz {
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #bbb;
  text-align: center;
  padding: 2px 5px;
  z-index: 99;
}

.indexV2_screenshotIconsContainer__icUsd {
  display: flex;
  justify-content: flex-end;
  padding-right: 3px;
  grid-gap: 10px;
  gap: 10px;
}

.indexV2_hypertestBtn__3k7An {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #0ebac5;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 128px;
}

.indexV2_loadingHtBtn__s3eG6 {
  color: #0ebac5;
}

.indexV2_loadingHtBtn__s3eG6 > span {
  display: flex;
  justify-content: space-between;
}

.indexV2_actionButton__2SiYy {
  align-items: center;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.indexV2_commandsLog__2gK0j:hover .indexV2_bugBtn__MTDtG,
.indexV2_commandsLog__2gK0j:hover .indexV2_viewParametersBtn__dusm4,
.indexV2_commandsLog__2gK0j:focus-within .indexV2_bugBtn__MTDtG,
.indexV2_commandsLog__2gK0j:focus-within .indexV2_viewParametersBtn__dusm4 {
  display: flex;
}

.indexV2_bugBtn__MTDtG {
  display: none;
}

.indexV2_viewParametersBtn__dusm4 {
  display: none;
}

.indexV2_failedChip__1-IHE {
  background: rgba(255, 226, 219, 0.85);
  color: #fb6637;
}

.indexV2_successChip__Z5g6Z {
  background: #2ab14f;
  color: #fff;
}

.indexV2_successChip__Z5g6Z,
.indexV2_failedChip__1-IHE,
.indexV2_pendingChip__1Xx11 {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  justify-content: center;
  padding: 2px 6px;
  width: 100%;
  word-break: break-all;
}

.indexV2_pendingChip__1Xx11 {
  background: #fafa23;
  color: #997f60;
}

.indexV2_responseParameterContainer__1vH-3 {
  font-size: 14px;
  height: auto;
  max-height: 245px;
  position: relative;
  margin-bottom: 50px;
}

.indexV2_copyIcon__yyCx5 {
  position: absolute;
  right: 15px;
  top: 45px;
}

.indexV2_syntaxHighlighterClass__1jefJ {
  border: none;
  border-radius: 8px;
  font-size: 12px;
  height: 100%;
  line-height: 18px;
  margin: 0;
  margin-bottom: 35px;
  max-height: 220px;
  overflow: scroll;
  padding: 20px !important;
  word-wrap: initial !important;
  word-break: initial !important;
}

.indexV2_statusIconContainer__rff2s {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
}

.indexV2_statusSuccess__wSvky {
  border: 1px solid #2ab14f;
}

.indexV2_statusFailed__5IW0X {
  border: 1px solid #f45c47;
}

.indexV2_commandLogsContainer__1F6Ow {
  padding: 8px 16px;
  width: 100%;
}

.indexV2_colDiv-1__D3p-x {
  min-width: 5%;
  max-width: 5%;
}

.indexV2_colDiv-2__QQGEw {
  min-width: 12%;
  max-width: 12%;
}

.indexV2_colDiv-3__1GRZE {
  min-width: 15%;
  max-width: 15%;
}
.indexV2_colDiv-4__3xriS {
  min-width: 23%;
  max-width: 23%;
}

.indexV2_colDiv-5__1liUJ {
  min-width: 27%;
  max-width: 27%;
}

.indexV2_colDiv-55__2FBLU {
  min-width: 30%;
  max-width: 30%;
}

.indexV2_colDiv-6__3inUi {
  min-width: 44%;
  max-width: 44%;
}

.indexV2_flex__1Z09s {
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.indexV2_popoverSectionHeading__1yDaC {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
  margin-bottom: 8px;
}

.indexV2_autoHealContainer__2-47G {
  padding: 1px;
  display: flex;
}

.indexV2_copyHealedParam__FQov0 {
  margin-top: 2px;
}

.indexV2_usingSelector__3lDoA {
  border: 1px solid #ddd;
  border-radius: 100px;
  display: flex;
  padding: 1px 7px;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.indexV2_originalSelectorSection__2ZegD {
  margin-top: 15px;
}

.indexV2_commandLogsDurationContainer__HEE6T {
  min-width: 40px;
}

.indexV2_healedValueContainer__2YWPl,
.indexV2_actualValueContainer__3EbZ2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.indexV2_healedValueContainer__2YWPl {
  color: #176f2c;
}

.indexV2_actualValueContainer__3EbZ2 {
  color: #cf370e;
}

.indexV2_healedValue__-f7kV,
.indexV2_actualValue__Q95Bs {
  margin-left: 8px;
  word-break: break-word;
}

.indexV2_popoverRow__322fT {
  display: flex;
  justify-content: space-between;
}

.indexV2_healedSectionHeading__5zmOb {
  padding-top: 0;
}

.indexV2_annotationText__3cdap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.indexV2_listWrapper__1thx3 {
  list-style: none;
  padding: 0;
}

.indexV2_noListStyle__5uzxl {
  list-style: none;
  padding: 0;
}

.indexV2_listWrapper__1thx3:last-child,
.indexV2_noListStyle__5uzxl:last-child {
  border-bottom: 1px solid #d4d4d4;
}

.indexV2_commandLogHeader__31a3J {
  align-items: center;
  border: 1px solid;
  overflow: hidden;
  border-bottom: none;
  display: flex;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1024px) {

  .indexV2_commandLogHeader__31a3J {
    overflow: auto;
  }
}

.indexV2_commandLogHeader__31a3J:hover {
  background: rgba(221, 221, 221, 0.2);
}

/* .commandLogHeader:hover .collapseExpandCommandLog svg path {
    stroke: #333;
  } */

.indexV2_commandLogHeaderFirst__3LIIG {
  border-top: none;
}

/* .commandLogHeaderError {
    border-left: 1.25px solid #cf222e !important;
    background-color: #fdf4f5;
  } */

.indexV2_commandLogHeaderError__3KEsV:hover {
  background-color: #ffebe9 !important;
}

.indexV2_commandLogHeaderSuccess__1lbNz {
  /* border-left: 1.25px solid #176f2c !important; */
  border-top: 1px solid;
  border-bottom: 1px solid !important;
  border-color: #a0e4b2 !important;
  background-color: #f0f0f0;
}

.indexV2_commandLogHeaderPending__27yw5 {
  /* border-left: 1px solid #bf8700 !important; */
}

.indexV2_commandLogTitleSection__1z3M3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0;
  padding: 2px 16px;
  position: relative;
  width: 100%;
}

.indexV2_commandLogTitle__2Kd0e {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-word;
}

.indexV2_commandLogTitle__2Kd0e h3 {
  font-size: 12px;
  margin-bottom: 0;
}

.indexV2_commandLogDuration__3bohn,
.indexV2_commandLogTimestamp__2_XK4 {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  grid-gap: 4px;
  gap: 4px;
  line-height: 18px;
}

.indexV2_dot__3We12 {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #888;
}

.indexV2_commandLogSecondaryInfo__34QMP {
  align-items: center;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-left: 16px;
}

@media only screen and (max-width: 1024px) {

  .indexV2_commandLogSecondaryInfo__34QMP {
    height: 100%;
  }
}

.indexV2_commandLogResponseStatus__iLT6H {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.indexV2_commandLogTitleAction__1ohf2 {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
}

.indexV2_errorRemark__1Eoar,
.indexV2_commandLogRemark__LXoCR,
.indexV2_annotationBar__22jOo {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  grid-gap: 16px;
  gap: 16px;
  line-height: 20px;
  padding: 8px 16px;
  width: 100%;
}

.indexV2_errorRemark__1Eoar {
  background: #fdf4f5;
  border-left: 1.25px solid #cf222e;
}

.indexV2_annotationBar-warn__Feq66,
.indexV2_annotationBar-debug__2rjB4 {
  border-top: 1px solid rgba(212, 167, 44, 0.4);
  border-bottom: 1px solid rgba(212, 167, 44, 0.4);
  background: #fff8c5;
}

.indexV2_annotationBar-error__36k0d {
  border-top: 1px solid rgba(255, 129, 130, 0.4);
  border-bottom: 1px solid rgba(255, 129, 130, 0.4);
  background-color: #ffebe9;
}

.indexV2_commandLogRemark__LXoCR {
  border-left: 1.5px solid #cf222e;
  margin-right: 12px;
  padding: 0px;
  margin-left: 24px;
  padding-left: 4px;
}

.indexV2_cypressCodeBlockWrapper__9xGXJ {
  border: 1px solid #ddd;
  border-bottom: none;
  border-top: none;
}

.indexV2_cypressCodeBlockWrapper__9xGXJ:last-child {
  border-bottom: 1px solid #ddd;
}

.indexV2_commandLogHeaderError__3KEsV ~ .indexV2_commandLogRemark__LXoCR,
.indexV2_commandLogHeaderError__3KEsV ~ .indexV2_cypressCodeBlockWrapper__9xGXJ,
.indexV2_commandLogHeaderError__3KEsV .indexV2_errorRemark__1Eoar,
.indexV2_commandLogHeaderError__3KEsV .indexV2_annotationBar-info__1cCHk,
.indexV2_commandLogHeaderError__3KEsV .indexV2_annotationBar-error__36k0d,
.indexV2_commandLogHeaderError__3KEsV .indexV2_annotationBar-debug__2rjB4 {
  /* border-left: 1.25px solid rgb(207, 34, 46); */
  border-right: 1px solid #ddd;
}

.indexV2_commandLogHeaderSuccess__1lbNz ~ .indexV2_commandLogRemark__LXoCR,
.indexV2_commandLogHeaderSuccess__1lbNz ~ .indexV2_cypressCodeBlockWrapper__9xGXJ,
.indexV2_commandLogHeaderSuccess__1lbNz .indexV2_errorRemark__1Eoar,
.indexV2_commandLogHeaderSuccess__1lbNz .indexV2_annotationBar-info__1cCHk,
.indexV2_commandLogHeaderSuccess__1lbNz .indexV2_annotationBar-error__36k0d,
.indexV2_commandLogHeaderSuccess__1lbNz .indexV2_annotationBar-debug__2rjB4 {
  /* border-left: 1.25px solid #176f2c; */
  border-right: 1px solid #ddd;
}

.indexV2_commandLogHeaderPending__27yw5 ~ .indexV2_cypressCodeBlockWrapper__9xGXJ {
  border-left: 1px solid #bf8700;
  border-right: 1px solid #ddd;
}
.indexV2_loader__2YBGw {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.indexV2_flakyTestHeader__INRFQ {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  font-weight: 600;
  align-items: center;
}

.indexV2_suiteTitle__1hn2A {
  align-items: center;
  align-self: stretch;
  background: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  grid-gap: 8px;
  gap: 8px;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 8px 16px;
}

.indexV2_specExecutionTime__1kum4 {
  color: #666;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.15px;
}

.indexV2_actionWrapper__bTfob {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.indexV2_actionBar__1m6p2 {
  display: flex;
}

.indexV2_cypressCodeBlock__2-Dx8 {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 16px;
}

.indexV2_cypressCodeBlockDark__OCCxz {
  background: rgb(40, 44, 52);
}

.indexV2_cypressCodeBlockWrapper__9xGXJ {
  padding: 10px 16px;
}

.indexV2_commandLogActive__VLwmr {
  background-color: rgba(221, 221, 221, 0.4);
}

.indexV2_ExceptionLogActive__1QLj7 {
  background-color: #ffebe9;
}

.indexV2_collapseExpandCommandLog__Nz4qd {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex: 0 1;
  grid-gap: 8px;
  gap: 8px;
  grid-gap: 8px;
  justify-content: center;
  margin: 10px 8px;
}

.indexV2_collapseExpandCommandLog__Nz4qd:focus {
  outline-color: #0969da;
}

.indexV2_cursorPointer__3XmE0 {
  cursor: pointer;
}

.indexV2_commandLogsPlaceholderBtn__1AaeA {
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  height: 100% !important;
  position: absolute;
  width: 100%;
  left: 0px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.indexV2_commandLogsPlaceholderBtn__1AaeA:hover {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.indexV2_commandLogsPlaceholderBtn__1AaeA.indexV2_focus-visible__Zb6f3 {
  outline: 2px solid #0969da;
}

.indexV2_frameworkExceptionLogContainer__3OgeE {
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding: 10px 16px;
}

@media only screen and (max-width: 1024px) {

  .indexV2_frameworkExceptionLogContainer__3OgeE {
    overflow: auto;
  }
}

.indexV2_copyButton__3PqLs {
  display: none;
}

.indexV2_commandLogTitleSection__1z3M3:hover .indexV2_copyButton__3PqLs {
  display: block;
}

.indexV2_commandScrollButton__33TLe {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.indexV2_arrowDownButton__3bohD {
  display: flex;
  align-items: center;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.EditTestModal_statusChangeForm__1ODAV {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;
}

@media only screen and (max-width: 1024px) {

  .EditTestModal_statusChangeForm__1ODAV {
    overflow: auto;
  }
}

.EditTestModal_formSection__11vKX {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.CopyCode_copyIcon__r9uCp {
  align-items: center;
  cursor: pointer;
  display: flex;
  z-index: 99;
}

.CopyCode_copied__1Qszc {
  background-color: #176f2c;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  padding: 0 5px;
}

.ShareModal_shareBuildFormField__1H7UQ {
  flex-direction: column;
  display: flex;
  margin-bottom: 15px;
  padding: 16px;
  grid-gap: 16px;
  gap: 16px;
}
@media only screen and (max-width: 1024px) {
  .ShareModal_shareBuildFormField__1H7UQ {
    overflow: auto;
  }
}
.ShareModal_sectionHeading__aYhI8 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 4px;
}

.ShareModal_checkIcon__2hI5Y {
  margin-right: 8px;
}

.ShareModal_requiredHeading__24G1z:after {
  content: "*";
}

.ShareModal_emailError__2wRQK {
  align-items: center;
  background: #ffebe9;
  border: 1px rgba(255, 129, 130, 0.4) solid;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  grid-gap: 16px;
  gap: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  padding: 8px 16px;
  width: 100%;
}

.VideoContainer_mainContainer__1DCqt {
  height: 100%;
  position: relative;
}

.VideoContainer_videoComponent__PXf39 {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(66, 74, 83, 0.12);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 1024px) {

  .VideoContainer_videoComponent__PXf39 {
    height: auto;
    aspect-ratio: 2 !important;
  }
}

.VideoContainer_videoAspect__2yas7 {
  aspect-ratio: 4/2.5;
}

.VideoContainer_videoComponent__PXf39 > div:nth-child(1) {
  border-radius: 6px;
  overflow: hidden;
}

.VideoContainer_videoComponent__PXf39 > div.VideoContainer_testMenu__2Cqvh {
  padding: 2px 10px;
}
.VideoContainer_videoComponent__PXf39 > div {
  width: 100%;
}
.VideoContainer_videoCapabiltyHeading__1P83m {
  padding: 7px 23px 7px 8px;
  border-radius: 3px;
  border: solid 1px rgba(224, 57, 57, 0.15);
  background-color: rgba(255, 175, 175, 0.12);
  color: #591111;
  font-size: 12px;
  /* width: 56%; */
}
.VideoContainer_videoCapabiltyMsg__D2YRO {
  line-height: 1.36;
  letter-spacing: normal;
  color: #000000;
  font-size: 11px;
}
.VideoContainer_videoCapabiltyMsg__D2YRO span {
  font-weight: bold;
}
.VideoContainer_videoCapabiltyGeneraterLink__1kJhC {
  color: #000000;
  text-decoration: none;
  border-bottom: 1px solid #000;
  cursor: pointer;
}
.VideoContainer_videoCapabiltyGeneraterLink__1kJhC:hover {
  color: #000;
  text-decoration: none;
}

.VideoContainer_liveInteractionBtn__3tGp9 {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 26px;
  line-height: 12px;
  padding: 7px 7px 7px 0px;
  position: absolute;
  right: 18px;
  top: 11px;
  width: auto;
  z-index: 98;
}

.VideoContainer_messageContainer__gDv2o {
  align-items: center;
  background: #333;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  max-height: 100%;
  aspect-ratio: 4/2.5;
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
}
.VideoContainer_videoCapablityFalseMessageWrapper__nJ5Dw {
  background-color: #ffffff;
  text-align: center;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VideoContainer_messageHeading__2Elmb {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 20px;
  text-align: center;
  padding: 0px 8px;
}
.VideoContainer_messageDescription__11izx {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.VideoContainer_learnMore__2N6Uz {
  color: #ffffff;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-decoration: underline !important;
  cursor: pointer;
}
.VideoContainer_learnMore__2N6Uz:hover {
  color: #fff;
}
.VideoContainer_learnMore__2N6Uz:focus {
  outline: 2px solid #2f81f7;
}
.VideoContainer_iconVideoNot__kela5 {
  display: flex;
}
.VideoContainer_pauseBtn__7pzg_ {
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 12px;
}
.VideoContainer_realTimeStart__2nLdf {
  padding: 6px 7px;
  cursor: pointer;
  background-color: #43bac5;
  width: 138px;
  text-transform: uppercase;
  color: #fff;
  margin-right: 5px;
  /* min-width: 200px; */
  display: inline-flex;
}
.VideoContainer_breakButton__2wjIn {
  cursor: pointer;
  background-color: #43bac5;
  color: #fff;
  margin-right: 5px;
  font-size: 12px;
  padding: 14px 27px;
}
.VideoContainer_messageContainerRealMobile__d8k69 {
  align-items: center;
  background-color: #333;
  border-radius: 1.2px;
  border: solid 4.9px #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 15px;
  text-align: center !important;
}
.VideoContainer_contentContainerRealMobile__2Z45U {
  text-align: left;
  margin-left: 20px;
}
.VideoContainer_messageContainerRealMobile__d8k69 .VideoContainer_messageHeading__2Elmb:after {
  content: " .";
  -webkit-animation: VideoContainer_dots__2u80K 1s steps(5, end) infinite;
          animation: VideoContainer_dots__2u80K 1s steps(5, end) infinite;
}

.VideoContainer_errorMsgWrapper__WwMHb {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

@-webkit-keyframes VideoContainer_dots__2u80K {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes VideoContainer_dots__2u80K {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.VideoContainer_lottieContainer__dhMo- {
  display: flex;
  padding-top: 9px;
}

.VideoContainer_liveStreamWrapper__SZhkW {
  aspect-ratio: 4/2.5;
  display: flex;
  justify-content: center;
  max-height: 100%;
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 320px) {

  .VideoContainer_liveStreamWrapper__SZhkW {
    min-height: 200px;
  }
}

.VideoContainer_streamLoadingContainer__1z2ND {
  display: flex;
  align-items: center;
  height: 100%;
}

.VideoContainer_loadingBorder__2nMie {
  border: 1px solid #d4d4d4;
  border-radius: 4px;
}

.ArtifectRetentionPolicyMsg_Artifectretentionpolicymsg__2si96 {
  display: block;
}

.ArtifectRetentionPolicyMsg_messageContainer__2p-Cr {
  border-radius: 1.2px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
  border: solid 4.9px #ffffff;
  background-color: #333;
  text-align: center;
  padding: 22px;
}
.ArtifectRetentionPolicyMsg_messageHeading__3qpol {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 20px;
  text-align: center;
}
.ArtifectRetentionPolicyMsg_messageDescription__lAKwL {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
.ArtifectRetentionPolicyMsg_contactSupport__1nqAd {
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
  border: solid 1px #50e3c2;
  width: 130px;
  padding: 6px 16px;
  margin-top: 34px;
}
.ArtifectRetentionPolicyMsg_contactSupport__1nqAd:hover {
  background: #50e3c2;
}
.ArtifectRetentionPolicyMsg_contactSupportLink__644Wg {
  text-decoration: none !important;
  color: #ffffff !important;
}

.ArtifectRetentionPolicyMsg_anchorTagStyle__1y2Dh {
  color: white !important;
  text-decoration: none !important;
}

.TestDetailInfo_testDetailInfoContainer__38ltQ {
  max-height: 100%;
  padding: 16px 16px 8px 16px;
  /* @media only screen and (max-width: 1024px) {
    height: auto;
  } */
}

.TestDetailInfo_screenshotContainer__3jFjQ {
  cursor: pointer;
  /* height: 100%; */
  /* aspect-ratio: 4/2.5; */
  width: 100%;
}

@media only screen and (max-width: 1024px) {

  .TestDetailInfo_screenshotContainer__3jFjQ {
    height: auto;
    aspect-ratio: 2;
  }
}
.TestDetailInfo_screenshotPrimerContainer__1Go-O {
  aspect-ratio: 4/2.5;
}

.TestDetailInfo_borderedContainer__36dqY {
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(66, 74, 83, 0.12);
  position: relative;
  overflow: hidden;
}

.TestDetailInfo_downloadScreenshotWrapper__1CGFV {
  display: flex;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.TestDetailInfo_borderedContainer__36dqY .TestDetailInfo_screenshotOverlay__3Do_I {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
}

.TestDetailInfo_borderedContainer__36dqY:not(.TestDetailInfo_loadingScreenshot__1CSST):hover .TestDetailInfo_screenshotOverlay__3Do_I,
.TestDetailInfo_borderedContainer__36dqY:not(.TestDetailInfo_errorScreenshot__1K2bb):hover .TestDetailInfo_screenshotOverlay__3Do_I {
  display: block;
}

.TestDetailInfo_playIcon__1pk8l {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.TestDetailInfo_screenshotActionBtns__2Zymn {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px 16px 20px;
  align-items: center;
}

.TestDetailInfo_imgModal__1DE36 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.TestDetailInfo_imgModalContent__29oik {
  height: 90%;
  width: 90%;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.TestDetailInfo_imgModal__1DE36 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.TestDetailInfo_screenshotHelpText__3pjg9 {
  align-items: center;
  color: #666;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  grid-gap: 2px;
  gap: 2px;
  line-height: 18px;
}

.TestDetailInfo_linkContainer__2gDK0 {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.TestDetailInfo_videoHeader__3KHza {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.TestDetailInfo_linkModalContainer__3-7fc {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  padding: 16px;
}

.TestDetailInfo_searchResults__3iNAS {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 300px;
}

.TestDetailInfo_testResult__2VERE {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 6px;
}

.TestDetailInfo_testResult__2VERE:hover {
  background-color: #f7f7f7;
}
.NoScreenshot_title__10YA3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.NoScreenshot_noScreenshotWrapper__1ok1L {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.NoScreenshot_flex__G-AVg {
  display: flex;
  justify-content: center;
}

.NoScreenshot_btnWrapper__10aBV {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.NoScreenshot_description__1-O59 {
  color: #666;
}
.AutomationLogsDetailWrapper_testListWrapper__1Pq1z {
  flex: 3 1;
  border-right: 1px solid #eaeaea;
  max-width: 25%;
}
.AutomationLogsDetailWrapper_hideTestList__27NWg {
  flex: 3 1;
  border-right: 1px solid #eaeaea;
  max-width: 0%;
}
.AutomationLogsDetailWrapper_testDetailInfoWrapper__2dLEK {
  background-color: #fafcff;
  height: 100%;
  width: 50%;
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.AutomationLogsDetailWrapper_testDetailWrapper__1h3zN {
  flex: 4 1;
}
.AutomationLogsDetailWrapper_testDetailLargeWrapper__3hg1a {
  flex: 7 1;
}
.AutomationLogsDetailWrapper_testDetailPageWrapper__2P8dj {
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .AutomationLogsDetailWrapper_testDetailPageWrapper__2P8dj {
    overflow: unset;
  }
}

.AutomationLogsDetailWrapper_testDetailPageWrapper__2P8dj > img {
  align-self: center;
  width: 100%;
}

.AutomationLogsDetailWrapper_testDetailPageWrapperShared__1OvFo {
  padding-right: 15px;
  width: 100vw;
}

@media only screen and (max-width: 1024px) {
  .AutomationLogsDetailWrapper_testDetailPageWrapperShared__1OvFo {
    padding-right: 0px;
  }
}

.AutomationLogsDetailWrapper_wider__Bs2BV {
  display: flex;
  flex: 1 1;
  width: 50%;
}

.AutomationLogsDetailWrapper_fullscreenStream__17-ie {
  z-index: 1000;
  border-radius: 4px;
}
.AutomationLogsDetailWrapper_fullscreenStream__17-ie .AutomationLogsDetailWrapper_close__2ESEn {
  float: right;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 3px;
  margin-right: 8px;
}
.AutomationLogsDetailWrapper_fullscreenStream__17-ie .AutomationLogsDetailWrapper_footer__2OiAJ {
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.2px;
  padding: 4px;
  margin-left: 13px;
  margin-top: -11px;
  padding: 2px;
}

.AutomationLogsDetailWrapper_fullscreenStream__17-ie .AutomationLogsDetailWrapper_liveStreamWrapper__NfA5_ {
  padding: 10px;
  margin-top: 15px;
  min-height: 494px;
  min-width: 1000px;
}
.AutomationLogsDetailWrapper_stopButtonDivFullscreen__1HqXE {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}
.AutomationLogsDetailWrapper_runningImage__2ipg8 {
  margin-left: 5px;
  max-width: 54px;
}
.AutomationLogsDetailWrapper_breakButton__2WQd5 {
  cursor: pointer;
  background-color: #ffbb36;
  color: #fff;
  margin-right: 5px;
  font-size: 15px;
  padding: 10px 9px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 6px;
}
.AutomationLogsDetailWrapper_backToTestView__q3srk {
  cursor: pointer;
  background-color: #3e9cf2;
  color: #fff;
  margin-right: 5px;
  font-size: 15px;
  padding: 10px 9px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 4px;
}
.AutomationLogsDetailWrapper_abortImage__3H3DI {
  margin-right: 5px;
}

.AutomationLogsDetailWrapper_chatSupportBtn__3j2LN {
  color: #3366ff;
  cursor: pointer;
  margin-left: 5px;
}

.AutomationLogsDetailWrapper_customError__1GpwL {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 0.82;
  position: absolute;
  text-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  top: 40%;
}

.automationLogsPage .form-lable {
  padding-left: 0px;
  text-align: right;
  padding-right: 0px;
  padding-top: 5px;
  font-weight: 400;
  font-size: 13px;
}

.automationLogsPage .multiSelect-option2 button.css-1r4vtzz,
.automationLogsPage .multiSelect-option2 button.css-48ayfv {
  padding: 3px 6px 3px 6px;
  font-size: 12px;
  border-radius: 30px;
  width: 100% !important;
  background-color: #ffffff;
  box-shadow: none;
}
.automationTimelinePage .multiSelect-option2 button.css-1r4vtzz > span,
.automationTimelinePage .multiSelect-option2 button.css-48ayfv > span {
  padding-right: 0px !important;
}
.automationLogsPage .css-ik6y5r {
  box-shadow: 0 2px 4px 0 #eaeaea;
  border: solid 1px #eaeaea;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  background: #ffffff;
  width: 180px;
}

.automationLogsPage .css-8ywtop {
  padding: 2px 3px;
  width: 100% !important;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 12px;
}

.automationLogsPage .multiSelect-option2 > div > div:first-of-type {
  z-index: 11 !important;
}
.automationLogsPage .multiSelect-option2 > div > div:last-of-type {
  z-index: 10 !important;
}

.MaintenancePage_maintenancePageContainer__1mRsw {
  text-align: center;
  margin-top: 100px;
}

.MaintenancePage_heading__1E0jJ {
  color: #333;
  font-size: 22px;
  font-weight: 700;
}

.MaintenancePage_subHeading__17BSo {
  color: #666;
  font-size: 12px;
  font-weight: 400;
}

.MaintenancePage_imageWrapper__335QP {
  display: flex;
  justify-content: center;
}

.MaintenancePage_additionalInfoWrapper__2O_6o {
  display: flex;
  margin: 50px 10px 30px 10px;
  justify-content: center;
}

.MaintenancePage_additionalInfoBlock__1cE8l {
  max-width: 250px;
}

.MaintenancePage_additionalInfoLabel__hbJmS {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  height: 44px;
}

.MaintenancePage_additionalInfoBlock__1cE8l {
  flex: 1 1;
  margin-right: 10px;
}

.MaintenancePage_additionalInfoLink__i6stF {
  color: #3366ff;
  cursor: pointer;
  margin-top: 5px;
}

.MaintenancePage_additionalInfoLink__i6stF a,
  .MaintenancePage_additionalInfoLink__i6stF a:hover,
  .MaintenancePage_additionalInfoLink__i6stF a:visited {
    color: #3366ff;
  }

.LTTestHeaderV2_buildName__2Ctze {
  margin: 2px;
}
.LTTestHeaderV2_buildName__2Ctze:hover > span {
  color: #0969da;
  text-decoration: underline;
  cursor: pointer;
}
.LTTestHeaderV2_buildName__2Ctze:focus {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  border-radius: 4px;
}

.LTTestHeaderV2_noListStyle__3tjXK {
  list-style: none;
  padding: 0;
}

.DrawerHeader_ltDrawerHeader__2mXhY {
  align-items: center;
  background-color: #fafcff;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  height: 51px;
  max-height: 51px;
  min-width: 100%;
  padding: 12px
}

.DrawerHeader_ltDrawerHeader__2mXhY > div:first-child {
  width: 20%
}

.DrawerHeader_ltDrawerHeader__2mXhY > div:first-child > div:first-child {
  width: 100%;
}

.DrawerHeader_ltSearchBox__1YASz {
  align-items: center;
  display: flex;
  width: 40%;
}

.DrawerHeader_ltDrawerInputBoxContainer__3q2TA {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  display: flex;
  height: 28px;
  padding-left: 11px;
  padding-right: 11px;
  position: relative;
  width: 100%;
}

.DrawerHeader_ltDrawerInput__Iz7Xy {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: #333333;
  font-size: 10px;
  font-weight: normal;
  line-height: 12.1px;
  outline: none;
  padding-left: 8px;
  position: relative;
  width: 90%;
}

::-webkit-input-placeholder {
  color: #c7c7c7;
  font-size: 10px;
}

::placeholder {
  color: #c7c7c7;
  font-size: 10px;
}

.DrawerHeader_ltButtonDefault__xavYi {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  color: #666666;
  display: flex;
  font-size: 10px;
  font-weight: normal;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 28px;
  justify-items: center;
  line-height: 12px;
  outline: none;
  position: relative;
}

.DrawerHeader_ltButton__1sqPd {
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  width: 100%;
}

.DrawerHeader_ltButtonLink__3GSUi {
  background-color: transparent;
  border: none;
  width: auto;
}

.DrawerHeader_ltButtonIcon__2LR5w {
  background-color: #fff;
  border-radius: 4px;
  border: 0.8px solid #dfe6ef;
  position: relative;
  width: auto;
}

.DrawerHeader_ltBadgeClasses__1nopl {
  align-items: center;
  background-color: #0ebac5;
  border-color: #0ebac5;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 18%;
  top: 4px;
  width: 16px;
}

.DrawerHeader_ltBadgeActive__2_o0Z {
  align-items: center;
  background-color: #0ebac5;
  border-color: #0ebac5;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  color: #fff;
  display: flex;
  font-size: 8px;
  font-weight: 500;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 33%;
  top: 4px;
  width: 16px;
}

.DrawerHeader_ltPrimaryText__1Re6q {
  color: #0ebac5;
}

.DrawerHeader_crossIcon__914tu {
  bottom: 6px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  padding: 2px;
  position: absolute;
  right: 5px;
  width: 14px;
  z-index: 9;
}

.DrawerHeader_checkboxContainer__3s0qy {
  align-items: center;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: #dfe6ef;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  grid-gap: 8px;
  gap: 8px;
  line-height: 20px;
  min-width: 150px;
  position: relative;
  padding: 8px;
  width: auto;
}
.DrawerHeader_ltCloseButton__1PNhy {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  align-items: center;
  appearance: none;
  color: #0ebac5;
  display: flex;
  font-size: 10px;
  font-weight: normal;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 28px;
  justify-items: center;
  line-height: 12px;
  outline: none;
  position: relative;
}

.DrawerHeader_ltButtonDefault__xavYi:focus,
.DrawerHeader_ltSearchBox__1YASz:focus {
  outline: 1px solid #333;
}

.DrawerHeader_ltDateButton__3a1os {
  min-width: 180px !important;
}

.FlakyTestGroups_wrapper__28nGQ {
  height: calc(100vh - 70px);
  overflow-x: hidden;
}

.FlakyTestGroups_headerContainer__1gj3a {
  align-items: center;
  border-bottom: 0.8px solid #F1F3F8;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: space-between;
  line-height: 17px;
  padding: 13px 13px 13px 18px;
}

.FlakyTestGroups_testCounts__27G77 {
  font-size: '12px';
  font-weight: 500;
}
.Tag_buildStatus__1PTMy {
  align-items: center;
  border-radius: 2px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  grid-gap: 3px;
  gap: 3px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 11px;
  margin: 0px 5px;
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Tag_selectedTestTag__1gXT- {
  background: #fff;
  border: 1px solid #2684ff;
  color: #2684ff;
}

.Tag_cursorPointer__wpp_Z {
  cursor: pointer;
}

.Tag_boxGreen__3Jv6z {
  background: #176f2c;
}
.Tag_boxCyanGreen__2xALm {
  background: #037f78;
}
.Tag_boxRed__1SOjf {
  background: #cf222e;
}
.Tag_boxDarkOrange__ZXnns {
  background: #bc4c00;
}
.Tag_boxCyan__2J6K1 {
  background-color: #087493;
}
.Tag_boxPink__30HiS {
  background-color: #ffadad;
  color: #101010;
}
.Tag_boxOrange__20J1C {
  background: #c24e00;
}
.Tag_boxYellow__3tL2u {
  background-color: #fae17d;
  color: #101010;
}

.Tag_boxDarkRed__1BKEh {
  background: #a40e26;
}

.Tag_boxDarkPink__2pw9D {
  background-color: #99306f;
}

.Tag_boxGrayLight__3PN9y {
  background: #f7f7f7;
  color: #333;
}

.Tag_boxGrayDark__1s7Ku {
  background: #666;
}

.Tag_boxBlueLight__QWNvk {
  background: #0969da;
}

.Tag_boxBlueDark__35ENp {
  background: #033d8b;
}

.Tag_boxPurple__3Jfbg {
  background: #8250df;
}

.Tag_boxDarkPurple__HgGup {
  background-color: #512a97;
}

.Tag_boxDarkYellow__2fyGw {
  background-color: #ffb302;
}

.Tag_boxFlaky__3RJnJ {
  background: #a04100;
}

.Tag_tagIcon__3swOO {
  margin-right: 4px;
}

.Tag_statuButton__jVXbI {
  align-items: center;
  display: flex;
  justify-content: center;
}

.FlakyTestItem_testItemWrapper__1aCW9 {
  align-items: center;
  border-bottom: 0.8px solid #F1F3F8;
  color: inherit;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 15px;
  grid-gap: 17px;
  gap: 17px;
  padding: 13px;
  text-decoration: none;
}
.FlakyTestItem_content__1uo3Z {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 15px;
  text-align: left;
  text-decoration: none;
}

.FlakyTestItem_browser__1zI5p {
  width: 40%;
  justify-content: left;
}

.FlakyTestItem_os__dFf6C {
  width: 30%;
  justify-content: left;
}

.FlakyTestItem_duration__25NgH {
  width: 5%;
}
.FlakyTestItem_resolution__2mfWK {
  width: 25%;
}

.FlakyTestItem_testItemWrapper__1aCW9:hover {
  background: #fafafa;
  color: inherit;
  text-decoration: none;
}

.FlakyTestItem_testItemWrapper__1aCW9 div {
  align-items: center;
}

.FlakyTestItem_testItemWrapper__1aCW9 div img {
  height: 12px;
  margin-right: 5px;
  width: 12px;
}

.FlakyTestItem_inspect__249fN {
  color: #0EBAC5;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.FlakyTestItem_inspect__249fN:hover {
  color: #0EBAC5;
}

.FlakyTestItem_testConfiguration__3i_el {
  display: flex;
  align-items: center;
  justify-content: center;
}

.FlakyTestItem_flakeRate__1rNNS {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CommandLogFlakiness_wrapper__1luWp {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CommandLogFlakiness_tooltipContainer__17rDO svg {
  margin: 2px;
}

.CommandLogFlakiness_tooManyLogsWrapper__1tvRe {
  background: #FEFAE6;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px;
}

.CommandLogFlakiness_log__F2JcN {
  border: 1px solid #EAEAEA;
  height: 32px;
  margin-right: 2px;
}

.CommandLogFlakiness_log__F2JcN:hover {
  cursor: pointer;
}

.CommandLogFlakiness_logRed__1NIoi {
  background-color: #FF650E;
}

.CommandLogFlakiness_logGreen__2G-ot {
  background-color: #5FC8AF;
}
.TestDetailLogTopHeader_testDetailTopBar__1cp3V {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #747474;
  display: flex;
  font-size: 12px;
  height: 45px;
  letter-spacing: 0.4px;
  padding: 8px 15px;
}

.TestDetailLogTopHeader_testDetailTopBar__1cp3V > span {
  color: #0ebac5;
  cursor: pointer;
}
.TestDetailLogTopHeader_backIconContainer__2GunY {
  align-items: center;
  border: 0.8px solid #dfe6ef;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-right: 11px;
  padding: 6px;
  width: 28px;
}

.TestDetailLogTopHeader_drawerIconContainer__18T5S {
  align-items: center;
  border: 0.8px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-right: 11px;
  min-width: 29px;
  padding: 0px 6px;
  width: auto;
}

.TestDetailLogTopHeader_drawerIconContainer__18T5S > img {
  margin-right: 5px;
}

.TestDetailLogTopHeader_brandLogo__1h7RB {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.TestDetailLogTopHeader_renameModal__FHpHS {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  padding: 16px;
  width: 100%;
}

.TestDetailLogTopHeader_deleteModal__1RYZD {
  padding: 16px;
}

.TestDetailLogTopHeader_buildHeaderActions__rAyQY {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
}

.TestDetailLogTopHeader_remark__3CweX,
.TestDetailLogTopHeader_flakyBar__Ei0tZ {
  align-items: flex-start;
  align-self: stretch;
  color: #333;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 14px;
}

.TestDetailLogTopHeader_flakyBar__Ei0tZ {
  background-color: #ffebe9;
  border: 1px solid rgba(255, 129, 130, 0.4);
  font-weight: 600;
}

.TestDetailLogTopHeader_remark__3CweX {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  background: #ddf4ff;
  border: 1px solid rgba(84, 174, 255, 0.4);
}

.TestDetailLogTopHeader_actionBar__2JDZj {
  display: flex;
}

.TestDetailLogTopHeader_reasonContainer__3tSRh {
  height: calc(100vh - 168px);
  overflow: auto;
}

.FlakyFeedbackModal_info__2_3mz {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.FlakyFeedbackModal_infoTitle__acwVN {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.FlakyFeedbackModal_separator__Rphfe {
  background-color: #d0d7de;
  height: 1px;
  width: 100%;
}

.FlakyFeedbackModal_footer__1m4Ni {
  align-items: center;
  display: flex;
  justify-content: end;
  padding: 16px;
  width: 100%;
}

.FlakyFeedbackModal_error__2iDdn {
  color: #CF222E;
  width: 100%;
}

.FlakyFeedbackModal_reportButton__QqAjC {
  background-color: #000000 !important; 
  border-color: #000000 !important;
  color: #fff !important;
  margin: 0px 4px;
}

.FlakyFeedbackModal_infoMessage__f1s4V {
  color: #333 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.FlakyFeedbackModal_radioButton__37UZI {
  border-color: #000000 !important;
}

.FlakyFeedbackModal_radioLabel__3ke7U {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
}

.FlakyFeedbackModal_testInfo__2H5Wt {
  margin-bottom: 8px;
}

.FlakyFeedbackModal_textField__39toL::-webkit-input-placeholder{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
} 

.FlakyFeedbackModal_actionButton__22P6p {
  display: flex;
  justify-content: end;
  width: 100%;
}

.FlakyFeedbackModal_dialog__2dmPS {
  z-index: 9999 !important;

}
.FlakyFeedbackModal_parent__7_-xi>span::before {
  z-index: 9999;
}
.FlakyTestHeader_flakyBar__3tTN1 {
  align-items: center;
  align-self: stretch;
  background-color: #FFEBE9;
  border: 1px solid rgba(255, 129, 130, 0.40);
  color: #333;
  display: flex;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding: 14px;
}

.FlakyTestHeader_viewTestGrpBtn__2ukHr {
  color: #409FF6;
  cursor: pointer;
  font-size: 10px;
  text-decoration: underline;
  text-underline-offset: 4px;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
}

.FlakyTestHeader_flakySubHeader__WXXXU {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 20px;
}

.FlakyTestHeader_flakySubHeader__WXXXU a:hover,
.FlakyTestHeader_flakySubHeader__WXXXU a:visited,
.FlakyTestHeader_flakySubHeader__WXXXU a:active {
  color: #0969DA;
}


.FlakyTestHeader_flakyAction__3FfM2 {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.ReplayTest_linkWrapper__3t4sV,
.ReplayTest_linkWrapper__3t4sV:hover,
.ReplayTest_linkWrapper__3t4sV:focus,
.ReplayTest_linkWrapper__3t4sV:active {
  color: inherit;
  text-decoration: none;
}

.ReplayTest_linkWrapper__3t4sV:focus .ReplayTest_testItem__3t7Vw {
  border: 1px solid #333;
  border-bottom: none;
  border-top: none;
  outline: 2px solid #0969da;
  outline-offset: -1px;
}

.ReplayTest_testItem__3t7Vw {
  border-bottom: none !important;
}

.ReplayTest_testListContainer__1vFUl > li {
  border-left: 1px solid #d0d7de;
  border-right: 1px solid #d0d7de;
  border-bottom: 1px solid #d0d7de;
  border-color: #d0d7de !important;
}

.ReplayTest_testListContainer__1vFUl > li:first-child {
  border-top: 1px solid #d0d7de;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ReplayTest_testListContainer__1vFUl > li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ReplayTest_noListStyle__1_8ud {
  list-style: none;
  padding: 0;
}

.ReplayTest_tunnelInfoDiv__3lNNM {
  align-items: center;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

a:hover {
  color: #1f2328;
}

.SharedPageWrapper_brandLogo__1gbmn {
  display: flex;
}

.SharedPageWrapper_testDetailTopBar__NcBo5 {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #747474;
  display: flex;
  font-size: 12px;
  height: 45px;
  letter-spacing: 0.4px;
  padding: 8px 15px;
  grid-gap: 16px;
  gap: 16px;
}

.SharedPageWrapper_buildPageWrapper__habEq {
  height: calc(100vh - 45px);
}

.SharedPageWrapper_separator__2VHnT {
  border-left: 1px solid #ddd;
  height: 30px;
  display: flex;
  justify-content: center;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.downtimeParent {
  height: 50px;
  width: 100%;
  z-index: 9999999999999999;
  left: 0px;
  position: fixed;
  background: rgb(225, 115, 75, 0.8);
  color: #fff;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.disableScroll {
  overflow: hidden;
}

.product_main_content {
  overflow: hidden;
}
.App_downtimeParent__2KcAN {
  height: 50px;
  width: 100%;
  z-index: 9999999999999999;
  left: 0px;
  position: fixed;
  background: #f89913;
  color: #fff;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.App_downtimeParent__2KcAN .App_message__145_p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  /* margin-top: 5px; */
  font-size: 15px;
  margin-left: 48px;
  letter-spacing: 0.3px;
  font-weight: 500;
}
.App_downtimeParent__2KcAN span {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.App_downtimeParent__2KcAN .App_closeBtn__1RKh7 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* margin-top: -30px; */
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
}
.App_warningIco__2KKPx {
  font-size: 20px;
  flex: 0 1;
}
.App_messageParent__3Sopi {
  flex: 4 1;
}

.App_buildLoader__BsO1K {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.PreApp_downtimeParent__2Yrh6 {
  height: 50px;
  width: 100%;
  z-index: 9999999999999999;
  left: 0px;
  position: fixed;
  background: #f89913;
  color: #fff;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PreApp_downtimeParent__2Yrh6 .PreApp_message__M_Ai7 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  /* margin-top: 5px; */
  font-size: 15px;
  margin-left: 48px;
  letter-spacing: 0.3px;
  font-weight: 500;
}
.PreApp_downtimeParent__2Yrh6 span {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.PreApp_downtimeParent__2Yrh6 .PreApp_closeBtn__2ydkR {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* margin-top: -30px; */
  font-size: 18px;
  cursor: pointer;
  font-weight: 700;
}
.PreApp_warningIco__f-7Dq {
  font-size: 20px;
  flex: 0 1;
}
.PreApp_messageParent__q0P3r {
  flex: 4 1;
}

.PreApp_productTitle__2tta3 {
  align-items: flex-end;
  color: #333;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: initial;
  margin-top: initial;
  position: relative;
}

.PreApp_ltTopNavBtns__3vT6R {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.AccessKeyPopUp_accessKeyValueSection__1wnf5 {
  display: flex;
}

.AccessKeyPopUp_accessKeyTitle__17dvD {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 16px 16px 8px 16px;
}

.AccessKeyPopUp_separator__XUoxE {
  border-bottom: 1px solid #DDDDDD;
}

.AccessKeyPopUp_accessKeySectionTitle__1T7Q5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.AccessKeyPopUp_ltTextInput__fSObq {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}

.AccessKeyPopUp_ltIconButtonInput__2uwSw {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.AccessKeyPopUp_accessKeyContainer__UTbRW {
  width: 320px;
}

.AccessKeyPopUp_accessKeySectionWrapper__yld5z {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px 16px 16px 16px;
}

.AccessKeyPopUp_accessKeySection__2ZphV {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

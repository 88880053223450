.wrapper {
  height: calc(100vh - 70px);
  overflow-x: hidden;
}

.headerContainer {
  align-items: center;
  border-bottom: 0.8px solid #F1F3F8;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  line-height: 17px;
  padding: 13px 13px 13px 18px;
}

.testCounts {
  font-size: '12px';
  font-weight: 500;
}
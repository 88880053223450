.buildname {
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.24px;
  color: #47c5d8;
  font-size: 12px;
  padding: 16px;
}

.metaDataTable {
  overflow: auto;
  font-size: 12px;
}
.metaDataColumn {
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  padding: 5px;
}
.metaDataValue {
  letter-spacing: 0.3px;
  color: #3c4144;
}

.attachmentWrapper {
  display: flex;
  font-size: 12px;
  gap: 10px;
}
.attachmentName {
  color: #3c4144;
  display: block;
  letter-spacing: 0.3px;
}

.action_btn {
  border: 1px solid #979797;
  border-radius: 5px;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}

.action_btn:hover {
  border: 1px solid #0ebac5;
}

.actiondiv {
  text-align: center;
}

.attachmentRow {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
  max-width: 100%;
  padding: 10px;
}

.attachmentRow:hover {
  background-color: #f8fcff;
}

.logsTabSection {
  margin-top: 16px;
}

.searchLog {
  height: 28px;
  border-radius: 4px;
  padding-left: 30px;
  /* padding-right: 20px; */
  font-size: 12px;
  color: #61605c !important;
  border: solid 1px #eaeaea !important;
  box-shadow: none !important;
}

.searchLogParent {
  position: relative;
  width: 35%;
}

.searchLogIcon {
  background: url(../../../images/ml2.0/search.svg);
  background-repeat: no-repeat;
  background-position: 10px 8px;
  background-size: 12px 12px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 1;
}

.filterlogs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0px;
}

.checkboxWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 15px;
}

.checkboxDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxContainerLabel {
  font-size: 12px;
  line-height: 1.6;
  margin: 0px 10px 0px 5px;
}

.checkboxContainer input[type="checkbox"] {
  position: relative;
  width: 14px;
  height: 14px;
  color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

.checkboxContainer input[type="checkbox"]:before {
  position: absolute;
  content: "";
  display: block;
  top: 2px;
  left: 3px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.checkboxContainer input[type="checkbox"]:checked {
  color: #fff;
  border-color: #eaeaea;
  background: #101010;
}

.checkboxContainer input[type="checkbox"]:checked:before {
  opacity: 1;
}

.checkboxContainer input[type="checkbox"]:checked ~ label:before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.commandsLogWrapper {
  font-size: 12px;
  margin-left: 5px;
}

.rawLogsTab {
  margin: 10px 5px 0px 0px;
}

.download_report {
  display: flex;
  flex-direction: row-reverse;
  padding: 8px;
  width: 100%;
}

.download_report span {
  background-color: #f1f2f4;
  border-radius: 4px;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 8px 10px;
}

.performance {
  overflow: auto;
}

.copyIconWrapper {
  position: absolute;
  right: 25px;
  top: 20px;
  z-index: 999;
}

.hypertestBtn {
  border-radius: 4px;
  border: 0.8px solid #0ebac5;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 4px;
}

.hyperExecuteText {
  color: #999;
  font-size: 10px;
  margin-left: 6px;
}

.loadingHtBtn {
  color: #0ebac5;
}

.loadingHtBtn > span {
  display: flex;
  justify-content: space-between;
}

.remarkDrawer {
  overflow: hidden;
  position: relative;
}

.headerWrapper {
  display: flex;
  padding: 17px 23px;
}

.headerWrapper > img {
  cursor: pointer;
  margin-left: auto;
}

.headerTitle > h4 {
  margin-bottom: 0px;
  margin-right: 6px;
}

.headerTitle {
  color: #999999;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-top: 6px;
}

.copyIcon {
  position: absolute;
  right: 10px;
  top: 70px;
}

.testDetailTabContent {
  display: block;
  overflow: auto;
  padding: 10px;
}

.testDetailTabContent > tbody {
  display: flex;
  flex-direction: column;
}

.testDetailTabContent > tbody > tr {
  display: flex;
  margin-bottom: 15px;
}

.testDetailTabContent.bannerPadding {
  max-height: calc(23vh - 42px);
}

.metaDataColumn {
  color: #666;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  width: 30%;
}

.metaDataValue {
  color: #3c4144;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 70%;
}

.startHeading {
  color: #666;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0.3px;
  line-height: 15px;
  width: 50px;
}

.metaDataDate {
  display: flex;
}

.performanceReportName {
  border-bottom: 0.8px solid #f1f3f8;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 10px;
}

.performanceReportView {
  font-size: 12px;
}

.performanceReportName:hover {
  background-color: #f1f4f9;
}

.performanceReportDrawer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.checkboxContainer:focus-within {
  outline: 1px solid #0969da;
}

.customTabPane:focus {
  outline: 1px solid #0969da;
}

.testContainer {
  width: 100%;
  height: 100%;
}

.allCommandsWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.commandLogWrapper {
  width: 50%;
}
.commandLogWrapperPrimer {
  width: 100%;
  height: 100%;
}

.testDetailInfoWrapper {
  height: 100%;
  /* width: 50%; */
  transition: width 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

.rightPane {
  width: 50%;
}

.annotationWrapper {
  margin-left: 8px;
  @media only screen and (max-width: 320px) {
    display: none;
  }
}

.cypressSpec td {
  padding: 8px;
}

.cypressSpec th {
  position: relative;
  padding: 8px;
}

.cypressSpec th,
.cypressSpec td {
  text-align: left;
}

.cypressSpecTable {
  padding: 16px 16px 8px 16px;
}

.navContainer {
  overflow: auto !important;
}

@media only screen and (max-width: 1024px) {
  .allCommandsWrapper {
    flex-direction: column;
    height: unset;
  }
  .commandLogWrapper {
    width: 100%;
  }
  .testDetailInfoWrapper {
    aspect-ratio: 2;
    width: 100%;
    height: unset;
    width: 100% !important;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.infoTitle {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.separator {
  background-color: #d0d7de;
  height: 1px;
  width: 100%;
}

.footer {
  align-items: center;
  display: flex;
  justify-content: end;
  padding: 16px;
  width: 100%;
}

.error {
  color: #CF222E;
  width: 100%;
}

.reportButton {
  background-color: #000000 !important; 
  border-color: #000000 !important;
  color: #fff !important;
  margin: 0px 4px;
}

.infoMessage {
  color: #333 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.radioButton {
  border-color: #000000 !important;
}

.radioLabel {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
}

.testInfo {
  margin-bottom: 8px;
}

.textField::-webkit-input-placeholder{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
} 

.actionButton {
  display: flex;
  justify-content: end;
  width: 100%;
}

.dialog {
  z-index: 9999 !important;

}
.parent>span::before {
  z-index: 9999;
}
.brandLogo {
  display: flex;
}

.testDetailTopBar {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  color: #747474;
  display: flex;
  font-size: 12px;
  height: 45px;
  letter-spacing: 0.4px;
  padding: 8px 15px;
  gap: 16px;
}

.buildPageWrapper {
  height: calc(100vh - 45px);
}

.separator {
  border-left: 1px solid #ddd;
  height: 30px;
  display: flex;
  justify-content: center;
}
